import React, {useEffect, useState} from 'react'
import {ChevronLeft, ChevronRight} from '@mui/icons-material'
import {clsx} from 'clsx'
import {v4} from 'uuid'

import styles from './Pagination.module.scss'
import {DOTS, usePagination} from './usePagination'
import {FormControl, MenuItem, Select} from '@mui/material'

export const Pagination = (props: Props) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className, variant } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange && paginationRange[paginationRange.length - 1];

  const [isChevronLeftDisabled, setIsChevronLeftDisabled] = useState(false);
  const [isChevronRightDisabled, setIsChevronRightDisabled] = useState(false);
  const [paginationIsBlocked, setPaginationIsBlocked] = useState(false);

  useEffect(() => {
    setIsChevronLeftDisabled(currentPage == 1);

    if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
      setPaginationIsBlocked(true);
    } else {
      setPaginationIsBlocked(false);
    }

    setIsChevronRightDisabled(currentPage === lastPage);
  }, [currentPage, paginationRange, lastPage]);

  const isSelected = (pageNumber: number) => {
    return pageNumber === currentPage;
  };

  return (
    <div className={clsx(styles.paginationContainer, className)}>
      <div
        className={clsx(styles.paginationItem, isChevronLeftDisabled && styles.disabled)}
        onClick={!isChevronLeftDisabled && !paginationIsBlocked ? onPrevious : () => {}}
      >
        <ChevronLeft
          className={isChevronLeftDisabled || paginationIsBlocked ? styles.disabled : ''}
        />
      </div>
      {paginationRange &&
        paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <div key={v4()} className={clsx(styles.paginationItem, styles.dots)}>
                ...
              </div>
            );
          }

          return (
            <div
              key={v4()}
              className={clsx(styles.paginationItem, {
                [styles.selected]: isSelected(+pageNumber),
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </div>
          );
        })}
      <div
        className={clsx(styles.paginationItem, {
          [styles.disabled]: isChevronRightDisabled || paginationIsBlocked,
        })}
        onClick={!isChevronRightDisabled && !paginationIsBlocked ? onNext : () => {}}
      >
        <ChevronRight />
      </div>
      <div className={styles.selectBlock}>
        <span className={styles.showLabel}>Show</span>
        <FormControl variant="outlined" className={styles.selectContainer} size="small">
          <Select
            value={props.selectSettings.value}
            onChange={(e) => props.selectSettings.onChangeOption(e.target.value)}
            // label="Items"
             variant={'outlined'}>
            {props.selectSettings.arr.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <span className={styles.totalLabel}>{variant} per page</span>
      </div>
    </div>
  );
};

type Props = {
  variant: 'transactions' | 'articles' | 'sessions'
  onPageChange: (value: number | string) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className?: string;
  selectSettings: {
    value: string;
    onChangeOption: (value: string) => void;
    arr: Array<string>;
  };
};
