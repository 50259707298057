// myProgressReducer.js
import {
  FETCH_MYPROGRESS_REQUEST,
  FETCH_MYPROGRESS_SUCCESS,
  FETCH_MYPROGRESS_FAILURE,
  SET_SHOW_MODAL_MYPROGRESS,
  SET_POSITION_STYLES_MODAL_MYPROGRESS
} from '../../actions/player/myProgressActions';

const initialState = {
  myProgress: [],
  loading: false,
  error: null,
  messages: null,
  showModalMyProgress: false,
  positionStylesModalMyProgress: {},
};

const myProgressReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MYPROGRESS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_MYPROGRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        myProgress: action.payload,
      };
    case FETCH_MYPROGRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_SHOW_MODAL_MYPROGRESS:
      return {
        ...state,
        showModalMyProgress: action.payload,
        positionStylesModalMyProgress: action.payload ? state.positionStylesModalMyProgress : {},
      };

    case SET_POSITION_STYLES_MODAL_MYPROGRESS:
      return {
        ...state,
        positionStylesModalMyProgress: action.payload,
      };

    default:
      return state;
  }
};

export default myProgressReducer;
