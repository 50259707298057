import { Dispatch } from 'redux';

export const ACTION_SET_VIEW = 'ACTION_SET_VIEW';

// Определяем типы для наших действий
interface SetViewAction {
  type: typeof ACTION_SET_VIEW;
  payload: {
    view: string;
    previousView: string | null;
  };
}

// Определяем тип состояния
interface ViewPlayerDepositState {
  views: string[];
  currentView: string;
}

// Определяем функции для получения предыдущего и следующего вида
const getPreviousView = (currentView: string, views: string[]): string | null => {
  const currentIndex = views.indexOf(currentView);
  return currentIndex > 0 ? views[currentIndex - 1] : null;
};

const getNextView = (currentView: string, views: string[]): string => {
  const currentIndex = views.indexOf(currentView);
  return currentIndex >= 0 && currentIndex < views.length - 1 ? views[currentIndex + 1] : currentView;
};

// Экшен для успешного переключения вида
export const setView = (view: string) => (
  dispatch: Dispatch<SetViewAction>,
  getState: () => { viewPlayerDepositReducer: ViewPlayerDepositState }
) => {
  const { views, currentView } = getState().viewPlayerDepositReducer;
  const previousView = getPreviousView(view, views);

  dispatch({
    type: ACTION_SET_VIEW,
    payload: { view, previousView },
  });
};

// Экшен для переключения на следующий вид
export const setNextView = () => (
  dispatch: Dispatch<SetViewAction>,
  getState: () => { viewPlayerDepositReducer: ViewPlayerDepositState }
) => {
  const { views, currentView } = getState().viewPlayerDepositReducer;
  const nextView = getNextView(currentView, views);

  dispatch({
    type: ACTION_SET_VIEW,
    payload: { view: nextView, previousView: currentView },
  });
};

// Экшен для переключения на предыдущий вид
export const setPreviousView = () => (
  dispatch: Dispatch<SetViewAction>,
  getState: () => { viewPlayerDepositReducer: ViewPlayerDepositState }
) => {
  const { views, currentView } = getState().viewPlayerDepositReducer;

  // Найти индекс текущего вида
  const currentIndex = views.indexOf(currentView);
  
  // Определить предыдущий вид
  const previousView = currentIndex > 0 ? views[currentIndex - 1] : null;
  
  // Определить вид перед предыдущим
  const previousPreviousView = currentIndex > 1 ? views[currentIndex - 2] : null;

  dispatch({
    type: ACTION_SET_VIEW,
    payload: {
      view: previousView || currentView, // Устанавливаем текущим предыдущий или остаемся на текущем
      previousView: previousPreviousView // Предыдущий становится предыдущим предыдущего
    },
  });
};
