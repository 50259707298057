import {useNavigate} from 'react-router-dom'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {connectWebSocket} from '../../../redux/actions/game/gameGroupsActions'
import {
  selectGameGroups,
  selectGameGroupsError,
  selectGameGroupsLoading
} from '../../../redux/selectors/game/gameGroupsSelectors'
import {selectIsAuthenticated, selectToken} from '../../../redux/selectors/site/loginSelectors'
import styles from "./GameGroups.module.scss"
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import BrightCarousel from "../../../common/BrightCarousel/BrightCarousel"
import game_icon from 'src/assets/images/game_icon.png'
import clsx from 'clsx'
import config from "../../../config"

// in Figma: Categories
const GameGroups: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const gameGroups = useSelector<Array<GameGroup>>(selectGameGroups)
  const gameGroupsLoading = useSelector(selectGameGroupsLoading)
  const gameGroupsError = useSelector(selectGameGroupsError)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const token = useSelector(selectToken)

  useEffect(() => {
    if (isAuthenticated) {
      const closeWebSocket = dispatch<any>(connectWebSocket(token))

      return () => {
        closeWebSocket()
      }
    }
  }, [dispatch, token, isAuthenticated])

  useEffect(() => {
    console.log('gameGroups', gameGroups)
  }, [gameGroups])


  const handleGroupClick = (group: GameGroup) => {
    if (group.id && group.slug) {
      console.log('Group ID:', group.id)
      console.log('Group Slug:', group.slug)
      navigate(`/game-group/${group.slug}`)
    } else {
      console.error('Group ID or Slug is missing', group)
    }
  }

  const isObjectEmpty = (obj: any): boolean => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object
  }

  const gameGroupConvertor = (gameGroups: Array<GameGroup>): Array<React.ReactNode> => {
    return gameGroups.map((group: GameGroup, i: number) => (
      <div onClick={() => handleGroupClick(group)} className={clsx(stylesCommon.link, styles.element)} key={i}>
        <img src={group.logo_path ? `${config.fileUrls.gameGroup}${group.logo_path}` : game_icon} alt="game_icon" className={styles.gameIcon}/>
        <div className={styles.groupName}>{group.name}</div>
      </div>
    ))
  }

  return (
    <div className={clsx(styles.gameGroups, stylesCommon.group)}>
      {gameGroupsLoading ? (
        <p>Loading...</p>
      ) : gameGroupsError
        ? <>Error: {gameGroupsError}</>
        : gameGroups && !isObjectEmpty(gameGroups) && Array.isArray(gameGroups) && gameGroups.length > 0 ? (
          <div className={styles.groupsContainer}><BrightCarousel className={styles.carousel} elements={gameGroupConvertor(gameGroups)}/></div>
        ) : (
          <>
            <p>No game categories!</p>
          </>
        )}
    </div>
  )
}

export default GameGroups

type GameGroup = {
  id: number
  pos: number
  flag_active: 'YES' | 'NO'
  slug: string
  name: string
  description?: string
  language_iso_code?: string
  logo_path?: string
  title?: string
  platform?: string
  created_at: number
  updated_at?: number
} 
