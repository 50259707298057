// platform-client-app\react-platform\src\redux\selectors\site\registrationAccauntSelectors.js

export const selectRegistrationAccaunt               = (state) => state.registrationReducer.data;
export const selectRegistrationAccauntCreatedSuccess = (state) => state.registrationReducer.success === true;
export const selectRegistrationAccauntMessages       = (state) => state.registrationReducer.messages;
export const selectRegistrationAccauntLoading        = (state) => state.registrationReducer.loading;
export const selectRegistrationAccauntError          = (state) => state.registrationReducer.error;

export const selectRegistrationAccauntErrorString = (state) => {
    const error = state.registrationReducer.error;
  
    if (Array.isArray(error)) {
      return error.join('\n');
    }
  
    if (typeof error === 'object' && error !== null) {
      return Object.entries(error)
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n');
    }
  
    return error;
  };

  export const selectRegistrationAccauntErrorArray = (state) => {
    const error = state.registrationReducer.error;
    let errorsArray = [];
  
    if (Array.isArray(error)) {
      errorsArray = error.map(err => `${err}`);
    } else if (typeof error === 'object' && error !== null) {
      errorsArray = Object.entries(error)
        .flatMap(([key, value]) => Array.isArray(value) ? value.map(val => `${key}: ${val}`) : `${key}: ${value}`);
    } else if (error) {
      errorsArray = [error];
    }
  
    return errorsArray.length > 0 ? errorsArray : null;
  };
  


  