import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectBalances } from 'src/redux/selectors/balancesSelectors'
import { selectIsAuthenticated, selectToken } from '../../../redux/selectors/site/loginSelectors'
import { LanguageDropdown } from "../../elems/LanguageDropdown/LanguageDropdown"
import { fetchLogout } from "src/redux/actions/site/logoutActions"
import { connectWebSocket, fetchBalances } from 'src/redux/actions/balancesActions'
import { useAppDispatch } from "../../../hooks"
import { clearChildrenMenuData } from "../../../redux/slices/interfaceSlice"
import { setShowModalMyProgress } from "src/redux/actions/player/myProgressActions"
import Site_logo from 'src/assets/images/Site_logo.png'
import styles from './Navigation.module.scss'
import stylesModal from '../ui/modal/Modal.module.scss'
import clsx from 'clsx'
import { ButtonStyled } from "../../../common/componentsStyled/ButtonStyled"
import PlayerDeposit from "../../pages/PlayerDeposit/PlayerDeposit"
import Modal from "../ui/modal/Modal";
import {
  selectCurrentView,
  selectPreviousView,
  selectViews
} from '../../../redux/selectors/playerDeposit/viewSelectors';
import { setPreviousView } from '../../../redux/actions/playerDeposit/viewActions';

const Navigation: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showDropdownMainBalance, setShowDropdownMainBalance] = useState<boolean>(false)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const token = useSelector(selectToken)
  const balances = useSelector(selectBalances)
  const [hideZeroBalance, setHideZeroBalance] = useState<boolean>(false)
  const previousView = useSelector(selectPreviousView);

  const handleHideZeroBalanceToggle = () => {
    setHideZeroBalance(!hideZeroBalance)
  }

  const [showDropdownLanguage, setShowDropdownLanguage] = useState<boolean>(false)

  const handleLogout = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(clearChildrenMenuData())
    dispatch(setShowModalMyProgress(false))
    await dispatch(fetchLogout(token as string))
    if (!isAuthenticated) {
      navigate('/auth')
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchBalances(token))
      const closeWebSocket = dispatch(connectWebSocket(token))
      return () => {
        closeWebSocket()
      }
    }
  }, [dispatch, token, isAuthenticated])

  useEffect(() => {
    console.log(balances)
    // setShowModal(false)
  }, [balances])

  const handleModalBackClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(setPreviousView());
  };

  const handleClosePlayerDepositModal = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    e.preventDefault();
    setShowModal(false);
  };

  // ---------------
  // TODO сделать какой то блок Alert в котором будет выводиться ошибка логаута
  // ---------------

  return (
    <>
      <nav className={clsx("navbar navbar-expand-lg", styles.navBar)}>
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={Site_logo} alt="site_logo" className={styles.siteLogo} />
          </Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse d-flex justify-content-end" id="navbarNav">
            <ul className="navbar-nav">
              {isAuthenticated ? (
                <>
                  <li className="nav-item" style={{ paddingRight: "40px" }}>
                    <div className={"dropdown" + (showDropdown ? " show" : "")}>
                      <a className="nav-link btn dropdown-toggle" href="#"
                        onClick={() => setShowDropdown(!showDropdown)}>Profile</a>
                      <div
                        className={"dropdown-menu dropdown-menu-left dropdown-menu-arrow" + (showDropdown ? " show" : "")}>
                        <Link className="dropdown-item" to="/notifications">Notifications</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/favorite-games">Favorite Games</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/accounts">Accounts</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/personal-information">Personal Information</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/documents">Documents</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/transactions">Transaction History</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/bonus-history">Bonus History</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/responsible-gaming">Responsible Gaming</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/deposit">Deposit</Link>
                      </div>
                    </div>
                  </li>
                  {/* Balances */}
                  {balances ? (
                    Object.entries(balances).map(([key, balance]: [string, any]) => {
                      // Main balance
                      if (key === 'main_balance') {
                        return (
                          <li className="nav-item" style={{ paddingRight: "40px" }} key={key}>
                            <div className={"dropdown" + (showDropdownMainBalance ? " show" : "")}>
                              <a className="nav-link btn dropdown-toggle d-flex align-items-center" href="#"
                                onClick={() => setShowDropdownMainBalance(!showDropdownMainBalance)}>
                                <div>
                                  <p className="mb-0">REAL</p>
                                  <p className="mb-0">
                                    <span>{balance.amount}{balance.currency}</span>
                                  </p>
                                </div>
                              </a>
                              <div
                                className={"dropdown-menu dropdown-menu-left dropdown-menu-arrow" + (showDropdownMainBalance ? " show" : "")}>
                                {hideZeroBalance && balance.amount === 0 ? null : (
                                  <>
                                    <div className="d-flex justify-content-between p-2">
                                      <span>{balance.currency}</span>
                                      <span>{balance.currency_symbol ? balance.currency_symbol : ''}{balance.amount}</span>
                                    </div>
                                    <div className="dropdown-divider"></div>
                                  </>
                                )}
                                <div className="d-flex justify-content-between p-2">
                                  {/* <span>Show crypto fiat</span> */}
                                  <span>Hide zero balance</span>
                                  <input
                                    type="checkbox"
                                    checked={hideZeroBalance}
                                    onChange={handleHideZeroBalanceToggle}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                      } else if (key === 'bonus_balance') {
                        return (
                          <li className="nav-item" style={{ paddingRight: "40px" }} key={key}>
                            <div className="align-items-center p-2">
                              <p className="mb-0">BONUS</p>
                              <p className="mb-0">
                                <span>{balance.amount}{balance.currency}</span>
                              </p>
                            </div>
                          </li>
                        )
                      }
                      return null
                    })
                  ) : (
                    <p>No data available</p>
                  )}

                  <li className="nav-item">
                    <button className="nav-link" onClick={() => setShowModal(true)}>Player deposit</button>
                  </li>
                  <li className="nav-item">
                    <button className="nav-link text-light" onClick={handleLogout}>Logout</button>
                  </li>
                </>
              ) : (
                <>
                  <ButtonStyled to="/auth/login" variant={'glass'} className={styles.loginButton}>Login</ButtonStyled>
                  <ButtonStyled to="/auth/signup" >Register</ButtonStyled>
                </>
              )}
              <LanguageDropdown showDropdownLanguage={showDropdownLanguage}
                setShowDropdownLanguage={setShowDropdownLanguage}
              />
            </ul>
          </div>
        </div>
      </nav>
      {/* Используем Modal для отображения компонента PlayerDeposit */}
      <Modal
        show={showModal}
        onClose={handleClosePlayerDepositModal}
        title="Player Deposit"
        header={
          previousView && (
            <button className={stylesModal.btnPrev} onClick={handleModalBackClick}>
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
              </svg>
            </button>
          )
        }
        body={<PlayerDeposit />} // Ваш компонент PlayerDeposit
      />
    </>
  )
}

export type Language = {
  title: string
  iso_code: string
  id: number
};

export default Navigation
