// gameListReducer.js
import { ACTION_GAMELIST_REQUEST, ACTION_GAMELIST_SUCCESS, ACTION_GAMELIST_FAILURE } from '../../actions/game/gameListActions';

const initialState = {
  data: null,
  loading: false,
  errors: null,
  messages: null
};
   
  const gameListReducer = (state = initialState, action) => {
    switch (action.type) {
      case ACTION_GAMELIST_REQUEST:
        return {
          ...state,
          loading: true,
          errors: null,
          messages: null 
        };
  
      case ACTION_GAMELIST_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload.data,
          messages: action.payload.messages,
          errors: null
        };
  
      case ACTION_GAMELIST_FAILURE:
        return {
          ...state,
          loading: false,
          errors: action.payload.errors,
          messages: action.payload.messages,
          data: null 
        };
  
      default:
        return state;
    }
  };

  export default gameListReducer;
  