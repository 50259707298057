import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import config from '../../config'
import {SwitchAccountResponse} from "../../api/types"

type SwitchAccountState = {
  response: SwitchAccountResponse | null;
  isLoading: boolean;
  isError: boolean;
}

const initialState: SwitchAccountState = {
  response: null,
  isLoading: false,
  isError: false,
}

// Async thunk for switching accounts via WebSocket
export const switchAccount = createAsyncThunk<
  SwitchAccountResponse,
  { token: string; currencyId: number }
>('account/switchAccount', async ({token, currencyId}) => {
  return new Promise<SwitchAccountResponse>((resolve, reject) => {
    const ws = new WebSocket(config.serverWebsocketUrl)

    ws.onopen = () => {
      ws.send(JSON.stringify({type: 'switchAccount', token, currencyId}))
    }

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data)
        resolve(data)
        ws.close()
      } catch (error) {
        reject('switchAccount ws.onmessage catch')
      }

    }

    ws.onerror = () => {
      reject('switchAccount rejected ws.onerror')
    }

    ws.onclose = () => {
      // console.log('SwitchAccount webSocket connection closed')
    }
  })
})

// Creating the slice
const switchAccountSlice = createSlice({
  name: 'switchAccount',
  initialState,
  reducers: {
    clearSwitchAccountResponse: (state) => {
      state.response = null
      state.isLoading = false
      state.isError = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(switchAccount.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(switchAccount.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.response = action.payload
      })
      .addCase(switchAccount.rejected, (state) => {
        state.isLoading = false
        state.isError = true
        state.response = null
      })
  },
  selectors: {
    switchAccountSelector: (state: SwitchAccountState) => state,
  },
})

// Exporting the reducer and actions
export const switchAccountReducer = switchAccountSlice.reducer
export const {switchAccountSelector} = switchAccountSlice.selectors
export const {clearSwitchAccountResponse} = switchAccountSlice.actions
