import React from 'react'
import clsx from "clsx"
import chevron_left from "src/assets/images/icons/chevron_left.svg"
import chevron_right from 'src/assets/images/icons/chevron_right.svg'
import arrow_left from "src/assets/images/icons/arrow_left.svg"
import arrow_right from "src/assets/images/icons/arrow_right.svg"
import styles from "./ScrollButton.module.scss"

type Props = {
  onClick: () => void
  direction: 'left' | 'right'
  className?: string
  variant?:  'chevron' | 'arrow'
}

export const ScrollButton = ({onClick, direction, variant = 'chevron', className}: Props) => {
  return (
    <div onClick={onClick} className={clsx(styles.scrollButton, className)}>
      <img
        src={
        direction === 'left'
          ? (variant === 'chevron' ? chevron_left : arrow_left)
          : (variant === 'chevron' ? chevron_right : arrow_right)
      }
        alt={
        direction === 'left'
          ? (variant === 'chevron' ? 'chevron_left' : 'arrow_left')
          : (variant === 'chevron' ? 'chevron_right' : 'arrow_right')
      }
        />
    </div>
  )
}