// gameGroupsReducer.js
import { FETCH_GAMEGROUPS_REQUEST, FETCH_GAMEGROUPS_SUCCESS, FETCH_GAMEGROUPS_FAILURE } from '../../actions/game/gameGroupsActions';

const initialState = {
    gameGroups: null,
    loading: false,
    error: null,
    messages: null,
  };
  
  const gameGroupsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_GAMEGROUPS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_GAMEGROUPS_SUCCESS:
        return {
          ...state,
          loading: false,
          gameGroups: action.payload,
        };
      case FETCH_GAMEGROUPS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default gameGroupsReducer;
  