import React from 'react'
import styles from './LanguageDropdown.module.scss'
import clsx from 'clsx'
import {useGetLanguagesQuery} from '../../../api/api'
import {Language} from "../../layouts/Navigation/Navigation"
import {useAppDispatch, useAppSelector} from "../../../hooks"
import {clearChildrenMenuData, interfaceSelector, setLanguage} from "../../../redux/slices/interfaceSlice"

type Props = {
  showDropdownLanguage: boolean
  setShowDropdownLanguage: (value: boolean) => void
}

export const LanguageDropdown = ({
                                   showDropdownLanguage,
                                   setShowDropdownLanguage,
                                 }: Props) => {

  const {data} = useGetLanguagesQuery()
  const {selectedLanguage} = useAppSelector(interfaceSelector)

  const dispatch = useAppDispatch()

  const handleLanguageChange = (lang: Language) => {
    dispatch(clearChildrenMenuData())
    dispatch(setLanguage(lang))
    setShowDropdownLanguage(false)
  }


  type Item = string | { title?: string; iso_code?: string } | null;

  const getIsocode = (item: Item): string => {
    
    if (typeof item === 'string') {
      return item;
    }

    if (typeof item === 'object' && item !== null && 'iso_code' in item) {
      return item.iso_code || '';
    }

    return '';
  };

  return (
    <div className={styles.languageContainer}>
      <li className="nav-item">
        <div className={'dropdown' + (showDropdownLanguage ? ' show' : '')}>
          <a className="nav-link btn text-light dropdown-toggle" href="#"
             onClick={() => setShowDropdownLanguage(!showDropdownLanguage)}>
            {selectedLanguage.title}
          </a>
          <div
            className={clsx('dropdown-menu dropdown-menu-left dropdown-menu-arrow' + (showDropdownLanguage ? ' show' : ''), styles.menu)}>
            {data && Object.entries(data).map(([id, language]) => (
              <div key={id} className={clsx('dropdown-item', styles.item)} onClick={() => handleLanguageChange({
                title: language.title,
                iso_code: language.iso_code,
                id: Number(id)
              })}>
                {language.title}
              </div>
            ))}
          </div>
        </div>
      </li>
    </div>
  )
}
