// platform-client-app\react-platform\src\redux\selectors\setting\getRegistrationFormSettingsSelectors.js

export const selectGetRegistrationFormSettings             = (state) => state.getRegistrationFormSettingsReducer.data;
export const selectGetRegistrationFormSettingsMessages     = (state) => state.getRegistrationFormSettingsReducer.messages;
export const selectGetRegistrationFormSettingsLoading      = (state) => state.getRegistrationFormSettingsReducer.loading;
export const selectRegistrationFormSettingsId              = (state) => state.getRegistrationFormSettingsReducer.idForm;

// export const selectGetRegistrationFormSettings = (state) => {
//   const data = state.getRegistrationFormSettingsReducer.data;

//   if (!data || !data.settingRegistrationFormElements) {
//     return data;
//   }

//   const sortedElements = [...data.settingRegistrationFormElements].sort((a, b) => a.pos - b.pos);
//   return {
//     ...data,
//     settingRegistrationFormElements: sortedElements,
//   };
// };

export const selectSettingRegistrationFormElements = (state) => {
  const data = state.getRegistrationFormSettingsReducer.data;

  if (!data || !data.settingRegistrationFormElements) {
    return data;
  }

  const sortedElements = [...data.settingRegistrationFormElements].sort((a, b) => a.pos - b.pos);
  return sortedElements;
};


export const selectGetRegistrationFormSettingsError = (state) => {
    const error = state.getRegistrationFormSettingsReducer.error;
  
    if (Array.isArray(error)) {
      return error.join('\n');
    }
  
    if (typeof error === 'object' && error !== null) {
      return Object.entries(error)
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n');
    }
  
    return error;
  };
  