import React from 'react'
import {useAppSelector} from "../../../../hooks"
import {interfaceSelector} from "../../../../redux/slices/interfaceSlice"
import {useGetCarouselQuery} from "../../../../api/api"
import styles from "./Carousel.module.scss"
import config from "../../../../config"
import BrightCarousel from "../../../../common/BrightCarousel/BrightCarousel"
import {CarouselTitleIcon} from "../../../../common/CarouselTitleIcon/CarouselTitleIcon"

type Props = {
  id: number
  interval?: number
}

export const Carousel = ({id, interval}: Props) => {
  const {selectedLanguage} = useAppSelector(interfaceSelector)
  const {data} = useGetCarouselQuery({languageId: selectedLanguage.id, id})

  const items = data?.data?.items
  const direction = data?.data?.deriction
  const flag_show_title = data?.data?.flag_show_title

  if (
    !data ||
    !data.success ||
    !direction ||
    !items
  )
    return <></>

  return (
    <div className={styles.carousel}>
      {flag_show_title === 'YES' &&
          <CarouselTitleIcon logoPath={data?.data?.logo_path} title={data.data.title}/>
      }
      <BrightCarousel
        elements={items.map((item) => (
          <div key={item.id} className={styles.element}>
            <img src={`${config.fileUrls.rootFolder}${item.image_path}`} alt={item.image_path}/>
          </div>
        ))}
        isControlHidden={true}
        smooth={{active: true, direction}}
        interval={interval}
        className={styles.carouselSettings}
      />
    </div>
  )
}