import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import config from '../../config.js';

interface ErrorMessage {
  errorMessage: string;
}

interface ErrorResponse {
  error: string;
}

export const NotFound: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>(null);

  useEffect(() => {
    document.title = "404 - Not Found";
    //Если ответ сервера успешен (коды статуса 2xx), этот блок выполняется.
    axios.get(`${config.serverUrl}/status/404`)
      
    .then((response) => {
        setErrorMessage({ errorMessage: response.data.error });
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          // Если сервер возвращает ошибку (например, коды статуса 4xx или 5xx), этот блок будет выполнен.
          if (error.response) {
            setErrorMessage({ errorMessage: error.response.data.error });
          } else {
            // Если ошибка не связана с ответом от сервера
            console.error('Fetch error:', error);
            setErrorMessage({ errorMessage: "An error occurred while fetching the error status." });
          }
        } else {
          // Обработка других ошибок
          console.error('Unexpected error:', error);
          setErrorMessage({ errorMessage: "An unexpected error occurred." });
        }
      });
  }, []);

  return (
    <div>
      <h1>404 - Not Found</h1>
      {errorMessage && <p>{errorMessage.errorMessage}</p>}
      <Link to="/">Back to main</Link>
    </div>
  );
}
