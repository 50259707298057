import {Order} from './types'

// Universal function to get a comparator for sorting
export function getComparator<T, Key extends keyof T>(
  order: Order,           // 'asc' for ascending or 'desc' for descending order
  orderBy: Key            // The field by which sorting is performed
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// Function to compare two entries in descending order based on the specified field (orderBy)
function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy]) {
    return -1 // Return -1 if `b` should come before `a` in sorted order
  }
  if (b[orderBy] > a[orderBy]) {
    return 1  // Return 1 if `a` should come before `b`
  }
  return 0    // Return 0 if values are equal
}

export const formatDate = (timestamp: number | null | undefined): string => {
  return timestamp ? new Date(timestamp * 1000).toLocaleDateString() : ''
}

// sub function to get nested value from object
const getNestedValue = (obj: any, path: string): any => {
  return path.split('.').reduce((acc, key) => acc && acc[key], obj);
}

// get options for select from array
export function extractUniqueOptions<T>(data: T[], path: string): { value: string; label: string }[] {
  const uniqueValues = [...new Set(data.map(item => getNestedValue(item, path)))];
  return uniqueValues.map(value => ({ value: String(value), label: String(value) }));
}

