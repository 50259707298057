import React, {ReactNode, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useGetSeoRedirectsQuery} from "../api/api"

type Props = {
  children: ReactNode
}

export const SeoRedirecter = ({ children }: Props) => {
  const location = useLocation();
  const navigate = useNavigate(); // Переносим useNavigate вне useEffect
  const { data: seoRedirects, isSuccess } = useGetSeoRedirectsQuery( { url: location.pathname, id_domain: 1 });

  useEffect(() => {
    if (isSuccess && seoRedirects && seoRedirects.data) {
      // Выполняем редирект
      navigate(seoRedirects.data.redirect_to, { replace: true });
    }
  }, [seoRedirects, navigate]); // Добавляем navigate в зависимости

  return <>{children}</>;
};