import {
  ACTION_CODE_CONFIRMATION_REQUEST,
  ACTION_CODE_CONFIRMATION_SUCCESS,
  ACTION_CODE_CONFIRMATION_FAILURE
} from '../../actions/util/codeConfirmationActions';

const initialState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};

const codeConfirmationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_CODE_CONFIRMATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: false
      };

    case ACTION_CODE_CONFIRMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        messages: action.payload.messages,
        success: true
      };

    case ACTION_CODE_CONFIRMATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        success: false
      };

    default:
      return state;
  }
};

export default codeConfirmationReducer;
