import React from 'react'
import {useGetFaqQuery} from '../../api/api'
import {FaqItem} from '../../api/types'
import {useAppSelector} from "../../hooks"
import {interfaceSelector} from "../../redux/slices/interfaceSlice"

export const Faq = () => {
  const {selectedLanguage} = useAppSelector(interfaceSelector)

  const {data, error, isLoading} = useGetFaqQuery(selectedLanguage.id)

  if (isLoading) return <p>Loading FAQs...</p>
  if (error) return <p>Error fetching FAQs: {error.toString()}</p>


  return (
    <div>
      <h1>FAQ</h1>
      {data && data.data.length > 0 ? (
        data.data.map((item: FaqItem) => (
          <div key={item.id}>
            <div>name: {item.name}</div>
            <div>description: {item.description}</div>
            <div>slug: {item.slug}</div>
          </div>
        ))
      ) : (
        <p>No FAQs available.</p>
      )}
    </div>
  )
}
