// getPlayerProfileReducer.js
import {
  ACTION_GET_PLAYER_PROFILE_SETTINGS_REQUEST,
  ACTION_GET_PLAYER_PROFILE_SETTINGS_SUCCESS,
  ACTION_GET_PLAYER_PROFILE_SETTINGS_FAILURE
} from '../../actions/setting/getPlayerProfileSettingsActions';

const initialState = {
  loading: false,
  error: null,
  data: [],
  messages: null,
};

const getPlayerProfileSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_GET_PLAYER_PROFILE_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ACTION_GET_PLAYER_PROFILE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        messages: action.payload.messages,
      };

    case ACTION_GET_PLAYER_PROFILE_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default getPlayerProfileSettingsReducer;
