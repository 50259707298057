import React from 'react'
import clsx from "clsx"
import styles from './ChildrenMenu.module.scss'
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import {useAppSelector} from "../../../hooks"
import {childrenMenuSelector} from "../../../redux/slices/interfaceSlice"
import {NavWithChildrenMenuClose} from "../NavWithChildrenMenuClose/NavWithChildrenMenuClose"
import trophy_2_1 from "../../../assets/images/trophy_2_1.png"

export const ChildrenMenu = () => {
  const {selectedMenu, menuPosition, isChildrenMenuOpened} = useAppSelector(childrenMenuSelector)

  return (
    <div
      className={clsx(styles.childrenMenu, {
        [styles.open]: isChildrenMenuOpened,
        [styles.closed]: !isChildrenMenuOpened,
      })}
      style={{top: `${menuPosition}px`}}
    >
      {selectedMenu && (
        <div className={stylesCommon.itemsContainer}>
          {selectedMenu.map((item) => (
            <div key={item.id} className={stylesCommon.item}>
              <img src={trophy_2_1} alt="trophy_2_1" className={stylesCommon.trophy}/>
              <NavWithChildrenMenuClose item={item}/>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

