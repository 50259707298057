import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import config from '../../config'
import {AddFavoriteGameResponse} from "../../api/types"

type FavoriteGameState = {
  response: AddFavoriteGameResponse | null;
};

const initialState: FavoriteGameState = {
  response: null,
}

export const addFavoriteGame = createAsyncThunk<
  AddFavoriteGameResponse,
  { gameId: number, token: string }
>('games/addFavoriteGame', async ({gameId, token}) => {
  return new Promise((resolve, reject) => {
    const ws = new WebSocket(config.serverWebsocketUrl)

    ws.onopen = () => {
      ws.send(JSON.stringify({type: 'addFavoriteGame', token, gameId}))
    }

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data)
        resolve(data.result)  // Returning the result
        ws.close() // Closing WebSocket connection after receiving the response
      } catch (error) {
        reject('Error parsing WebSocket data')
      }
    }

    ws.onclose = () => {
      console.log('WebSocket connection closed')
    }
  })
})

// Creating a Slice
const favoriteGameSlice = createSlice({
  name: 'favoriteGame',
  initialState,
  reducers: {
    clearFavoriteGameResponse: (state) => {
      state.response = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addFavoriteGame.fulfilled, (state, action) => {
      state.response = action.payload  // Storing the response in the state
    })
  },
  selectors: {
    addFavoriteGameSelector: state => state
  }
})

export const favoriteGameReducer = favoriteGameSlice.reducer
export const {addFavoriteGameSelector} = favoriteGameSlice.selectors
export const {clearFavoriteGameResponse} = favoriteGameSlice.actions