import React from 'react'
import {useGetWinnersQuery} from "../../../api/api"
import styles from './Winners.module.scss'
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import config from "../../../config"
import BrightCarousel from "../../../common/BrightCarousel/BrightCarousel"
import {useAppSelector} from "../../../hooks"
import {interfaceSelector} from "src/redux/slices/interfaceSlice"
import clsx from 'clsx'
import game_winners_dummy from 'src/assets/images/game_winners_dummy.png'
import {ButtonStyled} from "../../../common/componentsStyled/ButtonStyled"
import user_01 from 'src/assets/images/icons/user_01.svg'
import currency_btc from 'src/assets/images/icons/currency_btc.svg'
import currency_euro from 'src/assets/images/icons/currency_euro.svg'
import currency_dollar from 'src/assets/images/icons/currency_dollar.svg'
import {CarouselTitleIcon} from "../../../common/CarouselTitleIcon/CarouselTitleIcon"

const currencySelector = (currency_code: string) => {
  switch (currency_code) {
    case 'BTC':
      return <img className={styles.currency} src={currency_btc} alt={currency_code}/>
    case 'EUR':
      return <img className={styles.currency} src={currency_euro} alt={currency_code}/>
    case 'USD':
      return <img className={styles.currency} src={currency_dollar} alt={currency_code}/>
    default:
      return <div className={styles.currencyCode}>{currency_code}</div>
  }
}

export const Winners = () => {
  const {selectedLanguage} = useAppSelector(interfaceSelector)

  const {data} = useGetWinnersQuery({language: selectedLanguage.id, id: 1})
  console.log('data', data)

  return (
    <div className={clsx(styles.winners, stylesCommon.group)}>
      {
        data?.data?.transactions
        &&
          <>
            {
              data?.data?.flag_show_title === 'YES' &&
                <CarouselTitleIcon logoPath={`${config.fileUrls.rootFolder}${data?.data?.logo_path}`} title={data.data.title} className={styles.title}/>
            }
              <BrightCarousel elements={
                data?.data?.transactions?.map((winner, index) => (
                  <div key={index} className={styles.element}>
                    <img
                      src={winner.game_image ? `${config.fileUrls.game}${winner.game_image}` : game_winners_dummy}
                      alt={winner.game_slug}
                      className={styles.gameImage}
                    />
                    <div className={styles.container}>
                      <div className={styles.playerContainer}>
                        <div className={styles.iconContainer}>
                          <img src={user_01} alt="user_01"/>
                        </div>
                        <span className={styles.player} title={winner.player}>{winner.player}</span>
                      </div>
                      <div className={styles.currencyContainer}>
                        {currencySelector(winner.currency_code)}
                        <div className={styles.totalAmount}>+{winner.total_amount}</div>
                      </div>
                      <div className={styles.providerName}>{winner.provider_name}</div>
                      <ButtonStyled to={`/games/${winner.game_slug}`} className={styles.button} paddingVariant={'12px 16px'}>Try my luck</ButtonStyled>
                    </div>
                  </div>
                ))
              } isControlHidden={true} interval={data?.data?.interval}
                              className={styles.carousel}
              />
          </>

      }
    </div>
  )
}