import React, {useMemo, useState} from 'react'
import {useSelector} from "react-redux"
import {selectToken} from "../../../../../redux/selectors/site/loginSelectors"
import {useGetSessionInfoQuery} from "../../../../../api/api"
import {useAppSelector} from "../../../../../hooks"
import {interfaceSelector} from "../../../../../redux/slices/interfaceSlice"
import {Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"
import {SessionsData} from "src/api/types"
import {SortableFilterableTableCell} from '../../common/SortableFilterableTableCell'
import {DEFAULT_PAGINATION_VALUE, SIBLING_COUNT, TRANSACTIONS_PAGINATION_VALUES} from "../../../../../common/constants"
import {Pagination} from "../../../../../common/Pagination"
import {Link} from "react-router-dom"
import {extractUniqueOptions, formatDate, getComparator} from '../../common/utils'
import {useGetCurrencySelectOptions} from "../../common/useGetCurrencySelectOptions"
import {STATUS_OPTIONS} from "./SessionsInfoConstants"
import stylesCommon from "../../common/TablesCommonStyles.module.scss"
import CopyToClipboard from "react-copy-to-clipboard"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

export const SessionsInfo: React.FC = () => {
  const {selectedLanguage} = useAppSelector(interfaceSelector)
  const token = useSelector(selectToken)

  const {currencySelectOptions} = useGetCurrencySelectOptions()

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGINATION_VALUE)

  const {data: dataSessionInfo} = useGetSessionInfoQuery({
      token: token ? token : '',
      languageId: selectedLanguage.id,
      page: currentPage,
      pageSize: itemsPerPage
    },
    {
      pollingInterval: 30000, // re-fetching every 30 seconds
    }
  )

  const gameTypeOptions = dataSessionInfo
    ? extractUniqueOptions(dataSessionInfo.data, 'gameType.name')
    : []

  const gameOptions = dataSessionInfo
    ? extractUniqueOptions(dataSessionInfo.data, 'game.name')
    : []


  const [filters, setFilters] = useState({
    sessionIdFilter: '',
    gameFilter: '',
    gameTypeFilter: '',
    betFilter: '',
    winFilter: '',
    jackpotFilter: '',
    fsFilter: '',
    currencyFilter: '',
    startFilter: '',
    endFilter: '',
    statusFilter: ''
  })

  // Sort state
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const [orderBy, setOrderBy] = useState<keyof SessionsData>('id')

  const handleRequestSort = (property: keyof SessionsData) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleFilterChange = (field: string, value: string) => {
    setFilters((prevFilters) => ({...prevFilters, [field]: value}))
  }

  // Filter and sort data
  const filteredData = useMemo(() => {
    if (!dataSessionInfo?.data) return []

    return dataSessionInfo.data
      .filter((data: SessionsData) => {
        const matchesSessionId = !filters.sessionIdFilter || data.uid.toString().includes(filters.sessionIdFilter)
        const matchesGame = !filters.gameFilter || data.game.name.includes(filters.gameFilter)
        const matchesGameType = !filters.gameTypeFilter || data.gameType.name.includes(filters.gameTypeFilter)
        const matchesBet = !filters.betFilter || data.bet_real.toString().includes(filters.betFilter)
        const matchesWin = !filters.winFilter || data.win_real.toString().includes(filters.winFilter)
        const matchesJackpot = !filters.jackpotFilter || data.jackpot_total.toString().includes(filters.jackpotFilter)
        const matchesCurrency = !filters.currencyFilter || data.currency.code.includes(filters.currencyFilter)
        const matchesStart = !filters.startFilter || formatDate(data.created_at).includes(filters.startFilter)
        const matchesEnd = !filters.endFilter || formatDate(data.end_datetime).includes(filters.endFilter)
        const matchesStatus = !filters.statusFilter || data.status.includes(filters.statusFilter)

        return (
          matchesSessionId &&
          matchesGame &&
          matchesGameType &&
          matchesBet &&
          matchesWin &&
          matchesJackpot &&
          matchesCurrency &&
          matchesStart &&
          matchesEnd &&
          matchesStatus
        )
      })
      .sort((a, b) => {
        if (orderBy === 'currency') {
          return order === 'asc'
            ? a.currency.code.localeCompare(b.currency.code)
            : b.currency.code.localeCompare(a.currency.code)
        }
        return getComparator<SessionsData, keyof SessionsData>(order, orderBy)(a, b)
      })
  }, [dataSessionInfo, filters, order, orderBy])

  type Headers = {
    label: string
    orderBy?: keyof SessionsData
    filterKey?: string
    selectOptions?: { value: string, label: string }[]
    sortable?: boolean
  };

  const headers: Headers[] = [
    {label: 'Session ID', orderBy: 'uid', filterKey: 'sessionIdFilter', sortable: true},
    {
      label: 'Game',
      orderBy: 'game',
      filterKey: 'gameFilter',
      selectOptions: gameOptions,
      sortable: true
    },
    {
      label: 'Game type',
      orderBy: 'gameType',
      filterKey: 'gameTypeFilter',
      selectOptions: gameTypeOptions,
      sortable: true
    },
    {label: 'Total bet', orderBy: 'bet_real', filterKey: 'betFilter', sortable: true},
    {label: 'Total win', orderBy: 'win_real', filterKey: 'winFilter', sortable: true},
    {label: 'Total jackpot', orderBy: 'jackpot_total', filterKey: 'jackpotFilter', sortable: true},
    {label: 'Total FS', filterKey: 'fsFilter'},
    {
      label: 'Currency',
      orderBy: 'currency',
      filterKey: 'currencyFilter',
      selectOptions: currencySelectOptions,
      sortable: true
    },
    {label: 'Start', orderBy: 'created_at', filterKey: 'startFilter', sortable: true},
    {label: 'End', orderBy: 'end_datetime', filterKey: 'endFilter', sortable: true},
    {
      label: 'Status',
      orderBy: 'status',
      filterKey: 'statusFilter',
      selectOptions: STATUS_OPTIONS,
      sortable: true
    },
  ]

  const cells = (data: SessionsData) => [
    <Box className={stylesCommon.uid}>
      {data.uid}
      <CopyToClipboard text={data.uid}>
        <IconButton size="small">
          <ContentCopyIcon fontSize="small"/>
        </IconButton>
      </CopyToClipboard>
    </Box>,
    data.game.name,
    data.gameType.name,
    data.bet_real,
    data.win_real,
    data.jackpot_total,
    'N/A',
    data.currency.code,
    formatDate(data.created_at),
    formatDate(data.end_datetime),
    data.status,
    <TableCell key={`link-${data.id}`}><Link to={`/transactions/${data.id}`}>View transaction</Link></TableCell>
  ]

  return (
    <Box>
      <h3>Game Activity</h3>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) =>
                header.sortable ? (
                  <SortableFilterableTableCell
                    key={index}
                    label={header.label}
                    orderBy={header.orderBy || ''}
                    currentOrderBy={orderBy}
                    order={order}
                    filterValue={filters[header.filterKey as keyof typeof filters] || ''}
                    onSort={() => header.orderBy && handleRequestSort(header.orderBy)}
                    onFilterChange={(e) => handleFilterChange(header.filterKey || '', e.target.value)}
                    placeholder={`Filter by ${header.label}`}
                    selectOptions={header.selectOptions}
                  />
                ) : (
                  <TableCell key={index}>{header.label}</TableCell>
                )
              )}
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((element) => (
              <TableRow key={element.id}>
                {cells(element).map((cell, index) => (
                  <TableCell key={`${element.id}-${index}`}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {dataSessionInfo?.pagination && (
        <Pagination
          variant={'sessions'}
          onPageChange={(value) => setCurrentPage(Number(value))}
          totalCount={dataSessionInfo.pagination.total_count}
          currentPage={currentPage}
          pageSize={itemsPerPage}
          siblingCount={SIBLING_COUNT}
          selectSettings={{
            value: itemsPerPage.toString(),
            onChangeOption: (value: string) => {
              setItemsPerPage(Number(value)) // Change the number of items per page
              setCurrentPage(1) // Reset current page to 1
            },
            arr: TRANSACTIONS_PAGINATION_VALUES.map((val) => val.toString())
          }}
        />
      )}
    </Box>
  )
}
