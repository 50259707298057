// getPlayerProfileSettingsActions.js
import axios from 'axios';
import config from '../../../config';

export const ACTION_GET_PLAYER_PROFILE_SETTINGS_REQUEST = 'ACTION_GET_PLAYER_PROFILE_SETTINGS_REQUEST';
export const ACTION_GET_PLAYER_PROFILE_SETTINGS_SUCCESS = 'ACTION_GET_PLAYER_PROFILE_SETTINGS_SUCCESS';
export const ACTION_GET_PLAYER_PROFILE_SETTINGS_FAILURE = 'ACTION_GET_PLAYER_PROFILE_SETTINGS_FAILURE';

export const actionGetPlayerProfileSettingsRequest = () => ({
  type: ACTION_GET_PLAYER_PROFILE_SETTINGS_REQUEST,
});


export const actionGetPlayerProfileSettingsSuccess = (data, messages) => ({
  type: ACTION_GET_PLAYER_PROFILE_SETTINGS_SUCCESS,
  payload: { data, messages },
});

export const actionGetPlayerProfileSettingsFailure = (error) => ({
  type: ACTION_GET_PLAYER_PROFILE_SETTINGS_FAILURE,
  payload: { error },
});


//For Express
export const fetchGetPlayerProfileSettings = (token) => async (dispatch) => {

  // Инициализируем запрос
  dispatch(actionGetPlayerProfileSettingsRequest());

  try {
    console.log('Attempting to fetchGetPlayerProfileSettings:' + `${config.serverUrl}/setting/get-player-profile-settings`, { token });

    const response = await axios.get(`${config.serverUrl}/setting/get-player-profile-settings`,
      { token },
      {
        headers: {
          'Content-Type': 'application/json',
        }
      });

    const data = response.data;
    if (response.status === 200 && data.success) {
      console.log('fetchGetPlayerProfileSettings: ', data, data.data, data.messages); 
      dispatch(actionGetPlayerProfileSettingsSuccess(data.data, data.messages));
    } else {
      console.error('fetchGetPlayerProfileSettings error:', data.errors);
      dispatch(actionGetPlayerProfileSettingsFailure(data.errors, data.message || 'Error get player profile settings.'));
    }
  } catch (error) {
    if (error.response) {
      console.error(`Error: ${error.response.status}`, error.response.data);

      if (error.response.status === 404) {
        dispatch(actionGetPlayerProfileSettingsFailure(null, 'API endpoint not found.'));
      } else {
        dispatch(actionGetPlayerProfileSettingsFailure(error.response.data.error, error.response.data.message || 'Server error.'));
      }
    } else {
      console.error('fetchGetPlayerProfileSettings network error:', error.message);
      dispatch(actionGetPlayerProfileSettingsFailure(null, 'Network error: Unable to connect to the server.'));
    }
  }
};

