// get_Personal_InfoActions.js
import axios from 'axios';
import config from '../../../config';

export const ACTION_GET_PERSONAL_INFO_REQUEST = 'ACTION_GET_PERSONAL_INFO_REQUEST';
export const ACTION_GET_PERSONAL_INFO_SUCCESS = 'ACTION_GET_PERSONAL_INFO_SUCCESS';
export const ACTION_GET_PERSONAL_INFO_FAILURE = 'ACTION_GET_PERSONAL_INFO_FAILURE';

export const actionGetPersonalInfoRequest = () => ({
  type: ACTION_GET_PERSONAL_INFO_REQUEST,
});


const actionGetPersonalInfoSuccess = (data, messages) => ({
  type: ACTION_GET_PERSONAL_INFO_SUCCESS,
  payload: { data, messages },
});

export const actionGetPersonalInfoFailure = (errors, messages) => ({
  type: ACTION_GET_PERSONAL_INFO_FAILURE,
  payload: { errors, messages },
});


//For Express
export const fetchGetPersonalInfo = (token) => async (dispatch) => {

  // Инициализируем запрос
  dispatch(actionGetPersonalInfoRequest());

  try {
    console.log('Attempting to fetchGetPersonalInfo:', `${config.serverUrl}/combined/get-combined-personal-info`, { token });

    const response = await axios.get(`${config.serverUrl}/combined/get-combined-personal-info`, {
      params:{
        token: token
      },
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    const data = response.data;

    // Проверяем, успешен ли запрос и данные
    if (response.status === 200 && data.success) {
      console.log('fetchGetPersonalInfo success:', data);
      dispatch(actionGetPersonalInfoSuccess(data.data, data.messages));
    } else {
      // Обработка ошибок при статусе 200, но с success: false
      console.error('fetchGetPersonalInfo error:', data.errors);
      dispatch(actionGetPersonalInfoFailure(data.errors, data.messages || 'Error getting personal info.'));
    }

  } catch (error) {
    if (error.response) {
      // Ошибка, если есть ответ с сервера
      console.error(`Error: ${error.response.status}`, error.response.data);
      dispatch(actionGetPersonalInfoFailure(error.response.data.errors || ['Server error'], error.response.data.messages || 'Server error.'));
    } else {
      // Ошибка сети или иная ошибка без ответа от сервера
      console.error('fetchGetPersonalInfo network error:', error.message);
      dispatch(actionGetPersonalInfoFailure(['Network error: Unable to connect to the server.'], 'Network error.'));
    }
  }
};
