//ResponsibleGaming.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectLoading, selectError } from '../../redux/selectors/balanceSelectors';
import GameGroups from '../elems/GameGroups/GameGroups';
import Providers from '../elems/Providers';
import withAuth from '../hoc/withAuth';  // Import the HOC

const ResponsibleGaming = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Пример. Потом ошибки и загрузку выводим из хранилища.
  // const loading = useSelector(selectLoading);
  // const error = useSelector(selectError);

  //Пример. Локальное состояние. loading и error
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 200);
    
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : <div>
        <h1>ResponsibleGaming</h1>
        {/* <GameGroups />
        <Providers /> */}
      </div>
      }
    </>
  );
}

// export default withAuth(ResponsibleGaming);
export default ResponsibleGaming;