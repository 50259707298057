// PasswordInput.js
import React from 'react';

const PasswordInput = ({ id, name, value, onChange, required, placeholder, error }) => (
  <div className="form-group mb-3">
    <label htmlFor={id} className="form-label">
      <strong>{placeholder} {required && <span style={{ color: 'red' }}>*</span>}</strong>
    </label>
    <input
      type="password"
      id={id}
      name={name}
      className={`form-control ${error ? 'is-invalid' : ''}`}
      value={value}
      onChange={onChange}
      required={required}
      placeholder={placeholder}
      data-error-required={`${placeholder} is required`}
    />
    <div className="invalid-feedback">
      {error || ''}
    </div>
  </div>
);

export default PasswordInput;