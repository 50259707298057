// playerBalancesSelectors.js
import { RootState } from '../../store';

export const selectPlayerBalances             = (state: RootState) => state.playerBalancesReducer.data;
export const selectPlayerBalancesMessages     = (state: RootState) => state.playerBalancesReducer.messages;
export const selectPlayerBalancesLoading      = (state: RootState) => state.playerBalancesReducer.loading;

export const selectPlayerBalancesErrorString = (state: RootState): string | null => {
    const error = state.playerBalancesReducer.error;
  
    if (Array.isArray(error)) {
      return error.join('\n');
    }
  
    if (typeof error === 'object' && error !== null) {
      return Object.entries(error)
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n');
    }
  
    return error;
  };