import axios from 'axios';
import config from '../../../config.js';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../reducers'; // Проверьте правильность пути к корневому редьюсеру

// Определяем типы для действий
export const ACTION_GET_PAYMENT_METHODS_REQUEST = 'ACTION_GET_PAYMENT_METHODS_REQUEST';
export const ACTION_GET_PAYMENT_METHODS_SUCCESS = 'ACTION_GET_PAYMENT_METHODS_SUCCESS';
export const ACTION_GET_PAYMENT_METHODS_FAILURE = 'ACTION_GET_PAYMENT_METHODS_FAILURE';

// Определяем интерфейсы для успешного и неудачного ответа
interface SuccessResponse {
  success: true;
  data: any; // Замените `any` на точный тип данных, если возможно
  messages: string[];
}

interface FailureResponse {
  success: false;
  errors: string[];
  message?: string;
}

// Тип объединения для ответа
type ApiResponse = SuccessResponse | FailureResponse;

// Интерфейсы для действий
interface ActionGetPaymentMethodsRequest {
  type: typeof ACTION_GET_PAYMENT_METHODS_REQUEST;
}

interface ActionGetPaymentMethodsSuccess {
  type: typeof ACTION_GET_PAYMENT_METHODS_SUCCESS;
  payload: {
    data: any; // Замените `any` на точный тип данных, если возможно
    messages: string[];
  };
}

interface ActionGetPaymentMethodsFailure {
  type: typeof ACTION_GET_PAYMENT_METHODS_FAILURE;
  payload: {
    error: string;
    message?: string;
  };
}

type PaymentMethodsActions = 
  | ActionGetPaymentMethodsRequest
  | ActionGetPaymentMethodsSuccess
  | ActionGetPaymentMethodsFailure;

// Создание экшенов
export const actionGetPaymentMethodsRequest = (): ActionGetPaymentMethodsRequest => ({
  type: ACTION_GET_PAYMENT_METHODS_REQUEST,
});

export const actionGetPaymentMethodsSuccess = (data: any, messages: string[]): ActionGetPaymentMethodsSuccess => ({
  type: ACTION_GET_PAYMENT_METHODS_SUCCESS,
  payload: { data, messages },
});

export const actionGetPaymentMethodsFailure = (error: string, message?: string): ActionGetPaymentMethodsFailure => ({
  type: ACTION_GET_PAYMENT_METHODS_FAILURE,
  payload: { error, message },
});

// Функция проверки типа (тип предиката)
const isSuccessResponse = (response: ApiResponse): response is SuccessResponse => response.success === true;

const isErrorResponse = (response: ApiResponse): response is FailureResponse => response.success === false;

// Функция для получения методов оплаты
export const fetchGetPaymentMethods = (
  token: string,
  id_currency: string,
  direction: string,
  language: number
): ThunkAction<Promise<void>, RootState, unknown, PaymentMethodsActions> => async (dispatch: Dispatch<PaymentMethodsActions>) => {

  const params = { token, id_currency, direction, language };

  dispatch(actionGetPaymentMethodsRequest());
  try {
    console.log('Attempting to fetchGetPaymentMethods:' + `${config.serverUrl}/payment/get-payment-methods`, params);

    const response = await axios.get<ApiResponse>(`${config.serverUrl}/payment/get-payment-methods`, {
      params,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });

    const data = response.data;

    console.log('fetchGetPaymentMethods data (after ajax): ', data);
    if (response.status === 200 && isSuccessResponse(data)) {
      console.log('fetchGetPaymentMethods data (response.status === 200 && data.success): ', data);
      dispatch(actionGetPaymentMethodsSuccess(data.data, data.messages));
    } else if (isErrorResponse(data)) {
      console.error('fetchGetPaymentMethods error:', data.errors);
      dispatch(actionGetPaymentMethodsFailure(data.errors.join(', '), data.message || 'Error get payment methods.'));
    }
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      console.error(`Error: ${error.response.status}`, error.response.data);

      if (error.response.status === 404) {
        dispatch(actionGetPaymentMethodsFailure('API endpoint not found.'));
      } else {
        dispatch(actionGetPaymentMethodsFailure(error.response.data.error || 'Unknown error', error.response.data.message || 'Server error.'));
      }
    } else {
      console.error('fetchGetPaymentMethods network error:', error.message);
      dispatch(actionGetPaymentMethodsFailure('Network error: Unable to connect to the server.'));
    }
  }
};
