//PlayerDeposit.js

import config from '../../../config.js';
import React, { useState, useEffect, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch } from "../../../hooks"
import { selectIsAuthenticated, selectToken } from '../../../redux/selectors/site/loginSelectors.js';
import {
    selectPlayerBalances,
    selectPlayerBalancesLoading,
    selectPlayerBalancesErrorString
} from '../../../redux/selectors/playerDeposit/playerBalancesSelectors';
import { fetchGetPlayerBalances } from '../../../redux/actions/playerDeposit/playerBalancesActions';
import {
    selectGetPaymentMethods,
    selectGetPaymentMethodsLoading,
    selectGetPaymentMethodsErrorString
} from '../../../redux/selectors/payment/getPaymentMethodsSelectors';
import { fetchGetPaymentMethods } from '../../../redux/actions/payment/getPaymentMethodsActions';

import {
    selectCurrentView,
    selectPreviousView,
    selectViews
} from '../../../redux/selectors/playerDeposit/viewSelectors';
import { setView, setNextView } from '../../../redux/actions/playerDeposit/viewActions';

import CurrencyAndPayment from './blocks/CurrencyAndPayment';
import DepositDetails from './forms/DepositDetails';
import FormWrapper from './forms/FormWrapper';
import styles from './PlayerDeposit.module.scss';

interface Balance{
    id: string;
    currency_id: string;
    currency_title: string;
    currency_code: string;
    currency_symbol: string | null;
    currency_flag_active: string;
    flag_main: string;
    amount: string;
    comment: string | null;
    created_at: string;
    updated_at: string;
}

export interface PaymentMethod {
    id: string;
    name: string;
    image_path: string;
    direction: string;
    min_deposit: string;
    max_deposit: string;
    pay_system_id: string;
    pay_system_name: string;
    pay_system_tech_name: string;
    currency_id: string;
    currency_title: string;
    currency_code: string;
    currency_symbol: string | null;
    fields: any[]; // Можете уточнить тип для полей, если они будут известны
}

interface PaymentFields {
    // Определите точные поля, которые ожидаются в объекте fields
    [key: string]: any;
}

const imgDir = config.fileUrls.payment;
const dafaultImg = "https://img.freepik.com/premium-vector/content-delivery-network-concept-proxy-anonymous-vpn-vector-design-cloud-processing-computing_135661-1393.jpg?semt=ais_hybrid";

const PlayerDeposit = () => {
    const dispatch = useAppDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);

    const views = useSelector(selectViews);
    const currentView = useSelector(selectCurrentView);

    const playerBalances = useSelector(selectPlayerBalances);
    const playerBalancesLoading = useSelector(selectPlayerBalancesLoading);
    const playerBalancesErrorString = useSelector(selectPlayerBalancesErrorString);

    const getPaymentMethods = useSelector(selectGetPaymentMethods);
    const getPaymentMethodsLoading = useSelector(selectGetPaymentMethodsLoading);
    const getPaymentMethodsErrorString = useSelector(selectGetPaymentMethodsErrorString);

    const [selectedCurrency, setSelectedCurrency] = useState("");
    const [selectedPaymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
    const [isButtonContinueClick, setIsButtonContinueClick] = useState(false);

    const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

    useEffect(() => {
        // Для переключения форм
        if (isAuthenticated && token) {
            dispatch(fetchGetPlayerBalances(token));
        }
        
        console.log('component fetchGetPlayerBalances');
    }, [dispatch, token])

    useEffect(() => {
        const defaultCurrency = playerBalances.find(
            (balance: Balance) => balance.flag_main === "YES" && balance.currency_flag_active === "YES"
        );
        if (defaultCurrency) {
            setSelectedCurrency(defaultCurrency);
            findPaymentMethods(defaultCurrency.currency_id);
        }
    }, [playerBalances]);

    useEffect(() => {
        console.log('PlayerDeposit::playerBalances', playerBalances);
        console.log('PlayerDeposit::selectedCurrency', selectedCurrency);
    }, [playerBalances]);

    useEffect(() => {
        console.log('PlayerDeposit::getPaymentMethods', getPaymentMethods);
        console.log('PlayerDeposit::currentView', currentView);

        if (getPaymentMethods.length > 0) {
            const defaultPaymentMethod = getPaymentMethods[0];

            if (defaultPaymentMethod) {
                setPaymentMethod(defaultPaymentMethod);
            }
        }

    }, [getPaymentMethods, currentView]);

    // useEffect(() => {
    //     console.log('PlayerDeposit::selectedPaymentMethod', selectedPaymentMethod);
    // }, [selectedPaymentMethod]);

    // Функция для получения методов оплаты
    const findPaymentMethods = (currency_id: string) => {
        const direction = 'deposit';
        const language = 2; // TODO: Получить из приложения
        token && dispatch(fetchGetPaymentMethods(token, currency_id, direction, language));
    };

    const handleCurrencyChange = (currency_id: string) => {
        const selected = playerBalances.find((balance: Balance) => balance.currency_id === currency_id);
        setSelectedCurrency(selected);
        setPaymentMethod(null);
        findPaymentMethods(selected.currency_id);
    };

    const handlePaymentMethodChange = (id: string) => {
        const selectedMethod = getPaymentMethods.find((method: PaymentMethod) => method.id === id);
        setPaymentMethod(selectedMethod);
    };

    const handleButtonContinueClick = () => {
        setIsButtonContinueClick(true);
        if (selectedCurrency && selectedPaymentMethod) {
            dispatch(setNextView());
        }
    };

    // handleSubmit: (e: FormEvent, fieldsToSave: { amount: string }) => void;
    const handleCreatePaymentSubmit = (e: FormEvent<HTMLFormElement>, fieldsToSave: PaymentFields): void => {
        e.preventDefault();
        console.log('PlayerDeposit: handleCreatePaymentSubmit', fieldsToSave);
    
        // Пример использования dispatch
        // try {
        //     const result = await dispatch(yourAjaxAction(fields));
        //     if (result.meta.requestStatus === 'fulfilled') {
        //         setIsSuccess(true); // Сохранение успешности в стейте
        //         console.log('Operation successful', result.payload);
        //     } else {
        //         console.error('Operation failed');
        //     }
        // } catch (error) {
        //     console.error('Dispatch error:', error);
        // }
        
        setIsPaymentSuccess(true);
        // TODO доделать сохранение. Нужны методы в апи
    };

    const renderView = () => {
        switch (currentView) {
            case 'CurrencyAndPayment':
                return (
                    <CurrencyAndPayment
                        playerBalances={playerBalances}
                        getPaymentMethods={getPaymentMethods}
                        playerBalancesLoading={playerBalancesLoading}
                        playerBalancesErrorString={playerBalancesErrorString}
                        getPaymentMethodsLoading={getPaymentMethodsLoading}
                        getPaymentMethodsErrorString={getPaymentMethodsErrorString}
                        selectedCurrency={selectedCurrency}
                        selectedPaymentMethod={selectedPaymentMethod}
                        handleCurrencyChange={handleCurrencyChange}
                        handlePaymentMethodChange={handlePaymentMethodChange}
                        handleButtonContinueClick={handleButtonContinueClick}
                        isButtonContinueClick={isButtonContinueClick}
                    />
                );

            case 'DepositDetails':
                return (
                    <DepositDetails
                        selectedCurrency={selectedCurrency}
                        selectedPaymentMethod={selectedPaymentMethod}
                        handleSubmit={handleCreatePaymentSubmit}
                    />
                );

            default:
                return null; // Можно вернуть null или какой-то компонент по умолчанию
        }
    };

    return (
        <FormWrapper
            alertProps={isPaymentSuccess ? { type: "success", message: "Operation was successful", onClose: () => setIsPaymentSuccess(false) } : null}
        >
            {renderView()} {/* Вызов функции для рендеринга текущего вида */}
        </FormWrapper>
    );
};

export default PlayerDeposit;
