// selectors.ts - селекторы с TypeScript
import { RootState } from '../../store';

export const selectGetDepositBonusList = (state: RootState) => state.bonusDepositListReducer.data;
export const selectGetDepositBonusListMessages = (state: RootState) => state.bonusDepositListReducer.messages;
export const selectGetDepositBonusListLoading = (state: RootState) => state.bonusDepositListReducer.loading;

export const selectGetDepositBonusListErrorString = (state: RootState): string | null => {
  const error = state.bonusDepositListReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

