// listBalancesReducer.js
import { FETCH_LISTBALANCES_REQUEST, FETCH_LISTBALANCES_SUCCESS, FETCH_LISTBALANCES_FAILURE } from '../../actions/player/listBalances';

const initialState = {
    listBalances: [],
    loading: false,
    error: null,
    messages: null,
  };
  
  const listBalancesReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_LISTBALANCES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_LISTBALANCES_SUCCESS:
        return {
          ...state,
          loading: false,
          listBalances: action.payload,
        };
      case FETCH_LISTBALANCES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default listBalancesReducer;
  