// config.js

const config = {
  serverUrl: 'https://node-server.spaceduckers.info', // node.js server url
  serverWebsocketPort: 8089,
  serverWebsocketUrl: 'wss://node-server.spaceduckers.info/socket',  //эндпоинт которы обробатывает nginx для подключения к сокету. 
  serverApiPort: 8089,
  apiBaseUrl: 'https://api.sloterra8.com',
  fileServerBaseUrl: 'https://spaceduckers.online',
  fileUrls: {
    construct:  'https://spaceduckers.online/upfiles/construct/',
    game:  'https://spaceduckers.online/upfiles/game/',
    bonusFolder: 'https://spaceduckers.online/upfiles/bonus/',
    kycFolder: 'https://spaceduckers.online/kyc',
    rootFolder: 'https://spaceduckers.online',
    payment:     'https://spaceduckers.online/upfiles/payment/',
    gameGroup: 'https://spaceduckers.online/upfiles/gamegroup/'
  }
};

module.exports = config;
