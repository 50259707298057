import React, {useEffect, useState} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import LayoutWrapper from '../components/layouts/LayoutWrapper/LayoutWrapper.tsx';

import {
    Accounts,
    BasePage,
    Bonuses,
    BonusHistory,
    Documents,
    FavoriteGames,
    GameGroup,
    Home,
    // Login,
    Notifications,
    PersonalInformation,
    Provider,
    ResponsibleGaming,
    AuthPage,
    VipClub
} from '../components/pages' 
import {Home as HomeGuest} from '../components/pages/guest' 
import {useSelector} from 'react-redux' 
import StateLogger from '../helpers/components/StateLogger' 
import {selectIsAuthenticated} from '../redux/selectors/site/loginSelectors' 
import {Faq} from "../components/pages/Faq.tsx" 
import {NotFound} from "../components/pages/NotFound" 
import {Transactions} from "../components/pages/Transactions/Transactions" 
import {Page} from "../components/pages/Page" 
import {Game} from "../components/pages/Game/Game" 
import {SeoUpdater} from "../common/SeoUpdater" 
import {SeoRedirecter} from "../common/SeoRedirecter" 
import {PageList} from "../components/pages/PageList/PageList" 
import {createTheme, ThemeProvider} from "@mui/material" 
import {SessionTransaction} from "../components/pages/Transactions/GameActivity/SessionTransaction/SessionTransaction" 
import 'src/common/styles/index.scss'
import styles from './App.module.scss'

function App() {
    const isAuthenticated = useSelector(selectIsAuthenticated)

    // state for MUI theme
    const [theme, setTheme] = useState(createTheme({
        typography: {
            button: {
                textTransform: 'none'
            }
        }
    })) 


    //инициализация селекторов
    //const bonusList = useSelector(selectBonusList) 
    // const listFavoriteGames = useSelector(selectListFavoriteGames) 

    useEffect(() => {
        // В момент загрузки страницы
        // Токен сохраняется при удачном логине в локальном хранилище и в редаксе. Если страницу обновить,
        // то в редаксе теряется токен, поэтому он снова тут устанавливается, если он есть в локалсторе.
        // Таким образом при обновлении страницы пользователь не разлогинивается

        // if (storedToken) {
        //   dispatch(fetchLoginSuccess(storedToken)) 
        // }

        localStorage.setItem('groupId', '9')

        // document.body.classList.add('light-theme') 

        // === Theme Switcher ===
        const prefersLightMode = window.matchMedia("(prefers-color-scheme: light)").matches 
        if (prefersLightMode) {
            document.body.classList.add('light-theme') 
            document.body.classList.remove('dark-theme') 
        } else {
            document.body.classList.add('dark-theme') 
            document.body.classList.remove('light-theme') 
        }

        // Extracting text colors for MUI
        const styles = getComputedStyle(document.body) 
        const primaryTextColor = styles.getPropertyValue('--text-base-primary')
        const secondaryTextColor = styles.getPropertyValue('--text-base-secondary')

        const updatedTheme = createTheme({
            palette: {
                text: {
                    primary: primaryTextColor,
                    secondary: secondaryTextColor,
                },
            },
            typography: {
                button: {
                    textTransform: 'none',
                },
            },
        }) 

        setTheme(updatedTheme) 

        // Event handler for system theme changes
        const themeChangeListener = (e) => {
            if (e.matches) {
                document.body.classList.add('light-theme') 
                document.body.classList.remove('dark-theme') 
            } else {
                document.body.classList.add('dark-theme') 
                document.body.classList.remove('light-theme') 
            }

            // Update colors from CSS variables when theme changes
            const updatedStyles = getComputedStyle(document.body) 
            const newPrimaryTextColor = updatedStyles.getPropertyValue('--text-base-primary') 
            const newSecondaryTextColor = updatedStyles.getPropertyValue('--text-base-secondary') 

            // Create a new MUI theme with updated colors
            const newTheme = createTheme({
                palette: {
                    text: {
                        primary: newPrimaryTextColor,
                        secondary: newSecondaryTextColor,
                    },
                },
                typography: {
                    button: {
                        textTransform: 'none',
                    },
                },
            }) 

            setTheme(newTheme) 
        } 

        // Add listener for system theme changes
        const lightThemeMediaQuery = window.matchMedia("(prefers-color-scheme: light)") 
        lightThemeMediaQuery.addEventListener("change", themeChangeListener) 

        // Clean up listener when component unmounts
        return () => {
            lightThemeMediaQuery.removeEventListener("change", themeChangeListener) 
        } 
    }, []) 


    // const BasePageRoute = ({registered: RegisteredComponent, guest: GuestComponent}) => (
    //     <BasePage>
    //         {isAuthenticated ? <RegisteredComponent/> : <GuestComponent/>}
    //     </BasePage>
    // );

    const BasePageRoute = ({ registered: RegisteredComponent, guest: GuestComponent }) => {
        const isAuthenticated = useSelector(selectIsAuthenticated);
    
        if (isAuthenticated) {
            return (
                <BasePage>
                    <RegisteredComponent />
                </BasePage>
            );
        }
    
        // Если `GuestComponent` — это `<Navigate />`, рендерим его для редиректа,
        // иначе — рендерим как обычный компонент для гостя.
        return typeof GuestComponent.type === Navigate ? GuestComponent : (
            <BasePage>
                <GuestComponent />
            </BasePage>
        );
    };

    return (
        <div className={styles.app}>
            <StateLogger/>
            <BrowserRouter>
                <LayoutWrapper>
                    <SeoRedirecter>
                        <SeoUpdater>
                            <ThemeProvider theme={theme}>
                                <Routes>
                                    {/* <Route path="/login" element={<Login/>}/>
                                    <Route path="/signup" element={<Signup/>}/> */}
                                    <Route path="/auth" element={<AuthPage/>}/>
                                    <Route path="/auth/:param?" element={<AuthPage/>}/>
                                    {/* <Route path="/auth" element={<BasePageRoute registered={Home} guest={<Navigate to="/auth"/>}/>}/> */}
                                    <Route path="/" element={<BasePageRoute registered={Home} guest={HomeGuest}/>}/>

                                    <Route path="/notifications"
                                           element={<BasePageRoute registered={Notifications} guest={<Navigate to="/auth"/>}/>}/>
                                    <Route path="/favorite-games"
                                           element={<BasePageRoute registered={FavoriteGames} guest={<Navigate to="/auth"/>}/>}/>
                                    <Route path="/accounts"
                                           element={<BasePageRoute registered={Accounts} guest={<Navigate to="/auth"/>}/>}/>
                                    <Route path="/personal-information"
                                           element={<BasePageRoute registered={PersonalInformation} guest={<Navigate to="/auth"/>}/>}/>
                                    <Route path="/documents"
                                           element={<BasePageRoute registered={Documents} guest={<Navigate to="/auth"/>}/>}/>
                                    <Route path="/transactions"
                                           element={<BasePageRoute registered={Transactions} guest={<Navigate to="/auth"/>}/>}/>
                                    <Route path="/transactions/:transactionId"
                                           element={<BasePageRoute registered={SessionTransaction} guest={<Navigate to="/auth"/>}/>}/>
                                    <Route path="/bonus-history"
                                           element={<BasePageRoute registered={BonusHistory} guest={<Navigate to="/auth"/>}/>}/>
                                    <Route path="/responsible-gaming"
                                           element={<BasePageRoute registered={ResponsibleGaming} guest={<Navigate to="/auth"/>}/>}/>
                                    <Route path="/bonuses"
                                           element={<BasePageRoute registered={Bonuses} guest={<Navigate to="/auth"/>}/>}/>
                                    <Route path="/vip" element={<BasePageRoute registered={VipClub} guest={<Navigate to="/auth"/>}/>}/>

                                    {/* game provider */}
                                    <Route path="/provider/:id"
                                           element={<BasePageRoute registered={Provider} guest={<Navigate to="/auth"/>}/>}/>

                            {/* game provider */}
                            <Route path="/game-group/:gameGroupSlug"
                                   element={<BasePageRoute registered={GameGroup} guest={<Navigate to="/auth"/>}/>}/>

                                    <Route path="/faq" element={<Faq/>}/>
                                    <Route path="/page/:slug" element={<Page/>}/>
                                    <Route path="/games/:slug" element={<Game/>}/>
                                    <Route path="/pagelist" element={<PageList/>}/>
                                    <Route path="/404" element={<NotFound/>}/>
                                    <Route path="*" element={<Navigate to="/404"/>}/>
                                </Routes>
                            </ThemeProvider>
                        </SeoUpdater>
                    </SeoRedirecter>
                </LayoutWrapper>
            </BrowserRouter>
        </div>
    ) 
}

export default App 
