// gameGroupsActions.js
import axios from 'axios';
import config from '../../../config';
import { deviceDetector } from '../../../helpers/deviceDetector';

export const FETCH_GAMEGROUPS_REQUEST = 'FETCH_GAMEGROUPS_REQUEST';
export const FETCH_GAMEGROUPS_SUCCESS = 'FETCH_GAMEGROUPS_SUCCESS';
export const FETCH_GAMEGROUPS_FAILURE = 'FETCH_GAMEGROUPS_FAILURE';

// Экшены для получения данных
export const fetchGameGroupsRequest = () => ({
  type: FETCH_GAMEGROUPS_REQUEST,
});

export const fetchGameGroupsSuccess = (gameGroups) => ({
  type: FETCH_GAMEGROUPS_SUCCESS,
  payload: gameGroups,
});

export const fetchGameGroupsFailure = (error) => ({
  type: FETCH_GAMEGROUPS_FAILURE,
  payload: error,
});

export const fetchGameGroups = (token, language = null) => async (dispatch) => {
  dispatch(fetchGameGroupsRequest());
  try {
    const response = await axios.get(`${config.apiBaseUrl}/game/get-groups`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
      params: {
        language: language
      }
    });
    
    dispatch(fetchGameGroupsSuccess(response.gameGroups.data));
  } catch (error) {
    dispatch(fetchGameGroupsFailure(error.message));
  }
};

// Экшены для WebSocket
export const connectWebSocket = (token, language = null) => {
  return (dispatch) => {
    const ws = new WebSocket( config.serverWebsocketUrl );

    ws.onopen = () => {
      console.log('Connected to WebSocket server getGameGroups');
      var device = deviceDetector().device.type;
      console.log('device' , device);
      ws.send(JSON.stringify({ type: 'getGameGroups', token, language, device }));
      
    };

    ws.onmessage = (event) => {
      console.log('WebSocket message received getGameGroups:', event.data);
      try {
        const data = JSON.parse(event.data);
        console.log(data);
        if (data.type === 'getGameGroups') {
          console.log('GameGroups received:', data.gameGroups.data);
          dispatch(fetchGameGroupsSuccess(data.gameGroups.data));
        } else if (data.type === 'error') {
          dispatch(fetchGameGroupsFailure(data.message));
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error.message);
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      ws.close();
    };
  };
};
