import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import config from '../../config'
import {GameWidgetsSocketResponse} from "../../api/types"

type FavoriteGameState = {
  data: GameWidgetsSocketResponse | null;
  loading: boolean;
  error: string | null;
};

const initialState: FavoriteGameState = {
  data: null,
  loading: false,
  error: null,
}

export const getGameWidgets = createAsyncThunk<
  GameWidgetsSocketResponse,
  { token: string, language: number }
>('construct/getGameWidgets', async ({token, language}) => {
  return new Promise((resolve, reject) => {
    const ws = new WebSocket(config.serverWebsocketUrl)

    ws.onopen = () => {
      ws.send(JSON.stringify({type: 'getGameWidgets', token, language}))
    }

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data)
        resolve(data)
        ws.close()
      } catch (error) {
        reject('Error parsing WebSocket data')
      }
    }

    ws.onclose = () => {
      console.log('WebSocket connection closed')
    }
  })
})

const getGameWidgetsSlice = createSlice({
  name: 'getGameWidgets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGameWidgets.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getGameWidgets.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(getGameWidgets.rejected, (state, action) => {
        state.loading = false
        state.data = null
        state.error = action.error.message || 'Failed to fetch game widgets'
      })
  },
  selectors: {
    getGameWidgetsSelector: state => state,
  },
})

export const getGameWidgetsReducer = getGameWidgetsSlice.reducer
export const {getGameWidgetsSelector} = getGameWidgetsSlice.selectors
