import {useGetContentBlockQuery} from '../../../api/api'
import styles from './ContentBlock.module.scss'
import config from '../../../config'
import {Link} from 'react-router-dom'
import {useAppSelector} from '../../../hooks'
import {interfaceSelector} from '../../../redux/slices/interfaceSlice'
import clsx from 'clsx'
import Box from "@mui/material/Box"
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import {ButtonStyled} from "../../../common/componentsStyled/ButtonStyled"

type Props = {
  blockId: number
}

// in Figma: SEO block
export const ContentBlock = ({blockId}: Props) => {
  const {selectedLanguage} = useAppSelector(interfaceSelector)

  const {data, isLoading, error} = useGetContentBlockQuery({language: selectedLanguage.id, id: blockId})

  if (error) return <div/>

  if (isLoading) {
    return <div>ContentBlock loading ...</div>
  }

  if (!data || !data.data) {
    return null
  }

  const Wrapper = data.data.flag_full_area_clickable === 'YES' ? Link : 'div'

  return (
    <Box className={clsx(styles.contentBlockContainer, stylesCommon.group)}>
      <Wrapper
        to={data.data.link_href}
        className={clsx(styles.contentWrapper, {
          [styles.linkWrapper]: data.data.flag_full_area_clickable === 'YES',
        })}
      >
        <div className={styles.contentText}>
          {data.data.flag_show_title === 'YES' && <div className={styles.title}>{data.data.title}</div>}
          <div
            className={styles.body}
            dangerouslySetInnerHTML={{__html: data.data.body}}
          />
          {data.data.flag_full_area_clickable !== 'YES' && (
            <ButtonStyled to={data.data.link_href} variant={'glass'} paddingVariant={'14px 18px'}>
              {data.data.link_label}
            </ButtonStyled>
          )}
        </div>
        <div className={styles.contentImage}>
          <img src={`${config.fileUrls.rootFolder}${data.data.image_path}`} alt={data.data.title}/>
        </div>
      </Wrapper>
    </Box>
  )
}
