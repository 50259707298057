// balancesReducer.js
import { FETCH_BALANCES_REQUEST, FETCH_BALANCES_SUCCESS, FETCH_BALANCES_FAILURE } from '../actions/balancesActions';

const initialState = {
    balances: null,
    loading: false,
    error: null,
    messages: null,
  };
  
  const balancesReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_BALANCES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_BALANCES_SUCCESS:
        return {
          ...state,
          loading: false,
          balances: action.payload,
        };
      case FETCH_BALANCES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default balancesReducer;
  