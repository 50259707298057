import { FETCH_LOGIN_REQUEST, FETCH_LOGIN_SUCCESS, FETCH_LOGIN_FAILURE } from '../../actions/site/loginActions';
import { CLEAR_AUTH_DATA } from '../../actions/site/logoutActions';

const persistedLoginState = localStorage.getItem('loginState')
  ? JSON.parse(localStorage.getItem('loginState'))
  : {};

const initialState = {
    token: persistedLoginState.token || null,
    loading: false,
    errors: persistedLoginState.errors || null,
    statusSuccess: false, // Относится к статусу, возвращаемому API
};

// При инициализации редюсера состояние теперь восстанавливается из localStorage.
// а при логауте сробатівает єкшен CLEAR_AUTH_DATA
const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                errors: null,
                statusSuccess: false,
            };
        case FETCH_LOGIN_SUCCESS:
            const successState = {
                ...state,
                token: action.payload,
                loading: false,
                errors: null,
                statusSuccess: true,
            };
            localStorage.setItem('loginState', JSON.stringify(successState)); // Сохраняем состояние при успешном входе
            return successState;

        case FETCH_LOGIN_FAILURE:
            const failureState = {
                ...state,
                loading: false,
                errors: action.payload,
                statusSuccess: false,
            };
            localStorage.setItem('loginState', JSON.stringify(failureState)); // Сохраняем состояние при ошибке
            return failureState;

        case CLEAR_AUTH_DATA:
            localStorage.removeItem('loginState'); // Удаляем состояние при выходе
            return {
                ...state,
                token: null, // Очищаем токен
                errors: null, // Очищаем ошибки
                statusSuccess: false,
            };

        default:
            return state;
    }
};

export default loginReducer;
