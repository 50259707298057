// C:\Users\Admin\Desktop\SITES\htdocs/platform-client-app\react-platform/src/redux/actions/setting/getRegistrationFormSettingsActions.js
import axios from 'axios';
import config from '../../../config';

export const ACTION_GET_REGISTRATION_FORM_SETTINGS_REQUEST = 'ACTION_GET_REGISTRATION_FORM_SETTINGS_REQUEST';
export const ACTION_GET_REGISTRATION_FORM_SETTINGS_SUCCESS = 'ACTION_GET_REGISTRATION_FORM_SETTINGS_SUCCESS';
export const ACTION_GET_REGISTRATION_FORM_SETTINGS_FAILURE = 'ACTION_GET_REGISTRATION_FORM_SETTINGS_FAILURE';
export const ACTION_SET_REGISTRATION_FORM_SETTINGS_ID      = 'ACTION_SET_REGISTRATION_FORM_SETTINGS_ID';

export const actionGetRegistrationFormSettingsRequest = () => ({
  type: ACTION_GET_REGISTRATION_FORM_SETTINGS_REQUEST,
});

export const actionGetRegistrationFormSettingsSuccess = (data, messages) => ({
  type: ACTION_GET_REGISTRATION_FORM_SETTINGS_SUCCESS,
  payload: { data, messages },
});

export const actionGetRegistrationFormSettingsFailure = (error) => ({
  type: ACTION_GET_REGISTRATION_FORM_SETTINGS_FAILURE,
  payload: { error },
});

//Может понадобиться при переключении на разные формы в шаблоне
export const actionSetRegistrationFormSettingsId = (id) => ({
  type: ACTION_SET_REGISTRATION_FORM_SETTINGS_ID,
  payload: { id },
});

//For Express
export const fetchGetRegistrationFormSettings = (id) => async (dispatch) => {

  const idForm = id || '1';
  
  // Инициализируем запрос
  dispatch(actionGetRegistrationFormSettingsRequest());
  // dispatch(actionSetRegistrationFormSettingsId(idForm));

  try {
    console.log('Attempting to fetchGetRegistrationFormSettings:' + `${config.serverUrl}/setting/get-registration-form`, { idForm });

    const response = await axios.get(`${config.serverUrl}/setting/get-registration-form`, {
      params: {
        id: idForm
      },
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const data = response.data;

    console.log('fetchGetRegistrationFormSettings data (after ajax): ', data);
    if (response.status === 200 && data.success) {
      console.log('fetchGetRegistrationFormSettings data (response.status === 200 && data.success): ', data);
      dispatch(actionGetRegistrationFormSettingsSuccess(data.data, data.messages));
    } else {
      console.error('fetchGetRegistrationFormSettings error:', data.errors);
      dispatch(actionGetRegistrationFormSettingsFailure(data.errors, data.message || 'Error get registration form settings.'));
    }
  } catch (error) {
    if (error.response) {
      console.error(`Error: ${error.response.status}`, error.response.data);

      if (error.response.status === 404) {
        dispatch(actionGetRegistrationFormSettingsFailure(null, 'API endpoint not found.'));
      } else {
        dispatch(actionGetRegistrationFormSettingsFailure(error.response.data.error, error.response.data.message || 'Server error.'));
      }
    } else {
      console.error('fetchGetRegistrationFormSettings network error:', error.message);
      dispatch(actionGetRegistrationFormSettingsFailure(null, 'Network error: Unable to connect to the server.'));
    }
  }
};

