// getPersonalInfoReducer.js
import {
  ACTION_GET_PERSONAL_INFO_REQUEST,
  ACTION_GET_PERSONAL_INFO_SUCCESS,
  ACTION_GET_PERSONAL_INFO_FAILURE
} from '../../actions/player/getPersonalInfoActions';

const initialState = {
  data: null,
  loading: false,
  errors: null,
  messages: null
};

const getPersonalInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_GET_PERSONAL_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        errors: null,
        messages: null 
      };

    case ACTION_GET_PERSONAL_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        messages: action.payload.messages,
        errors: null
      };

    case ACTION_GET_PERSONAL_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
        messages: action.payload.messages,
        data: null 
      };

    default:
      return state;
  }
};

export default getPersonalInfoReducer;
