import React, {useEffect} from 'react'
import config from '../../config'

export type GameType = {
  image_path_album: string 
  name: string 
  group_name: string 
} 

type FavoriteGameItem = {
  game: GameType 
} 

type FavoriteGameListProps = {
  gameList: FavoriteGameItem[] 
  onStarClick: (game: GameType) => void 
  onDemoClick: (game: GameType) => void 
  onPlayClick: (game: GameType) => void 
} 

const FavoriteGameList: React.FC<FavoriteGameListProps> = ({gameList, onStarClick, onDemoClick, onPlayClick}) => {
  useEffect(() => {
    console.log('FavoriteGameList', gameList)
  }, [gameList])

  if (!gameList || gameList.length === 0) {
    return <p>No games available...</p>
  }

  return (
    <div className="row wrap-games">
      {gameList.map((item, i) => (
        <div key={i} className="col-3">
          <div className="item-game">
            <div className="item-game_pic">
              <img src={`${config.fileUrls.game}${item.game.image_path_album}`} alt={item.game.name}/>
            </div>
            <div className="item-game_info p-2 d-flex flex-column">
              <div className="d-flex justify-content-end">
                <i className="fa fa-star-o" onClick={() => onStarClick(item.game)}></i>
              </div>
              <div>
                <h5>{item.game.name}</h5>
              </div>
              <div className="font-weight-bold">
                <span style={{width: '1rem'}}>{item.game.group_name}</span>
              </div>
              <div className="d-flex justify-content-between mt-auto">
                <a href="#" className="btn btn-primary" onClick={() => onDemoClick(item.game)}>Demo</a>
                <a href="#" className="btn btn-success" onClick={() => onPlayClick(item.game)}>Play</a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default FavoriteGameList
