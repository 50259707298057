import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import GameList from '../elems/GameList'
// import withAuth from '../hoc/withAuth';  // Import the HOC
import { selectIsAuthenticated, selectToken } from '../../redux/selectors/site/loginSelectors'
import { fetchGameListByGroup } from "src/redux/actions/game/gameListActions"
import { ThunkDispatch } from "redux-thunk"
import { Action } from "redux"
import { selectGameList, selectGameListError, selectGameListLoading, selectGameListMessages } from "src/redux/selectors/game/gameListSelectors"
import { GameItem } from "../../common/types"

const GameGroup: React.FC = () => {
  let { gameGroupSlug } = useParams<{ gameGroupSlug: string }>()
  const dispatch: ThunkDispatch<any, any, Action> = useDispatch()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const token = useSelector(selectToken)

  const gameList: GameItem[] = useSelector(selectGameList);
  const gameListLoading = useSelector(selectGameListLoading);
  const gameListError = useSelector(selectGameListError);
  const gameListMessages = useSelector(selectGameListMessages);

  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated && token && gameGroupSlug) {
      dispatch(fetchGameListByGroup(token, gameGroupSlug));  // Вызов экшена для получения данных
    }
  }, [dispatch, isAuthenticated, token, gameGroupSlug]);

  useEffect(() => {
    console.log('component GameGroup selectGameList:', gameList, gameList && gameList.length > 0 ? gameList.length : gameList);

  }, [gameList]);

  useEffect(() => {
    console.log('component GameGroup selectGameList:', gameList, gameList && gameList.length > 0 ? gameList.length : gameList);

  }, [gameList]);

  const handleStarClick = (game: GameItem) => {
    console.log(`Star clicked for game: ${game.name}`)
  }

  const handlePlayClick = (game: GameItem) => {
    console.log(`Play clicked for game: ${game.slug}`)
    navigate(`/games/${game.slug}`)
  }

  const handleDemoClick = (game: GameItem) => {
    // console.log(`Demo clicked for game: ${game.name}`);
    handlePlayClick(game)
  }

  return (
    <>
        <div>
            <h1>Game Category: {gameGroupSlug}</h1>
            {/* <GameGroups />
    <Providers /> */}
        </div>

        {gameListLoading ? (
            <p>Loading...</p>
        ) : gameListMessages ? (
            <p>Error: {gameListMessages}</p>
        ) :
        <>
        {gameList?.length > 0 && <p>Games in group: {gameList.length}</p>}

             <GameList
                gameList={gameList}
                onStarClick={handleStarClick}
                onDemoClick={handleDemoClick}
                onPlayClick={handlePlayClick}
            />
        </>    
        }
    </>
);

}

// export default withAuth(GameGroup);
export default GameGroup
