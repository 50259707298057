import React from 'react'
import styles from './QuestionBlock.module.scss'
import mail_01 from 'src/assets/images/icons/mail_01.svg'
import telegram from 'src/assets/images/icons/telegram.svg'

export const QuestionBlock = () => {
  return (
    <div className={styles.questionBlock}>
      <div className={styles.textContainer}>
        <div className={styles.title}>
          Any questions?
        </div>
        <div className={styles.subtitle}>
          Contact our support
        </div>
      </div>
      <div className={styles.imageContainer}>
        <div className={styles.imageElement}>
          <img src={mail_01} alt="mail_01"/>
        </div>
        <div className={styles.imageElement}><img src={telegram} alt="telegram"/></div>
      </div>

    </div>
  )
}