// getPersonalInfoSelectors.js

export const selectGetPersonalInfo             = (state) => state.getPersonalInfoReducer.data;
export const selectGetPersonalInfoMessages     = (state) => state.getPersonalInfoReducer.messages;
export const selectGetPersonalInfoLoading      = (state) => state.getPersonalInfoReducer.loading;

// export const selectGetPersonalInfoError     = (state) => state.getPersonalInfoReducer.error;
export const selectGetPersonalInfoError = (state) => {
    const error = state.getPersonalInfoReducer.error;
  
    if (Array.isArray(error)) {
      return error.join('\n');
    }
  
    if (typeof error === 'object' && error !== null) {
      return Object.entries(error)
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n');
    }
  
    return error;
  };
  