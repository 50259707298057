// CheckboxInput.js
import React from 'react';

const CheckboxInput = ({ id, name, checked, onChange, required, hint, placeholder, error }) => (
  <div className="form-check mb-3">
    <label htmlFor={id} className="form-label">
      <strong>{placeholder} {required && <span style={{ color: 'red' }}>*</span>}</strong>
    </label>
    <br/>
    <input
      className={`form-check-input ${error ? 'is-invalid' : ''}`}
      type="checkbox"
      id={id}
      name={name}
      checked={checked}
      onChange={onChange}
      required={required}
      data-error-required={`${placeholder} is required`}
    />
    {hint}
    
    {error && <div className="invalid-feedback">{error}</div>}
  </div>
);

export default CheckboxInput;
