import React from 'react'
import GameWidget from "../elems/GameWidget/GameWidget"
import {ContentBlock} from "../elems/ContentBlock/ContentBlock"
import {Winners} from "../elems/Winners/Winners"
import GameGroups from "../elems/GameGroups/GameGroups"
import {CarouselBlock} from "../elems/CarouselBlock/CarouselBlock"

export const PublicBlocks = () => {
  return (
    <>
      <GameGroups />
      {/*<Providers/>*/}
      <Winners />
      <GameWidget />
      <ContentBlock blockId={1} />
      <CarouselBlock />
    </>
  )
}