import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {TransactionsServerResponse} from '../../api/types'
import config from '../../config'

export const fetchTransactions = createAsyncThunk<TransactionsServerResponse, FetchParams>(
  'transactions/fetch',
  async ({token, direction, page, pageSize}, {dispatch}) => {
    const ws = new WebSocket(config.serverWebsocketUrl)
    dispatch(setTransactionsWebSocket({ws, direction}))

    return new Promise<TransactionsServerResponse>((resolve, reject) => {
      ws.onopen = () => {
        ws.send(JSON.stringify({
          type: 'getTransactionHistory',
          token,
          direction,
          page,
          pageSize
        }))
      }

      ws.onmessage = (event) => {
        const parsedData: TransactionsServerResponse = JSON.parse(event.data)
        console.log('Received data:', parsedData)

        if (parsedData.type === 'getTransactionHistory') {
          resolve(parsedData) // Return the data
        } else {
          reject(new Error(`Unexpected data type: ${parsedData.type}`))
        }
      }

      ws.onerror = (error) => {
        reject(new Error('WebSocket error: ' + error))
      }
    })
  }
)

const initialState: TransactionsState = {
  'deposit': {
    data: null,
    loading: false,
    error: null,
    ws: null,
  },
  'withdrawal': {
    data: null,
    loading: false,
    error: null,
    ws: null,
  }
}

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setTransactionsWebSocket(state, action: { payload: { ws: WebSocket; direction: Direction } }) {
      const {ws, direction} = action.payload
      state[direction].ws = ws
    },
    clearTransactionsWebSocket(state, action: { payload: Direction }) {
      const direction = action.payload
      const transactionState = state[direction]

      if (transactionState.ws) {
        transactionState.ws.close() // closing connection
        transactionState.ws = null
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state, action) => {
        const direction = action.meta.arg.direction

        if (direction) {
          state[direction].loading = true
          state[direction].error = null
        }
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        const direction = action.meta.arg.direction

        if (direction) {
          state[direction].loading = false
          state[direction].data = action.payload
        }
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        const direction = action.meta.arg.direction

        if (direction) {
          state[direction].loading = false
          state[direction].error = action.error.message || 'Error'
        }
      })
  },
  selectors: {
    depositsSelector: state => state['deposit'],
    withdrawalsSelector: state => state['withdrawal']
  }
})

export const {setTransactionsWebSocket, clearTransactionsWebSocket} = transactionsSlice.actions

export const transactionsReducer = transactionsSlice.reducer
export const {depositsSelector, withdrawalsSelector} = transactionsSlice.selectors

type Transactions = {
  data: TransactionsServerResponse | null
  loading: boolean
  error: string | null
  ws: WebSocket | null
}


export type TransactionsState = {
  'deposit': Transactions
  'withdrawal': Transactions
}

type Direction = 'deposit' | 'withdrawal'

type FetchParams = {
  token: string
  direction: Direction
  page: number
  pageSize: number
}
