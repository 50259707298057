import React, { useState, useEffect, useRef, FormEvent, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch } from "../../../hooks"
import { fetchLogin } from 'src/redux/actions/site/loginActions';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../../../config';
import {
    selectIsAuthenticated,
    selectToken,
    selectLoginErrors,
    selectIsLoadingLogin,
    selectHasTokenInLocalStorage,
    selectLoginErrorsArray
} from '../../../redux/selectors/site/loginSelectors';
import PasswordInput from './formComponents/PasswordInput';
import TextInput from './formComponents/TextInput';
import styles from "./Auth.module.scss"

// Определение интерфейсов для состояний
interface FormState {
    email?: string;
    password?: string;
}

interface ClientErrors {
    required: { [key: string]: string };
}

interface LoginProps {
    handleButtonActiveTabClick: (tab: 'login' | 'signup') => void;
}

const Login: React.FC<LoginProps> = ({ handleButtonActiveTabClick }) => {
    const dispatch = useAppDispatch();
    // Навигация
    const navigate = useNavigate();
    const location = useLocation();

    const isAuthenticated: boolean = useSelector(selectIsAuthenticated);
    const storedToken: string | null = useSelector(selectToken);
    const loginErrors: any = useSelector(selectLoginErrors);
    const loginErrorsArray: string[] | null = useSelector(selectLoginErrorsArray);
    const isLoadingLogin: boolean = useSelector(selectIsLoadingLogin);
    const hasTokenInLocalStorage: boolean = useSelector(selectHasTokenInLocalStorage);

    const [ip, setIp] = useState<string>('');
    const [clientErrors, setClientErrors] = useState<ClientErrors>({ required: {} });
    const [emailFieldErrors, setEmailFieldErrors] = useState<string[] | null>(null);
    const [passwordFieldErrors, setPasswordFieldErrors] = useState<string[] | null>(null);
    const [formState, setFormState] = useState<FormState>({});
    const [initialState, setInitialState] = useState<FormState>({});
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        const initialFormValues: FormState = {
            email: '',
            password: ''
        };

        setFormState(initialFormValues);
        setInitialState(initialFormValues);
    }, []);

    useEffect(() => {
        const fetchIp = async () => {
            try {
                const response = await axios.get<{ ip: string }>('https://api.ipify.org?format=json');
                setIp(response.data.ip);
            } catch (error) {
                console.error('Error fetching IP:', error);
            }
        };

        fetchIp();
    }, []);

    useEffect(() => {
        if (loginErrors) {
            if (loginErrors.email) {
                setEmailFieldErrors(loginErrors.email);
            } else {
                setEmailFieldErrors(null);
            }

            if (loginErrors.password) {
                setPasswordFieldErrors(loginErrors.password);
            } else {
                setPasswordFieldErrors(null);
            }
        }

    }, [loginErrors]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const email = formState.email;
        const password = formState.password;

        if (clientValidation() && email && password) {
            dispatch(fetchLogin(email, password, ip, navigate));
        } else {
            console.log('Validation failed');
        }
    };

    const clientValidation = (): boolean => {
        const formElements = formRef.current?.elements;
        const newErrors: ClientErrors = { required: {} };
        let formIsValid = true;

        if (formElements) {
            Array.from(formElements).forEach(field => {
                const input = field as HTMLInputElement;
                if (input.required && !input.value.trim()) {
                    formIsValid = false;
                    const errorMessage = input.getAttribute('data-error-required') || 'This field is required.';
                    newErrors.required[input.name] = errorMessage;
                }
            });
        }

        setClientErrors(newErrors);
        return formIsValid;
    };

    const handleButtonLoginAccauntClick = () => {
        clientValidation();
    };

    const handleInputChange = (code: string, e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        const updatedFormState = {
            ...formState,
            [code]: value,
        };
        setFormState(updatedFormState);
        setIsEditing(JSON.stringify(updatedFormState) !== JSON.stringify(initialState));

        setClientErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };

            if (value.trim() !== '') {
                delete updatedErrors.required[code];
            } else {
                const errorMessage = e.target.getAttribute('data-error-required') || 'This field is required.';
                updatedErrors.required[code] = errorMessage;
            }

            return updatedErrors;
        });
    };

    // Кнопка закрытия на первом экране
    const handleClickCloseButtonInFirstScreen = () => {
        if (location.key !== 'default') {
            navigate(-1); // Переход на предыдущую страницу, если история есть
        } else {
            navigate('/'); // Переход на главную, если истории нет
        }
    };

    // Render View
    const renderView = (): JSX.Element => (
        <>
            <button onClick={handleClickCloseButtonInFirstScreen} className={styles.btnСlose}>
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                    <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                </svg>
            </button>{/* Кнопка закрытия */}


            <div className="row">
                {/* Левый блок - 40% ширины */}
                <div className="col-12 col-md-5" style={{ backgroundImage: `url('https://betm.co/wp-content/uploads/2024/06/casinos_in_kathmandu_nepal.png')`, backgroundSize: 'cover', borderRadius: '0.5rem' }}>
                    <div className={styles.leftContent}>
                        <h2 className="text-white">Welcome!</h2>
                        <p className="text-white">
                            Quick registration and login. Join us!
                        </p>
                    </div>
                </div>

                {/* Правый блок - 60% ширины */}
                <div className="col-12 col-md-7">
                    <div className={styles.rightContent}>

                        {/* Блок с кнопками Логин и Регистрация */}
                        <div className="d-flex mb-3">
                            <button onClick={() => handleButtonActiveTabClick('login')} className="btn btn-primary w-50 rounded-3 me-2">Login</button>
                            <button onClick={() => handleButtonActiveTabClick('signup')} className="btn btn-outline-primary w-50 rounded-3">Register</button>
                        </div>

                        {/* Блок с формой */}
                        <form onSubmit={handleSubmit} ref={formRef}>

                            <div className="row">
                                <div className="col-12">
                                    <TextInput
                                        id="email"
                                        name="email"
                                        value={formState.email || ''}
                                        onChange={(e) => handleInputChange('email', e)}
                                        required
                                        placeholder="Email"
                                        error={clientErrors.required.email || emailFieldErrors}
                                    />
                                </div>

                                <div className="col-12">
                                    <PasswordInput
                                        id="password"
                                        name="password"
                                        value={formState.password || ''}
                                        onChange={(e) => handleInputChange('password', e)}
                                        required
                                        placeholder="Password"
                                        error={clientErrors.required.password || passwordFieldErrors}
                                    />
                                </div>
                            </div>



                            <div className="row">
                                {/* Блок с кнопкой и ссылкой */}
                                <div className="mt-4">
                                    <button type="submit" className="btn btn-primary w-100 mb-2" onClick={handleButtonLoginAccauntClick}>
                                        {isLoadingLogin ? 'loading...' : 'Login'}
                                    </button>
                                </div>


                                {loginErrorsArray && (!emailFieldErrors && !passwordFieldErrors) && (
                                    <div className="alert alert-danger" role="alert">
                                        {loginErrorsArray.map((error, index) => (
                                            <p key={index}>{error}</p>
                                        ))}
                                    </div>
                                )}
                            </div>

                        </form>

                        {/* Блок с соцсетями */}
                        <div className="mt-4 text-center">
                            <h6>--- Sign up with social media ---</h6>
                            <div className="d-flex justify-content-between mt-3">
                                <button className="btn btn-outline-secondary flex-fill me-2">Google</button>
                                <button className="btn btn-outline-secondary flex-fill me-2">Facebook</button>
                                <button className="btn btn-outline-secondary flex-fill">Apple</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* тут будет внутренний контент */}
        </>
    );

    return (
        renderView()
    );
};

export default Login;
