// listBalances.js
import axios from 'axios';
import config from '../../../config';

export const FETCH_LISTBALANCES_REQUEST = 'FETCH_LISTBALANCES_REQUEST';
export const FETCH_LISTBALANCES_SUCCESS = 'FETCH_LISTBALANCES_SUCCESS';
export const FETCH_LISTBALANCES_FAILURE = 'FETCH_LISTBALANCES_FAILURE';

// Экшены для получения данных
export const fetchListBalancesRequest = () => ({
  type: FETCH_LISTBALANCES_REQUEST,
});

export const fetchListBalancesSuccess = (gameList) => ({
  type: FETCH_LISTBALANCES_SUCCESS,
  payload: gameList,
});

export const fetchListBalancesFailure = (error) => ({
  type: FETCH_LISTBALANCES_FAILURE,
  payload: error,
});

export const fetchListBalances = (token) => async (dispatch) => {
  dispatch(fetchListBalancesRequest());
  try {
    const response = await axios.get(`${config.apiBaseUrl}/player/list-balances`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
    
    dispatch(fetchListBalancesSuccess(response.data));
  } catch (error) {
    dispatch(fetchListBalancesFailure(error.message));
  }
};

// Экшены для WebSocket
// export const connectWebSocket = (token, language = null) => {
//   return (dispatch) => {
//     const ws = new WebSocket( config.serverWebsocketUrl );

//     ws.onopen = () => {
//       console.log('Connected to WebSocket server getListBalances');
//       ws.send(JSON.stringify({ type: 'getListBalances', token }));
//     };

//     ws.onmessage = (event) => {
//       console.log('WebSocket message received getListBalances:', event.data);
//       try {
//         const data = JSON.parse(event.data);
//         console.log(data);
//         console.log(data.listFavoriteGames.data);
//         if (data.type === 'getListBalances') {
//           console.log('ListBalances received:', data.listFavoriteGames.data);
//           dispatch(fetchListBalancesSuccess(data.listFavoriteGames.data));
//         } else if (data.type === 'error') {
//           dispatch(fetchListBalancesFailure(data.message));
//         }
//       } catch (error) {
//         console.error('Error parsing WebSocket message:', error.message);
//       }
//     };

//     ws.onclose = () => {
//       console.log('Disconnected from WebSocket server');
//     };

//     return () => {
//       ws.close();
//     };
//   };
// };

export const connectListBalancesWebSocket = (token, language = null) => {
  return (dispatch) => {
    const ws = new WebSocket(config.serverWebsocketUrl);

    ws.onopen = () => {
      console.log('Connected to WebSocket server getListBalances');
      ws.send(JSON.stringify({ type: 'getListBalances', token }));
    };

    ws.onmessage = (event) => {
      console.log('WebSocket message received getListBalances:', event.data);
      try {
        const data = JSON.parse(event.data);
        console.log('Parsed event.data:', data.listBalances.data);

        if (data.type === 'getListBalances') {
          const listBalances = data.listBalances.data;
          
          // Преобразуем данные в массив
          // Добавил тля итерации
          const listBalancesArray = Array.isArray(listBalances) ? listBalances : [listBalances];

          console.log('ListBalances received (array):', listBalancesArray);
          dispatch(fetchListBalancesSuccess(listBalancesArray));
        } else if (data.type === 'error') {
          dispatch(fetchListBalancesFailure(data.message));
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error.message);
        dispatch(fetchListBalancesFailure(error.message));
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      ws.close();
    };
  };
};
