// logoutActions.js
import axios from 'axios';
import config from '../../../config';

export const FETCH_LOGOUT_REQUEST = 'FETCH_LOGOUT_REQUEST';
export const FETCH_LOGOUT_SUCCESS = 'FETCH_LOGOUT_SUCCESS';
export const FETCH_LOGOUT_FAILURE = 'FETCH_LOGOUT_FAILURE';
export const CLEAR_AUTH_DATA      = 'CLEAR_AUTH_DATA';

export const fetchLogoutRequest = () => ({
  type: FETCH_LOGOUT_REQUEST,
});

export const fetchLogoutSuccess = () => ({
  type: FETCH_LOGOUT_SUCCESS,
});

export const fetchLogoutFailure = (error) => ({
  type: FETCH_LOGOUT_FAILURE,
  payload: error,
});

// очищает и токен что хранится в loginReducer
export const clearAuthData = () => {
  // This action can be dispatched when logout is successful to clear the token
  // localStorage.removeItem('token'); // закоментировал. Удаляю в логин редюсере локалстор стейта логина
  return {
    type: CLEAR_AUTH_DATA,
  };
};

// Обновил код, чтобы лучше обробатывать ошибки от апи
// export const fetchLogout = (token) => async (dispatch) => {
//   dispatch(fetchLogoutRequest());
//   try {
//     console.log('Attempting to logout');

//     const response = await axios.post(`${config.serverUrl}/auth/logout`, {
//       token
//     }, {
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     });

//     console.log('Logout response:', response);

//     if (response.status === 200 && response.data.success) {
//       // Dispatch the success and clear auth data actions
//       dispatch(fetchLogoutSuccess());
//       dispatch(clearAuthData());
//       console.log('Successfully logged out and token removed from localStorage');
//     } else {
//       console.error('Logout failed:', response.data.errors);
//       dispatch(fetchLogoutFailure(response.data.errors || 'Logout failed'));
//     }
//   } catch (error) {
//     if (error.response) {
//       console.error(`Logout error: ${error.response.status}`, error.response.data);
//       dispatch(fetchLogoutFailure(error.response.data.message || 'Error during logout'));
//     } else {
//       console.error('Logout network error: ', `url: ${config.serverUrl}:${config.serverApiPort}/auth/logout`, error.message, error);
//       dispatch(fetchLogoutFailure('Network error: Unable to connect to the server.'));
//     }
//   }
// };

//  Временный экшен, для теста, пока на апи сервере допилить логаут.
export const fetchLogout = (token) => async (dispatch) => {
  dispatch(fetchLogoutRequest());
  dispatch(fetchLogoutSuccess());
  dispatch(clearAuthData());
  console.log('Successfully logged out and token removed from localStorage');
};