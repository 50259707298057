// reducers/viewReducer.js
import {
  ACTION_SET_VIEW,
  
} from '../../actions/playerDeposit/viewActions';

const initialState = {
  views: ['CurrencyAndPayment', 'DepositDetails'],
  currentView: 'CurrencyAndPayment',
  previousView: null,
};

const viewPlayerDepositReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_SET_VIEW:
      return {
        ...state,
        currentView: action.payload.view,
        previousView: action.payload.previousView,
      };

    default:
      return state;
  }
};

export default viewPlayerDepositReducer;
