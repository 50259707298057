import React from 'react'
import {Link} from 'react-router-dom'
import {Button} from '@mui/material'

type Props = {
  to: string;
  children: string;
  className?: string;
  variant?: 'primary' | 'glass';
  height?: string;
  paddingVariant?: '12px 16px' | '14px 18px';
};

export const ButtonStyled = ({to, children, className, variant = 'primary', height = '40px', paddingVariant = '12px 16px'}: Props): JSX.Element => {
  const sxStyles = {
    textTransform: 'none',
    height: height,
    borderRadius: 'var(--radius-12)',
    backgroundColor: variant === 'primary' ? 'var(--background-button-primary)' : 'var(--background-button-glass)',
    fontFamily: 'var(--font-family), sans-serif',
    fontSize: '14px',
    fontWeight: '600',
    padding: paddingVariant,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: variant === 'primary' ? 'var(--background-button-primary-hover)' : 'var(--background-button-glass-hover)',
    },
    '&:active': {
      backgroundColor: variant === 'primary' ? 'var(--background-button-primary-pressed-correct)' : 'var(--background-button-glass-pressed-correct)',
    }
  }

  return (
    <Button
      variant="contained"
      className={className}
      component={Link}
      to={to}
      sx={sxStyles}
      disableRipple
    >
      {children}
    </Button>
  )
}

