import React from 'react' 
import { Box, TableCell, TableSortLabel, TextField, FormControl, Select, MenuItem, SelectChangeEvent } from "@mui/material" 
import { visuallyHidden } from '@mui/utils' 

type SortableFilterableTableCellProps = {
  label: string 
  orderBy: string 
  currentOrderBy: string 
  order: 'asc' | 'desc' 
  filterValue: string 
  onSort: () => void 
  onFilterChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => void 
  placeholder: string 
  selectOptions?: Array<{ value: string,  label: string }>
}

export const SortableFilterableTableCell: React.FC<SortableFilterableTableCellProps> = ({
                                                                                          label,
                                                                                          orderBy,
                                                                                          currentOrderBy,
                                                                                          order,
                                                                                          filterValue,
                                                                                          onSort,
                                                                                          onFilterChange,
                                                                                          placeholder,
                                                                                          selectOptions
                                                                                        }) => {
  return (
    <TableCell>
      <TableSortLabel
        active={currentOrderBy === orderBy}
        direction={currentOrderBy === orderBy ? order : 'asc'}
        onClick={onSort}
      >
        {label}
        {currentOrderBy === orderBy ? (
          <Box component="span" sx={visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        ) : null}
      </TableSortLabel>
      {selectOptions ? (
        <FormControl fullWidth>
          <Select
            value={filterValue}
            onChange={(e) => onFilterChange(e as SelectChangeEvent<string>)}
            displayEmpty
            variant="outlined"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {selectOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <TextField
          variant="outlined"
          value={filterValue}
          onChange={(e) => onFilterChange(e)}
          placeholder={placeholder}
          fullWidth
        />
      )}
    </TableCell>
  ) 
} 
