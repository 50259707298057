// // src/redux/selectors/site/loginSelectors.js

// Функция для получения токена из localStorage
const getToken = () => {
    const loginState = window.localStorage.getItem('loginState');
    try {
        return loginState ? JSON.parse(loginState).token : null;
    } catch (error) {
        console.error("Error parsing loginState from localStorage:", error);
        return null;
    }
};

// Проверка, аутентифицирован ли пользователь (по токену в state и localStorage)
export const selectIsAuthenticated = (state) => {
    const localStorageToken = getToken();
    const stateToken = state.loginReducer.token;
    
    // Проверяем наличие токена и в Redux, и в localStorage (если это требуется)
    return stateToken !== null && localStorageToken !== null;
};

// Получение токена из Redux state
export const selectToken = (state) => state.loginReducer.token;

// Ошибки логина из Redux state
export const selectLoginErrors = (state) => state.loginReducer.errors;

// Проверка, идет ли процесс логина
export const selectIsLoadingLogin = (state) => state.loginReducer.loading;

// Проверка, есть ли токен в localStorage
export const selectHasTokenInLocalStorage = () => {
    return getToken() !== null;
};

export const selectLoginErrorsArray = (state) => {
    const error = state.loginReducer.errors;
    let errorsArray = [];
  
    if (Array.isArray(error)) {
      errorsArray = error.map(err => `${err}`);
    } else if (typeof error === 'object' && error !== null) {
      errorsArray = Object.entries(error)
        .flatMap(([key, value]) => Array.isArray(value) ? value.map(val => `${key}: ${val}`) : `${key}: ${value}`);
    } else if (error) {
      errorsArray = [error];
    }
  
    return errorsArray.length > 0 ? errorsArray : null;
}