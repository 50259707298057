// Deposits.tsx
import React from 'react'
import {depositsSelector} from 'src/redux/slices/transactionsSlice'
import {DepositWithdrawalHandler} from "./common/DepositWithdrawalHandler/DepositWithdrawalHandler"

const Deposits = () => {
  return (
    <DepositWithdrawalHandler
      direction="deposit"
      selector={depositsSelector}
    />
  );
};

export default Deposits;
