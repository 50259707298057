import axios from 'axios';
import config from '../../../config.js';
import { Dispatch } from 'redux';

// Определяем типы для действий
export const ACTION_GET_PLAYER_BALANCES_REQUEST = 'ACTION_GET_PLAYER_BALANCES_REQUEST';
export const ACTION_GET_PLAYER_BALANCES_SUCCESS = 'ACTION_GET_PLAYER_BALANCES_SUCCESS';
export const ACTION_GET_PLAYER_BALANCES_FAILURE = 'ACTION_GET_PLAYER_BALANCES_FAILURE';

// Интерфейсы для успешного и неудачного ответа
interface SuccessResponse {
  success: true;
  data: any; // Уточните тип данных, если возможно
  messages: string[];
}

interface FailureResponse {
  success: false;
  errors: string;
  message?: string;
}

// Тип объединения для ответа
type ApiResponse = SuccessResponse | FailureResponse;

// Интерфейсы для действий
interface ActionGetPlayerBalancesRequest {
  type: typeof ACTION_GET_PLAYER_BALANCES_REQUEST;
}

interface ActionGetPlayerBalancesSuccess {
  type: typeof ACTION_GET_PLAYER_BALANCES_SUCCESS;
  payload: {
    data: any; // Уточните тип данных, если возможно
    messages: string[];
  };
}

interface ActionGetPlayerBalancesFailure {
  type: typeof ACTION_GET_PLAYER_BALANCES_FAILURE;
  payload: {
    error: string;
    message?: string;
  };
}

// Создаем тип для всех действий
type PlayerBalancesActionTypes =
  | ActionGetPlayerBalancesRequest
  | ActionGetPlayerBalancesSuccess
  | ActionGetPlayerBalancesFailure;

// Действия
export const actionGetPlayerBalancesRequest = (): ActionGetPlayerBalancesRequest => ({
  type: ACTION_GET_PLAYER_BALANCES_REQUEST,
});

export const actionGetPlayerBalancesSuccess = (data: any, messages: string[]): ActionGetPlayerBalancesSuccess => ({
  type: ACTION_GET_PLAYER_BALANCES_SUCCESS,
  payload: { data, messages },
});

export const actionGetPlayerBalancesFailure = (error: string, message?: string): ActionGetPlayerBalancesFailure => ({
  type: ACTION_GET_PLAYER_BALANCES_FAILURE,
  payload: { error, message },
});

// Функция проверки типа
const isFailureResponse = (response: ApiResponse): response is FailureResponse => !response.success;

// Функция для Express
export const fetchGetPlayerBalances = (token: string) => async (dispatch: Dispatch<PlayerBalancesActionTypes>) => {

  dispatch(actionGetPlayerBalancesRequest());
  try {
    console.log('Attempting to fetchGetPlayerBalances:' + `${config.serverUrl}/setting/get-registration-form`, { token });

    const response = await axios.get<ApiResponse>(`${config.serverUrl}/player/list-balances`, {
      params: {
        token,
      },
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });

    const data = response.data;

    console.log('fetchGetPlayerBalances data (after ajax): ', data);
    if (response.status === 200 && data.success) {
      console.log('fetchGetPlayerBalances data (response.status === 200 && data.success): ', data);
      dispatch(actionGetPlayerBalancesSuccess(data.data, data.messages));
    } else {
      if (isFailureResponse(data)) {
        console.error('fetchGetPlayerBalances error:', data.errors);
        dispatch(actionGetPlayerBalancesFailure(data.errors, data.message || 'Error get player balances.'));
      } else {
        dispatch(actionGetPlayerBalancesFailure('Unknown error', 'An unknown error occurred.'));
      }
    }
  } catch (error: any) {
    if (error.response) {
      console.error(`Error: ${error.response.status}`, error.response.data);

      if (error.response.status === 404) {
        dispatch(actionGetPlayerBalancesFailure('API endpoint not found.'));
      } else {
        dispatch(actionGetPlayerBalancesFailure(error.response.data.error, error.response.data.message || 'Server error.'));
      }
    } else {
      console.error('fetchGetPlayerBalances network error:', error.message);
      dispatch(actionGetPlayerBalancesFailure('Network error: Unable to connect to the server.'));
    }
  }
};
