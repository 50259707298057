// listFavoriteGamesReducer.js
import { FETCH_LISTFAVORITEGAMES_REQUEST, FETCH_LISTFAVORITEGAMES_SUCCESS, FETCH_LISTFAVORITEGAMES_FAILURE } from '../../actions/player/listFavoriteGamesActions';

const initialState = {
  result: [],
  resultMessages: null,
  loading: false,
  error: null,
  errorMessage: null,
};

const listFavoriteGamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LISTFAVORITEGAMES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        errorMessage: null
      };
    case FETCH_LISTFAVORITEGAMES_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        resultMessages: action.resultMessages,
      };
    case FETCH_LISTFAVORITEGAMES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        errorMessage: action.errorMessage,
      };

    default:
      return state;
  }
};

export default listFavoriteGamesReducer;
