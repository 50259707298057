import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchUpdatePersonalInfo } from '../../redux/actions/player/updatePersonalInfoActions';
import { fetchGetPersonalInfo } from '../../redux/actions/player/getPersonalInfoActions';

import {
  selectUpdatePersonalInfo,
  selectUpdatePersonalInfoMessages,
  selectUpdatePersonalInfoLoading,
  selectUpdatePersonalInfoError,
  selectUpdatePersonalInfoErrorMessage
} from '../../redux/selectors/player/updatePersonalInfoSelectors';

import {
  selectGetPersonalInfo,
  selectGetPersonalInfoMessages,
  selectGetPersonalInfoLoading,
  selectGetPersonalInfoError
} from '../../redux/selectors/player/getPersonalInfoSelectors';


import { selectIsAuthenticated, selectToken } from '../../redux/selectors/site/loginSelectors';


const PersonalInformation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const token = useSelector(selectToken);

  const getPersonalInfo = useSelector(selectGetPersonalInfo);
  const getPersonalInfoMessages = useSelector(selectGetPersonalInfoMessages);
  const getPersonalInfoLoading = useSelector(selectGetPersonalInfoLoading);
  const getPersonalInfoError = useSelector(selectGetPersonalInfoError);

  const updatePersonalInfo = useSelector(selectUpdatePersonalInfo);
  const updatePersonalInfoMessages = useSelector(selectUpdatePersonalInfoMessages);
  const updatePersonalInfoLoading = useSelector(selectUpdatePersonalInfoLoading);
  const updatePersonalInfoError = useSelector(selectUpdatePersonalInfoError);
  const updatePersonalInfoErrorMessage = useSelector(selectUpdatePersonalInfoErrorMessage);

  const [formState, setFormState] = useState({});
  const [initialState, setInitialState] = useState({});  // Начальные значения из API
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (isAuthenticated && token) {
      dispatch(fetchGetPersonalInfo(token));
    }
  }, [dispatch, isAuthenticated, token]);

  useEffect(() => {
    console.log('getPersonalInfo', getPersonalInfo);
  }, [getPersonalInfo]);

  useEffect(() => {
    const initialFormValues = {};
    if (getPersonalInfoError || getPersonalInfo === null) {
      return;
    }
    getPersonalInfo.forEach(item => {
      initialFormValues[item.code] = item.value;
    });
    setFormState(initialFormValues);
    setInitialState(initialFormValues);
  }, [getPersonalInfo]);

  const handleInputChange = (code, e) => {
    let value = e.target.value;
    console.log(code, value);
    const updatedFormState = {
      ...formState,
      [code]: value,  // Используем `code` как ключ, чтобы обновить соответствующее поле
    };

    setFormState(updatedFormState);

    // Проверяем, изменились ли данные по сравнению с начальным состоянием
    setIsEditing(JSON.stringify(updatedFormState) !== JSON.stringify(initialState));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitted data:', formState);
    // Здесь можно вызвать API для отправки данных
  };

  const renderInputField = (item) => {
    const labelText = item.approve === 'request' ? `${item.name} (update after approve admin)` : item.name;
    
    if (item.code === 'gender') {
      return (
        <div className="mb-3" key={item.id}>
          <label htmlFor={item.code} className="form-label"><strong>{labelText}</strong></label>
          <select
            id={item.code}
            className="form-control"
            name={item.code}
            value={formState[item.code] || ''}
            onChange={(e) => handleInputChange(item.code, e)}
            disabled={item.flag_editable === 'NO'}
          >
            <option value="M">Male</option>
            <option value="F">Female</option>
          </select>
        </div>
      );
    }

    // Для всех остальных полей рендерим текстовое поле
    return (
      <div className="mb-3" key={item.id}>
        <label htmlFor={item.code} className="form-label"><strong>{labelText}</strong></label>
        <input
          type="text"
          id={item.code}
          className="form-control"
          name={item.code}
          value={formState[item.code] || ''}
          onChange={(e) => handleInputChange(item.code, e)}
          disabled={item.flag_editable === 'NO'}
        />
      </div>
    );
  };

  return (
    <div className="mt-2 mb-2">
      <div className="card col-8 offset-2">
        <h1 className="p-2">Profile Information</h1>
        <div className="container mt-5">
          <ul className="nav nav-pills justify-content-between border p-2">
            <li className="nav-item">
              <a className="btn btn-success text-white">Personal</a>
            </li>
            <li className="nav-item">
              <Link className="nav-link font-weight-bold" to="#">Location</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link font-weight-bold" to="#">Contacts</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link font-weight-bold" to="#">Security</Link>
            </li>
          </ul>
        </div>
        <div className="card-body">
          {getPersonalInfoLoading ? (
            <p>Loading...</p>
          ) : getPersonalInfoError ? (
            <p>Error: {getPersonalInfoError}</p>
          ) : getPersonalInfo ? (
            <div className="row">
              <div className="col-lg-12">
                <form onSubmit={handleSubmit}>
                  {/* Рендерим поля динамически */}
                  {getPersonalInfo.map(renderInputField)}

                  {/* Кнопка будет disabled, если данные не изменились */}
                  <button type="submit" className="btn btn-primary" disabled={!isEditing}>
                    UPDATE DATA
                  </button>
                </form>
              </div>
            </div>
          ) : (<p>No data...</p>)}
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
