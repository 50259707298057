// getListDocumentsActions.js
import axios from 'axios';
import config from '../../../config';

export const FETCH_LISTDOCUMENTS_REQUEST = 'FETCH_LISTDOCUMENTS_REQUEST';
export const FETCH_LISTDOCUMENTS_SUCCESS = 'FETCH_LISTDOCUMENTS_SUCCESS';
export const FETCH_LISTDOCUMENTS_FAILURE = 'FETCH_LISTDOCUMENTS_FAILURE';

// Экшены для получения данных
export const fetchListDocumentsRequest = () => ({
  type: FETCH_LISTDOCUMENTS_REQUEST,
});

export const fetchListDocumentsSuccess = (listDocuments) => ({
  type: FETCH_LISTDOCUMENTS_SUCCESS,
  payload: listDocuments,
});

export const fetchListDocumentsFailure = (error) => ({
  type: FETCH_LISTDOCUMENTS_FAILURE,
  payload: error,
});

export const fetchListDocuments = (token) => async (dispatch) => {
  dispatch(fetchListDocumentsRequest());
  try {
    const response = await axios.get(`${config.apiBaseUrl}/document/list`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
    
    dispatch(fetchListDocumentsSuccess(response.data.data));
  } catch (error) {
    dispatch(fetchListDocumentsFailure(error.message));
  }
};


export const connectWebSocketListDocuments = (token, language = null) => {
  return (dispatch) => {
    const ws = new WebSocket(config.serverWebsocketUrl);

    ws.onopen = () => {
      console.log('Connected to WebSocket server getListDocumentsActions');
      ws.send(JSON.stringify({ type: 'getListDocumentsActions', token }));
    };

    ws.onmessage = (event) => {
      console.log('WebSocket message received getListDocumentsActions:', event.data);
      try {
        const data = JSON.parse(event.data);
        console.log('Parsed event.data:', data.listDocuments.data);

        if (data.type === 'getListDocumentsActions') {
          const listDocuments = data.listDocuments.data;
          
          // Преобразуем данные в массив
          // Добавил тля итерации
          const listDocumentsArray = Array.isArray(listDocuments) ? listDocuments : [listDocuments];

          console.log('ListDocumentsActions received (array):', listDocumentsArray);
          dispatch(fetchListDocumentsSuccess(listDocumentsArray));
        } else if (data.type === 'error') {
          dispatch(fetchListDocumentsFailure(data.message));
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error.message);
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      ws.close();
    };
  };
};
