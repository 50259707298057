import React, { useState, useRef, useEffect, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import styles from './Modal.module.scss';

interface ModalProps {
    show: boolean;
    onClose: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void
    title?: string | null;
    header?: ReactNode;
    body?: ReactNode;
    children?: ReactNode;
    showHeader?: boolean;
    showFooter?: boolean;
}

const Modal: React.FC<ModalProps> = ({ show, onClose, title = null, header, body, children, showHeader = true, showFooter = false }) => {
    // Эффект для управления размытием фона
    useEffect(() => {
        if (show) {
            document.body.classList.add(styles.modalBlurBackground); // Добавляем размытие к body
        } else {
            document.body.classList.remove(styles.modalBlurBackground);
        }

        return () => document.body.classList.remove(styles.modalBlurBackground);
    }, [show]);

    if (!show) return null; // Если show === false, не рендерим модальное окно

    // Контент модального окна
    const modalContent = (
        <>
            <div className={styles.overlay} onClick={onClose}></div>
            <div className="modal show d-block" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        {showHeader && (
                            <div className="modal-header">
                                {header && header}
                                <h2>{title}</h2>
                                <button className={styles.btnСlose} onClick={onClose}>
                                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                        <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </button> {/* Кнопка закрытия */}
                            </div>
                        )}

                        <div className="modal-body">
                            {body || children} {/* Если body отсутствует, будет показан children */}
                        </div>

                        {showFooter && (
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={onClose}>
                                    Close
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );

    // Используем портал для рендеринга в #modal-root
    const modalRoot = document.getElementById('modal-root'); 
    if (!modalRoot) return null; 
    // Используем портал для рендеринга в #modal-root 
    return ReactDOM.createPortal( modalContent, modalRoot );
    
};

export default Modal;
