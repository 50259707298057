import React, { useEffect } from 'react' 
import { useDispatch, useSelector } from 'react-redux' 
import { useNavigate } from 'react-router-dom' 
import FavoriteGameList, {GameType} from '../elems/FavoriteGameList'

import {
  selectListFavoriteGames,
  selectListFavoriteGamesError,
  selectListFavoriteGamesErrorMessage,
  selectListFavoriteGamesLoading,
  selectListFavoriteGamesMessages,
  selectListFavoriteGamesState
} from 'src/redux/selectors/player/listFavoriteGamesSelectors' 
import { selectIsAuthenticated, selectToken } from '../../redux/selectors/site/loginSelectors' 
import {fetchToListFavoriteGames} from "src/redux/actions/player/listFavoriteGamesActions"

const FavoriteGames: React.FC = () => {
  const dispatch = useDispatch() 
  const navigate = useNavigate() 
  const listFavoriteGamesState = useSelector(selectListFavoriteGamesState) 
  const listFavoriteGames = useSelector(selectListFavoriteGames) as { game: GameType }[] 
  const listFavoriteGamesMessages = useSelector(selectListFavoriteGamesMessages) 
  const listFavoriteGamesLoading = useSelector(selectListFavoriteGamesLoading) 
  const listFavoriteGamesError = useSelector(selectListFavoriteGamesError) 
  const listFavoriteGamesErrorMessage = useSelector(selectListFavoriteGamesErrorMessage) 
  const isAuthenticated = useSelector(selectIsAuthenticated) 
  const token = useSelector(selectToken) 

  useEffect(() => {
    if (isAuthenticated && token) {
      dispatch<any>(fetchToListFavoriteGames(token))  // Вызов экшена для получения данных
    }
  }, [dispatch, isAuthenticated, token]) 

  useEffect(() => {
    console.log('listFavoriteGames output selector', listFavoriteGames) 
    console.log('🚀listFavoriteGames output listFavoriteGamesState', listFavoriteGamesState) 
    console.log('🚀listFavoriteGames output listFavoriteGames', listFavoriteGames) 
  }, [listFavoriteGames, listFavoriteGamesState, listFavoriteGamesLoading]) 

  const handleStarClick = (game: GameType) => {
    console.log(`Star clicked for game: ${game.name}`) 
  } 

  const handleDemoClick = (game: GameType) => {
    console.log(`Demo clicked for game: ${game.name}`) 
  } 

  const handlePlayClick = (game: GameType) => {
    console.log(`Play clicked for game: ${game.name}`) 
  } 

  // Я обновил \platform\api\controllers\PlayerController.php actionListFavoriteGames() так как там возвращалась одна игра вместо списка
  // Для работы данного компонента нужно принять этот коммит в ветке SERG__Fix-actionListFavoriteGames в platform
  return (
      <>
        <div>
          <h1>Favorite Games.</h1>
          {/* <GameGroups />
        <Providers /> */}
        </div>

        {listFavoriteGamesLoading ? (
            <p>Loading...</p>
        ) : listFavoriteGamesErrorMessage ? (
            <p>Error: {listFavoriteGamesErrorMessage}</p>
        ) : listFavoriteGames.length > 0 ? (
            <>
              <p>Games in collection: {listFavoriteGames.length}</p>
              <FavoriteGameList
                  gameList={listFavoriteGames}
                  onStarClick={handleStarClick}
                  onDemoClick={handleDemoClick}
                  onPlayClick={handlePlayClick}
              />
            </>
        ) : (
            <p>No games available</p> // Если список игр пустой или не массив
        )}
      </>
  ) 
} 

// export default withAuth(FavoriteGames) 
export default FavoriteGames 
