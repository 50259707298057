// logoutReducer.js

import { 
    FETCH_LOGOUT_REQUEST, 
    FETCH_LOGOUT_SUCCESS, 
    FETCH_LOGOUT_FAILURE, 
    CLEAR_AUTH_DATA 
} from '../../actions/site/logoutActions';

const initialState = {
    loading: false,
    errors: null,
    statusSuccess: false, // относится к статусу, возвращаемому апи
};

const logoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LOGOUT_REQUEST:
            return {
                ...state,
                loading: true,
                errors: null,
                statusSuccess: false
            };
        case FETCH_LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                errors: null,
                statusSuccess: true
            };
        case FETCH_LOGOUT_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload,
                statusSuccess: false
            };
        case CLEAR_AUTH_DATA:
            return {
                ...state,
                errors: null,
                statusSuccess: false, // Сброс состояния успеха при очистке данных
            };
        default:
            return state;
    }
};

export default logoutReducer;
