import React, {useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from "../../../hooks"
import {clearGameInfo, fetchGameInfo, gameInfoSelector} from "../../../redux/slices/gameSlice"
import styles from "./Game.module.scss"
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import {IconButton, LinearProgress} from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {useSelector} from "react-redux"
import {selectToken} from "../../../redux/selectors/site/loginSelectors"

export const Game = () => {
  const {slug} = useParams()
  const token = useSelector(selectToken)
  const {gameInfo, loading} = useAppSelector(gameInfoSelector)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (token && slug) {
      dispatch(fetchGameInfo({token, slug}))
    }
    return () => {
      dispatch(clearGameInfo())
    }
  }, [token, slug])


  return (
    <div>
      <h1>Game page</h1>
      {loading
        ? <LinearProgress />
        : <>
          <div className={stylesCommon.name}>
            <IconButton onClick={() => navigate(-1)} aria-label="back">
              <ArrowBackIcon/>
            </IconButton>
            <div><b>Name:</b> {gameInfo?.data.name}</div>
          </div>

          <div className={styles.iframe}>
            <iframe
              src={gameInfo?.data.game_url ? gameInfo?.data.game_url : "https://en.wikipedia.org/wiki/Main_Page"}
              width="100%"
              height="100%"
              title="Game"
            ></iframe>
          </div>
          <div><b>Game provider:</b> {gameInfo?.data.game_provider.name_public}</div>
        </>
      }


    </div>
  )
}

