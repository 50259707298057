import React, { forwardRef, useState, MouseEvent } from 'react';
import config from '../../../../config.js';
import styles from '../PlayerDeposit.module.scss';
import { PaymentMethod } from '../PlayerDeposit';

// Определяем интерфейсы для пропсов и других типов
interface Currency {
  currency_id: string;
  currency_code: string;
  amount: number;
}

// interface PaymentMethod {
//   id: string;
//   name: string;
//   min_deposit: number;
//   max_deposit: number;
//   image_path?: string;
//   pay_system_name?: string;
// }

interface CurrencyAndPaymentFormProps {
  playerBalances: Currency[];
  getPaymentMethods: PaymentMethod[];
  playerBalancesLoading: boolean;
  playerBalancesErrorString: string | null;
  getPaymentMethodsLoading: boolean;
  getPaymentMethodsErrorString: string | null;
  selectedCurrency: string | null | Currency;
  selectedPaymentMethod?: string | null | PaymentMethod;
  handleCurrencyChange: (currencyId: string) => void;
  handlePaymentMethodChange: (paymentMethodId: string) => void;
  handleButtonContinueClick: (e: MouseEvent<HTMLButtonElement>) => void;
  isButtonContinueClick: boolean;
}

const imgDir = config.fileUrls.payment;
const defaultImg = "https://img.freepik.com/premium-vector/content-delivery-network-concept-proxy-anonymous-vpn-vector-design-cloud-processing-computing_135661-1393.jpg?semt=ais_hybrid";

const handleCurrencyMore = (e: MouseEvent<HTMLButtonElement>) => {
  // Ваша логика обработки
};

const CurrencyAndPaymentForm = forwardRef<HTMLDivElement, CurrencyAndPaymentFormProps>(({
  playerBalances,
  getPaymentMethods,
  playerBalancesLoading,
  playerBalancesErrorString,
  getPaymentMethodsLoading,
  getPaymentMethodsErrorString,
  selectedCurrency,
  selectedPaymentMethod,
  handleCurrencyChange,
  handlePaymentMethodChange,
  handleButtonContinueClick,
  isButtonContinueClick
}, ref) => {
  const [activeButton, setActiveButton] = useState<number>(1);

  const buttonData = [
    { id: 1, label: 'Deposit' },
    { id: 2, label: 'Button 2' },
    { id: 3, label: 'Button 3' },
  ];

  const handleButtonClick = (id: number) => {
    setActiveButton(id); // Устанавливаем активную кнопку
  };

  return (
    <div ref={ref}>
      <div className={`card p-1 rounded-pill ${styles.borderStyleRound}`}>
        <div className={`${styles.buttonGroup} btn-group d-flex`} role="group" aria-label="Basic example">
          {buttonData.map((button) => (
            <button
              key={button.id}
              onClick={() => handleButtonClick(button.id)}
              className={`${styles.buttonBgTransparent} btn ${activeButton === button.id ? 'btn-success' : 'btn-outline-secondary'} rounded-pill`}
            >
              {button.label}
            </button>
          ))}
        </div>
      </div>

      <div className={`card p-1 mt-2 rounded`}>
        <div className="h4">
          <strong>Currency for deposit</strong>
        </div>
        {selectedCurrency && typeof selectedCurrency !== 'string' && (
          <p className="card-text">
            Balance: {selectedCurrency.amount} {selectedCurrency.currency_code}
          </p>
        )}
        <div className={`${styles.buttonGroup} btn-group d-flex`} role="group" aria-label="Currency">
          {playerBalances.map((balance) => (
            <button
              key={balance.currency_id}
              type="button"
              onClick={() => handleCurrencyChange(balance.currency_id)}
              className={`btn ${typeof selectedCurrency !== 'string' && selectedCurrency?.currency_id === balance.currency_id ? `btn-outline-success ${styles.border2}` : 'btn-outline-secondary'} ${styles.currencyButton} rounded-pill`}
            >
              {balance.currency_code}
            </button>
          ))}
        </div>
        <button
          type="button"
          onClick={(e) => handleCurrencyMore(e)}
          className={`btn btn-secondary rounded-pill mt-2`}
        >
          More
        </button>
      </div>

      <div className={`card p-1 mt-2 rounded`}>
        <div className="h4">
          <strong>Payment methods</strong>
        </div>
        {selectedPaymentMethod !== 'string' && selectedPaymentMethod && (
          <p className="card-text">
            min deposit: {(selectedPaymentMethod as PaymentMethod).min_deposit} | max. deposit: {(selectedPaymentMethod as PaymentMethod).max_deposit}
          </p>

        )}
        <div className={`${styles.buttonGroup} btn-group d-flex`} role="group" aria-label="Payment method selection">
          {getPaymentMethodsLoading ? (
            <div>Loading...</div>
          ) : getPaymentMethodsErrorString ? (
            <div>{getPaymentMethodsErrorString}</div>
          ) : (
            <div className="btn-group d-flex flex-wrap" role="group" aria-label="Payment method selection">
              {getPaymentMethods.map((method) => (
                <button
                  key={method.id}
                  type="button"
                  onClick={() => handlePaymentMethodChange(method.id)}
                  className={`btn ${selectedPaymentMethod !== 'string' && (selectedPaymentMethod as PaymentMethod)?.id === method.id ? `btn-outline-success ${styles.border2}` : 'btn-outline-secondary'} ${styles.paymentMethodButton} rounded-pill`}
                >
                  <img
                    src={method.image_path ? `${imgDir}${method.image_path}` : defaultImg}
                    alt={method.pay_system_name}
                    style={{ width: 'auto', height: '20px', objectFit: 'cover' }}
                  />
                  <p>{method.name}</p>
                </button>
              ))}
            </div>
          )}
        </div>
        {getPaymentMethods.length > 0 ? (
          <button
            type="button"
            onClick={(e) => handleCurrencyMore(e)}
            className={`btn btn-secondary rounded-pill mt-2`}
          >
            More
          </button>
        ) : (
          <p>No data...</p>
        )}
      </div>

      {isButtonContinueClick && (!selectedCurrency || !selectedPaymentMethod) && (
        <div className="alert alert-danger mt-2" role="alert">
          <p>Please select both currency and payment method!</p>
        </div>
      )}

      <div className="mt-2">
        <button
          type="button"
          onClick={(e) => handleButtonContinueClick(e)}
          className="btn btn-success rounded-pill d-flex justify-content-center  w-100"
        >
          Continue
        </button>
      </div>
    </div>
  );
});

export default CurrencyAndPaymentForm;
