import DeviceDetector from "device-detector-js";

export const deviceDetector = () => {
    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent
    const device = deviceDetector.parse(userAgent);
    console.log('deviceDetector', device);
    return device;
    
  };
