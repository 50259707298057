// selectors/viewSelectors.js
import { RootState } from '../../store';

// Селектор для получения текущего вида
export const selectCurrentView = (state: RootState) => state.viewPlayerDepositReducer.currentView;

// Селектор для получения предыдущего вида
export const selectPreviousView = (state: RootState) => state.viewPlayerDepositReducer.previousView;

// Селектор для получения списка видов
export const selectViews = (state: RootState) => state.viewPlayerDepositReducer.views;
