// updatePersonalInfoActions.js
import axios from 'axios';
import config from '../../../config';

export const FETCH_UPDATEPERSONALINFO_REQUEST = 'FETCH_UPDATEPERSONALINFO_REQUEST';
export const FETCH_UPDATEPERSONALINFO_SUCCESS = 'FETCH_UPDATEPERSONALINFO_SUCCESS';
export const FETCH_UPDATEPERSONALINFO_FAILURE = 'FETCH_UPDATEPERSONALINFO_FAILURE';

export const actionUpdatePersonalInfoRequest = () => ({
  type: FETCH_UPDATEPERSONALINFO_REQUEST,
});

export const actionUpdatePersonalInfoSuccess = (result, resultMessages) => ({
  type: FETCH_UPDATEPERSONALINFO_SUCCESS,
  result,
  resultMessages
});

export const actionUpdatePersonalInfoFailure = (error, errorMessage) => ({
  type: FETCH_UPDATEPERSONALINFO_FAILURE,
  error: error,
  errorMessage: errorMessage
});


//For Express
export const fetchUpdatePersonalInfo = (token, params = {}) => async (dispatch) => {

  // Инициализируем запрос
  dispatch(actionUpdatePersonalInfoRequest());

  try {
    console.log('Attempting to fetchUpdatePersonalInfo:' + `${config.serverUrl}/player/update-personal-info`, { token, params });

    const response = await axios.post(`${config.serverUrl}/player/update-personal-info`,
      { token, params },
      {
        headers: {
          'Content-Type': 'application/json',
        }
      });

    const data = response.data;
    if (response.status === 200 && data.success) {
      console.log('fetchUpdatePersonalInfo: ', data, data.data, data.messages); 
      dispatch(actionUpdatePersonalInfoSuccess(data.data, data.messages));
    } else {
      console.error('fetchUpdatePersonalInfo error:', data.errors);
      dispatch(actionUpdatePersonalInfoFailure(data.errors, data.message || 'Error updating personal info.'));
    }
  } catch (error) {
    if (error.response) {
      console.error(`Error: ${error.response.status}`, error.response.data);

      if (error.response.status === 404) {
        dispatch(actionUpdatePersonalInfoFailure(null, 'API endpoint not found.'));
      } else {
        dispatch(actionUpdatePersonalInfoFailure(error.response.data.error, error.response.data.message || 'Server error.'));
      }
    } else {
      console.error('fetchUpdatePersonalInfo network error:', error.message);
      dispatch(actionUpdatePersonalInfoFailure(null, 'Network error: Unable to connect to the server.'));
    }
  }
};


//For Websocket
export const connectWebSocketUpdatePersonalInfo = (token, paramsObj = {}) => {
  return (dispatch) => {
    const ws = new WebSocket(config.serverWebsocketUrl);
    const params = JSON.stringify(paramsObj);

    console.log('config.serverWebsocketUrl: ' + config.serverWebsocketUrl);
    console.log('connectWebSocketUpdatePersonalInfo params: ' + params);


    ws.onopen = () => {
      console.log('Connected to WebSocket server UpdatePersonalInfo');
      ws.send(JSON.stringify({ type: 'updatePersonalInfo', token, params }));
    };

    ws.onmessage = (event) => {
      console.log('WebSocket message received UpdatePersonalInfo:', event.data);
      try {
        const data = JSON.parse(event.data);
        console.log('Parsed event.data:', data.result.data);
        console.log('Parsed data.messages:', data.result.messages);

        if (data.type === 'updatePersonalInfo') {
          const resultData = data.result.data;
          const resultMessages = data.result.messages;

          // Преобразуем данные в массив
          const resultDataArray = Array.isArray(resultData) ? resultData : [resultData];
          // const resultMessagesArray = Array.isArray(resultMessages) ? resultMessages : [resultMessages];

          console.log('UpdatePersonalInfo received (array):', resultDataArray);
          dispatch(actionUpdatePersonalInfoSuccess(resultDataArray, resultMessages));
        } else if (data.type === 'errorUpdatePersonalInfo') {
          dispatch(actionUpdatePersonalInfoFailure(data.error, data.message));
        }
      } catch (error) {
        console.error('Error parsing UpdatePersonalInfo WebSocket message:', error.message);
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from UpdatePersonalInfo WebSocket server');
    };

    return () => {
      ws.close();
    };
  };
};
