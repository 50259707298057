import config from '../../config'
import React, {useEffect} from 'react'
import {GameItem} from '../../common/types'

type GameListProps = {
  gameList: GameItem[]
  onStarClick: (game: GameItem) => void
  onDemoClick: (game: GameItem) => void
  onPlayClick: (game: GameItem) => void
}

const GameList: React.FC<GameListProps> = ({gameList, onStarClick, onDemoClick, onPlayClick}) => {
  useEffect(() => {
    console.log('gameList', gameList)
  }, [gameList])

  if (!gameList?.length) {
    return <p>No games available...</p>
  }

  return (
    <div className='row wrap-games'>
      {gameList.map((item, i) => (
        <div key={i} className='col-3'>
          <div className='item-game'>
            <div className='item-game_pic'>
              <img className='' src={`${config.fileUrls.game}${item.image_path_album}`} alt={item.name}/>
            </div>
            <div className='item-game_info p-2 d-flex flex-column'>
              <div className='d-flex justify-content-end'>
                <i className='fa fa-star-o' onClick={() => onStarClick(item)}></i>
              </div>
              <div className=''>
                <h5>{item.name}</h5>
              </div>
              <div className='font-weight-bold'>
                <span style={{width: '1rem'}}>{item.group_name}</span>
              </div>
              <div className='d-flex justify-content-between mt-auto'>
                <button type='button' className='btn btn-primary' onClick={() => onDemoClick(item)}>
                  Demo
                </button>
                <button type='button' className='btn btn-success' onClick={() => onPlayClick(item)}>
                  Play
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default GameList
