import axios from 'axios';
import config from '../../../config';
import { Action, Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { ThunkAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

// Константы для типов действий
export const ACTION_GET_DEPOSIT_BONUSLIST_REQUEST = 'ACTION_GET_DEPOSIT_BONUSLIST_REQUEST';
export const ACTION_GET_DEPOSIT_BONUSLIST_SUCCESS = 'ACTION_GET_DEPOSIT_BONUSLIST_SUCCESS';
export const ACTION_GET_DEPOSIT_BONUSLIST_FAILURE = 'ACTION_GET_DEPOSIT_BONUSLIST_FAILURE';

// Интерфейсы для данных и сообщений
interface BonusData {
    id: number;
    name: string;
    // Дополнительные поля, соответствующие структуре данных бонусов
}

interface SuccessResponse {
    success: true;
    data: BonusData[];
    messages: string[];
}

interface ErrorResponse {
    success: false;
    errors: string[];
    message?: string;
}

// Объединённый тип для успешного или неуспешного ответа
type ApiResponse = SuccessResponse | ErrorResponse;

// Функции-предикаты для проверки типа ответа
const isSuccessResponse = (response: ApiResponse): response is SuccessResponse => response.success === true;
const isErrorResponse   = (response: ApiResponse): response is ErrorResponse => response.success === false;

// Интерфейсы для экшенов
interface RequestAction {
    type: typeof ACTION_GET_DEPOSIT_BONUSLIST_REQUEST;
}

interface SuccessAction {
    type: typeof ACTION_GET_DEPOSIT_BONUSLIST_SUCCESS;
    payload: { data: BonusData[]; messages: string[] };
}

interface FailureAction {
    type: typeof ACTION_GET_DEPOSIT_BONUSLIST_FAILURE;
    payload: { error: string | null; message: string };
}

export type BonusListActions = RequestAction | SuccessAction | FailureAction;

// Экшены для получения данных
export const actionGetDepositBonusListRequest = (): RequestAction => ({
    type: ACTION_GET_DEPOSIT_BONUSLIST_REQUEST,
});

export const actionGetDepositBonusListSuccess = (data: BonusData[], messages: string[]): SuccessAction => ({
    type: ACTION_GET_DEPOSIT_BONUSLIST_SUCCESS,
    payload: { data, messages },
});

export const actionGetDepositBonusListFailure = (error: string | null, message: string): FailureAction => ({
    type: ACTION_GET_DEPOSIT_BONUSLIST_FAILURE,
    payload: { error, message },
});

// Экшен для получения списка бонусов с расширенной обработкой ошибок
export const fetchGetDepositBonusList = (
    token: string,
    event: number | null,
    language: string | null = null
): ThunkAction<Promise<void>, RootState, unknown, Action<any>> => async (dispatch: Dispatch<BonusListActions>) => {
    dispatch(actionGetDepositBonusListRequest());

    try {
        console.log('Attempting to fetchGetDepositBonusList:', `${config.serverUrl}/bonus/list`, { token, event, language });

        const response = await axios.get<ApiResponse>(`${config.serverUrl}/bonus/list`, {
            params: { token, event, language },
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
        });

        const data = response.data;
        console.log('fetchGetDepositBonusList data (after ajax):', data);

        if (isSuccessResponse(data)) {
            console.log('actionGetDepositBonusListSuccess data.data:', data.data);
            dispatch(actionGetDepositBonusListSuccess(data.data, data.messages));
        } else if (isErrorResponse(data)) {
            console.error('fetchGetDepositBonusList error:', data.errors);
            dispatch(actionGetDepositBonusListFailure(data.errors.join(', '), data.message || 'Error fetching deposit bonus list.'));
        }
    } catch (error: any) {
        if (axios.isAxiosError(error) && error.response) {
            console.error(`Error: ${error.response.status}`, error.response.data);

            if (error.response.status === 404) {
                dispatch(actionGetDepositBonusListFailure(null, 'API endpoint not found.'));
            } else {
                dispatch(actionGetDepositBonusListFailure(error.response.data.error || 'Unknown error', error.response.data.message || 'Server error.'));
            }
        } else {
            console.error('fetchGetDepositBonusList network error:', error.message);
            dispatch(actionGetDepositBonusListFailure(null, 'Network error: Unable to connect to the server.'));
        }
    }
};

// export function fetchGetDepositBonusList(
//     userData: any
// ): ThunkAction<Promise<void>, RootState, unknown, Action<any>>;
