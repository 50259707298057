import React, { ChangeEvent } from 'react';

interface TextInputProps {
  id: string;
  name: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  placeholder: string;
  error?: string;
  hint?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  name,
  value,
  onChange,
  required = false,
  placeholder,
  error,
  hint,
}) => (
  <div className="form-group mb-3">
    <label htmlFor={id} className="form-label">
      <strong>
        {placeholder} {required && <span style={{ color: 'red' }}>*</span>}
      </strong>
    </label>
    <input
      type="text"
      id={id}
      name={name}
      className={`form-control ${error ? 'is-invalid' : ''}`}
      value={value}
      onChange={onChange}
      required={required}
      placeholder={`${placeholder} here ...`}
      data-error-required={`${placeholder} is required`}
    />
    {error && (
      <div className="invalid-feedback">
        {error}
      </div>
    )}
    {hint && (
      <small className="form-text text-muted">
        {hint}
      </small>
    )}
  </div>
);

export default TextInput;
