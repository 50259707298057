import React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

interface Props extends ButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SquareButton = ({onClick, ...props}: Props) => {

  const StyledSquareButton = styled(Button)({
    width: '38px',
    height: '38px',
    minWidth: '38px',
    '&.Mui-disabled': {
      backgroundColor: '#ADD8E6',
    },
  });


  return (
    <StyledSquareButton
      {...props} // // Pass the rest of the props (disabled, color, variant и т.д.)
      onClick={onClick}
    />
  )
}