// platform-client-app\react-platform\src\redux\selectors\site\registerValidationFirstScreenSelectors.ts

import { RootState } from '../../store';// Импортируем тип состояния корневого редьюсера

export const selectRegisterValidationFirstScreen = (state: RootState) => state.registerValidationFirstScreenReducer.data;
export const selectRegisterValidationFirstScreenSuccess = (state: RootState) => state.registerValidationFirstScreenReducer.success === true;
export const selectRegisterValidationFirstScreenMessages = (state: RootState) => state.registerValidationFirstScreenReducer.messages;
export const selectRegisterValidationFirstScreenLoading = (state: RootState) => state.registerValidationFirstScreenReducer.loading;
export const selectRegisterValidationFirstScreenErrors = (state: RootState) => state.registerValidationFirstScreenReducer.errors;

// export const selectRegisterValidationFirstScreenErrorsString = (state: RootState): string | null => {
//   const error = state.registerValidationFirstScreenReducer.errors;

//   if (Array.isArray(error)) {
//     return error.join('\n');
//   }

//   if (typeof error === 'object' && error !== null) {
//     return Object.entries(error)
//       .map(([key, value]) => `${key}: ${value}`)
//       .join('\n');
//   }

//   return error;
// };

// export const selectRegisterValidationFirstScreenErrorsArray = (state: RootState): string[] | null => {
//   const error = state.registerValidationFirstScreenReducer.errors;
//   let errorsArray: string[] = [];

//   if (Array.isArray(error)) {
//     errorsArray = error.map(err => `${err}`);
//   } else if (typeof error === 'object' && error !== null) {
//     errorsArray = Object.entries(error)
//       .flatMap(([key, value]) => Array.isArray(value) ? value.map(val => `${key}: ${val}`) : `${key}: ${value}`);
//   } else if (error) {
//     errorsArray = [error];
//   }

//   return errorsArray.length > 0 ? errorsArray : null;
// };
