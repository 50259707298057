import React, {MouseEvent, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {selectIsAuthenticated} from '../../../redux/selectors/site/loginSelectors'
import styles from './Sidebar.module.scss'
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import {useGetMenuQuery} from '../../../api/api'
import {setPositionStylesModalMyProgress, setShowModalMyProgress} from "src/redux/actions/player/myProgressActions"
import {selectShowModalMyProgress} from "src/redux/selectors/player/myProgressSelectors"
import {MenuItemWithoutChildren} from "../../../api/types"
import {useAppDispatch, useAppSelector} from "../../../hooks"
import {
  childrenMenuSelector,
  interfaceSelector,
  setChildrenMenuPosition,
  setIsChildrenMenuOpened,
  setSelectedMenu
} from "../../../redux/slices/interfaceSlice"
import {NavWithChildrenMenuClose} from "../NavWithChildrenMenuClose/NavWithChildrenMenuClose"
import trophy_2_1 from 'src/assets/images/trophy_2_1.png'
import chevron_right from 'src/assets/images/icons/chevron_right.svg'
import chevron_right_hover from 'src/assets/images/icons/chevron_right_hover.svg'
import clsx from "clsx"
import {ColorBlock} from "./ColorBlock/ColorBlock"
import {QuestionBlock} from "./QuestionBlock/QuestionBlock"

const Sidebar = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const showModalMyProgress = useSelector(selectShowModalMyProgress)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const { selectedLanguage } = useAppSelector(interfaceSelector)
  const { isChildrenMenuOpened, selectedMenu } = useAppSelector(childrenMenuSelector)
  const { data: menuData } = useGetMenuQuery(selectedLanguage.id) // getting menu from server

  useEffect(() => {
    console.log('menuData', menuData);
  }, [menuData]);

  const [hoveredItems, setHoveredItems] = useState<{ [key: number]: boolean }>({}); // state for storing hovered chevron

  const handleMyProgress = (e: React.MouseEvent<HTMLElement>) => {
    dispatch(setIsChildrenMenuOpened(false))
    if (!isAuthenticated) {
      navigate('/auth')
    } else {
      const rect = e.currentTarget.getBoundingClientRect()
      const positionStyles = {
        top: `${rect.top + window.scrollY}px`,
        left: `${rect.right + window.scrollX + 20}px`,
      }

      dispatch(setPositionStylesModalMyProgress(positionStyles))
      dispatch(setShowModalMyProgress(!showModalMyProgress))
    }
  }

  const onChildrenMenu = (e: MouseEvent<HTMLDivElement>, menu?: MenuItemWithoutChildren[]) => {

    const sidebar = e.currentTarget

    const menuOffsetCalculate = () => {
      if (!menu) return 0
      
      const getNumberFromPx = (value: string) => parseFloat(value.replace('px', '').trim())

      const styles = getComputedStyle(document.documentElement)
      const sidebarItemHeight = getNumberFromPx(styles.getPropertyValue('--sidebar-item-height'))
      const itemCenterOffset  = sidebarItemHeight / 2  // first offset: half of item
      const elementsCount =  menu.length
      const sidebarItemsGap = getNumberFromPx(styles.getPropertyValue('--sidebar-items-gap'))
      const childrenMenuHeight = (elementsCount * sidebarItemHeight) + (sidebarItemsGap * (elementsCount - 1))
      const menuCenterOffset  = childrenMenuHeight / 2  // first offset: half children menu

      return itemCenterOffset - menuCenterOffset
    }

    const openMenu = () => {
      const positionTop = sidebar.offsetTop + menuOffsetCalculate()
      dispatch(setChildrenMenuPosition(positionTop))
      dispatch(setSelectedMenu(menu))
      dispatch(setIsChildrenMenuOpened(true))
    }

    if (isChildrenMenuOpened) {
      if (menu === selectedMenu) { // if we clicked on the same menu
        dispatch(setIsChildrenMenuOpened(false))
      } else {
        dispatch(setIsChildrenMenuOpened(false)) // First close the current menu
        setTimeout(openMenu, 100) // Open new after animation
      }
    } else {
      openMenu() // If the menu is closed, open it immediately
    }
  }

  const handleMouseEnter = (id: number) => {
    setHoveredItems((prevState) => ({ ...prevState, [id]: true }));
  };

  const handleMouseLeave = (id: number) => {
    setHoveredItems((prevState) => ({ ...prevState, [id]: false }));
  };

  return (
    <div className={styles.sidebar}>
      <ColorBlock 
        variant={'Wheel of Fortune'}
        className={styles.wheelOfFortune}
        title={'Wheel of Fortune'}
        subtitle={'Spin and win!'}
      />
      <ColorBlock
        variant={'Free Bonuses'} 
        className={styles.freeBonuses}
        title={'Free Bonuses'}
        subtitle={'Find your treasure'}

      />
      <div className={styles.hardAndMapElementsContainer}>
        <div className={stylesCommon.itemsContainer}>
          <div className={stylesCommon.item}>
            <img src={trophy_2_1} alt="trophy_2_1" className={stylesCommon.trophy}/>
            <div className={clsx(stylesCommon.link, styles.title)} onClick={handleMyProgress}>
              My Progress
            </div>
          </div>
          <div className={stylesCommon.item}>
            <img src={trophy_2_1} alt="trophy_2_1" className={stylesCommon.trophy}/>
            <NavWithChildrenMenuClose
              item={'Page List'}
              onClick={() => navigate('/pagelist')}
            />
          </div>
        </div>

        {menuData?.success && (
          <div className={stylesCommon.itemsContainer}>
            {menuData?.data.map((item) => (
              <div key={item.id}
                   className={stylesCommon.item}
                   onClick={(e) => onChildrenMenu(e, item.children)}
                   onMouseEnter={() => handleMouseEnter(item.id)}
                   onMouseLeave={() => handleMouseLeave(item.id)}
              >
                <img src={trophy_2_1} alt="trophy_2_1" className={stylesCommon.trophy}/>
                {!item.children ? (
                  <NavWithChildrenMenuClose item={item}/>
                ) : (
                  <div className={stylesCommon.link} onClick={(e) => onChildrenMenu(e, item.children)}>
                    <div className={styles.chevronContainer}>
                      <div className={styles.title}>{item.title}</div>
                      <div className={styles.chevronWrapper}>
                        {hoveredItems[item.id] ? (
                          <img src={chevron_right_hover} alt="chevron_right_hover" className={styles.chevron} />
                        ) : (
                          <img src={chevron_right} alt="chevron_right" className={styles.chevron} />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      <QuestionBlock/>
      <ColorBlock
        variant={'Vip Club'}
        className={styles.vipClub}
        title={'Vip Club'}
        subtitle={'Сash back and rewards!'}
      />
    </div>
  )
}

export default Sidebar

//todo: need to do all item height area clickable
