// Withdrawals.tsx
import React from 'react'
import {withdrawalsSelector} from 'src/redux/slices/transactionsSlice'
import {DepositWithdrawalHandler} from "./common/DepositWithdrawalHandler/DepositWithdrawalHandler"

export const Withdrawals = () => {
  return (
    <DepositWithdrawalHandler
      direction="withdrawal"
      selector={withdrawalsSelector}
    />
  );
};