//providerActions.js
import axios from 'axios';
import config from '../../config';

export const FETCH_PROVIDERS_REQUEST = 'FETCH_PROVIDERS_REQUEST';
export const FETCH_PROVIDERS_SUCCESS = 'FETCH_PROVIDERS_SUCCESS';
export const FETCH_PROVIDERS_FAILURE = 'FETCH_PROVIDERS_FAILURE';

export const fetchProvidersRequest = () => ({
  type: FETCH_PROVIDERS_REQUEST,
});

export const fetchProvidersSuccess = (providers) => ({
  type: FETCH_PROVIDERS_SUCCESS,
  payload: providers,
});

export const fetchProvidersFailure = (error) => ({
  type: FETCH_PROVIDERS_FAILURE,
  payload: error,
});

export const fetchProviders = (token) => async (dispatch) => {
  dispatch(fetchProvidersRequest());
  try {
    //http://api.platform.loc/game/get-providers
    const response = await axios.get(`${config.apiBaseUrl}/game/get-providers`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json; charset=UTF-8',
      }
    });
    dispatch(fetchProvidersSuccess(response.data));
  } catch (error) {
    dispatch(fetchProvidersFailure(error.message));
  }
};

// Экшены для WebSocket
export const connectWebSocket = (token) => {
  console.log(token);
  return (dispatch) => {
    const ws = new WebSocket(`${config.serverWebsocketUrl}`);

    ws.onopen = () => {
      console.log('Connected to WebSocket server to getProviders');
      ws.send(JSON.stringify({ type: 'getProviders', token }));
    };

    ws.onmessage = (event) => {
      console.log('WebSocket getProviders message received:', event.data);
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'getProviders') {
          console.log('Providers received:', data.providers.data);
          dispatch(fetchProvidersSuccess(data.providers.data));
        } else if (data.type === 'error') {
          console.log('Error received:', data.message);
          dispatch(fetchProvidersFailure(data.message));
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error.message);
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      ws.close();
    };
  };
};
