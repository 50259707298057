import React, {ReactNode} from 'react'
import styles from "./MainLayout.module.scss"
import {ChildrenMenu} from "../ChildrenMenu/ChildrenMenu"
import MyProgressModal from "src/components/elems/MyProgressModal"
import Navigation from "src/components/layouts/Navigation/Navigation"
import Sidebar from "../Sidebar/Sidebar"
import {Container} from '@mui/material'
import Grid from '@mui/material/Grid2'

type MainLayoutProps = {
  children: ReactNode;
};

const MainLayout: React.FC<MainLayoutProps> = ({children}) => {
  return (
    <Container className={styles.mainLayout} disableGutters maxWidth={false}>
      <Navigation/>
      <Grid container className={styles.gridContainer} columns={12}>
        <Grid size={2} className={styles.sidebarGrid}>
          <Sidebar/>
          <ChildrenMenu/>
        </Grid>
        <Grid size={10} className={styles.contentGrid}>
          <MyProgressModal/>
          {children}
        </Grid>
      </Grid>
    </Container>
  )
}

export default MainLayout
