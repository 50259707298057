import React from 'react'
import styles from './ColorBlock.module.scss'
import clsx from 'clsx'
import colorBlock_trophy_2_1 from 'src/assets/images/colorBlock_trophy_2_1.png'
import colorBlock_treasure_chest_1 from 'src/assets/images/colorBlock_treasure_chest_1.png'
import colorBlock_trophy_2_1_2 from 'src/assets/images/colorBlock_trophy_2_1_2.png'

type Variant = 'Wheel of Fortune' | 'Free Bonuses' | 'Vip Club'

type Props = {
  variant: Variant
  className?: string
  title: string
  subtitle: string
}

const colorSelector = (variant: Variant) => {
  switch (variant) {
    case 'Wheel of Fortune':
      return styles.wheelOfFortune
    case 'Free Bonuses':
      return styles.bonuses
    case 'Vip Club':
      return styles.vipClub
    default:
      return ''
  }
}

const imageSelector = (variant: Variant) => {
  switch (variant) {
    case 'Wheel of Fortune':
      return colorBlock_trophy_2_1
    case 'Free Bonuses':
      return colorBlock_treasure_chest_1
    case 'Vip Club':
      return colorBlock_trophy_2_1_2
    default:
      return ''
  }
}

// Wheel of Fortune, Free Bonuses, Vip Club
export const ColorBlock = ({variant, className, title, subtitle}: Props) => {
  return (
    <div className=
           {
             clsx(
               styles.colorBlock,
               colorSelector(variant),
               className
             )
           }>
      <img src={imageSelector(variant)} alt={'colorBlock_trophy'} className={styles.image}/>
      <div className={styles.textContainer}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
    </div>

  )
}