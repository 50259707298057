// platform-client-app\react-platform\src\redux\reducers\setting\getRegistrationFormSettingsReducer.js
import {
  ACTION_GET_REGISTRATION_FORM_SETTINGS_REQUEST,
  ACTION_GET_REGISTRATION_FORM_SETTINGS_SUCCESS,
  ACTION_GET_REGISTRATION_FORM_SETTINGS_FAILURE,
  ACTION_SET_REGISTRATION_FORM_SETTINGS_ID
} from '../../actions/setting/getRegistrationFormSettingsActions';

const initialState = {
  loading: false,
  error: null,
  data: [],
  messages: null,
  idForm: null,// используем при переключении на разные формы
};

const getRegistrationFormSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_GET_REGISTRATION_FORM_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ACTION_GET_REGISTRATION_FORM_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        messages: action.payload.messages,
        error: null,
      };

    case ACTION_GET_REGISTRATION_FORM_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case ACTION_SET_REGISTRATION_FORM_SETTINGS_ID:
      return {
        ...state,
        idForm: action.payload.id,
      };  

    default:
      return state;
  }
};

export default getRegistrationFormSettingsReducer;
