// getListDocumentsReducer.js
import { FETCH_LISTDOCUMENTS_REQUEST, FETCH_LISTDOCUMENTS_SUCCESS, FETCH_LISTDOCUMENTS_FAILURE } from '../../actions/document/getListDocumentsActions';

const initialState = {
    listDocuments: [],
    loading: false,
    error: null,
    messages: null,
  };
  
  const getListDocumentsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_LISTDOCUMENTS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_LISTDOCUMENTS_SUCCESS:
        return {
          ...state,
          loading: false,
          listDocuments: action.payload,
        };
      case FETCH_LISTDOCUMENTS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default getListDocumentsReducer;
  