import config from '../../config'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {connectWebSocket} from 'src/redux/actions/bonus/bonusListActions'
import {
  selectBonusList,
  selectBonusListError,
  selectBonusListLoading
} from 'src/redux/selectors/bonus/bonusListSelectors'
import {selectIsAuthenticated, selectToken} from '../../redux/selectors/site/loginSelectors'

type Bonus = {
  id: number
  image_link: string | null
  bonusLangs: { name: string }[]
  bonusBudgets: {
    amount: number
    currency: { code: string }
  }
  bonusFreespins: {
    spin_count: number | null
    wager_multiplier: number | null
  }
};

const GameGroup = () => {
  const dispatch = useDispatch()
  const bonusList = useSelector(selectBonusList) as Bonus[] | null
  const loading = useSelector(selectBonusListLoading) as boolean
  const error = useSelector(selectBonusListError) as string | null
  const isAuthenticated = useSelector(selectIsAuthenticated) as boolean
  const token = useSelector(selectToken) as string | null

  useEffect(() => {
    if (isAuthenticated && token) {
      console.log('home page', token)
      const closeWebSocket = dispatch(connectWebSocket(token))

      return () => {
        if (closeWebSocket && typeof closeWebSocket === 'function') {
          closeWebSocket()
        }
      }
    }
  }, [dispatch, token, isAuthenticated])

  useEffect(() => {
    console.log('bonusList:', bonusList)
  }, [bonusList])

  const onClickMoreInfo = async (e: React.MouseEvent<HTMLButtonElement>, bonus: Bonus) => {
    e.preventDefault() // Предотвращение дефолтного поведения кнопки (если требуется)
    console.log(`onClickMoreInfo`, bonus)
  }

  const onClickGetBonus = async (e: React.MouseEvent<HTMLButtonElement>, bonus: Bonus) => {
    e.preventDefault()
    console.log(`onClickGetBonus`, bonus)
  }

  return (
    <>
      <div>
        <h1>Bonuses</h1>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <div className="row wrap-bonus">
          {bonusList &&
            bonusList.map((bonus, i) => (
              <div key={i} className="col-4">
                <div className="card">
                  {bonus.image_link !== null && bonus.image_link !== '' ? (
                    <img
                      className="card-img-top"
                      src={`${config.fileUrls.bonusFolder}${bonus.image_link}`}
                      alt={bonus.bonusLangs[0].name}
                    />
                  ) : (
                    <div className="defaultImage">
                      <h5>Bonus #{bonus.id}</h5>
                    </div>
                  )}
                  <div className="card-body">
                    <button type="button" className="btn btn-warning">Wellcome bonus group</button>
                    <h4 className="card-title">{bonus.bonusLangs[0].name}</h4>
                    <p className="card-text">Фриспины {bonus.bonusFreespins.spin_count ? bonus.bonusFreespins.spin_count : '-'}</p>
                    <p className="card-text">Вейджеры
                      фриспинов {bonus.bonusFreespins.wager_multiplier ? bonus.bonusFreespins.wager_multiplier : '-'}</p>
                    <div className="bonus-card-buttons d-flex justify-content-between">
                      <button type="button" className="btn btn-primary"
                              onClick={(e) => onClickMoreInfo(e, bonus)}>Подробнее
                      </button>
                      <button type="button" className="btn btn-warning"
                              onClick={(e) => onClickGetBonus(e, bonus)}>Получить бонус
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </>

  )
}

export default GameGroup
