// platform-client-app\react-platform\src\redux\reducers\site\registrationReducer.js
import {
  ACTION_ACCAUNT_REGISTRATION_REQUEST,
  ACTION_ACCAUNT_REGISTRATION_SUCCESS,
  ACTION_ACCAUNT_REGISTRATION_FAILURE
} from '../../actions/site/registrationActions';

const initialState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};

const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_ACCAUNT_REGISTRATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: false
      };

    case ACTION_ACCAUNT_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        messages: action.payload.messages,
        success: true
      };

    case ACTION_ACCAUNT_REGISTRATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        success: false
      };

    default:
      return state;
  }
};

export default registrationReducer;
