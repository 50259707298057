// getPaymentMethodsSelectors.js
import { RootState } from '../../store';

export const selectGetPaymentMethods             = (state: RootState) => state.getPaymentMethodsReducer.data;
export const selectGetPaymentMethodsMessages     = (state: RootState) => state.getPaymentMethodsReducer.messages;
export const selectGetPaymentMethodsLoading      = (state: RootState) => state.getPaymentMethodsReducer.loading;

export const selectGetPaymentMethodsErrorString = (state: RootState): string | null => {
    const error = state.getPaymentMethodsReducer.error;
  
    if (Array.isArray(error)) {
      return error.join('\n');
    }
  
    if (typeof error === 'object' && error !== null) {
      return Object.entries(error)
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n');
    }
  
    return error;
  };