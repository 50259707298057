import React, {useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useGetPageBySlugQuery} from '../../api/api'
import {FetchBaseQueryError} from '@reduxjs/toolkit/query'
import {useAppSelector} from "../../hooks"
import {interfaceSelector} from "../../redux/slices/interfaceSlice"

export const Page = () => {
  const {slug} = useParams()
  const {selectedLanguage} = useAppSelector(interfaceSelector)

  const {data: response, error, isLoading} = useGetPageBySlugQuery({slug: slug || '', language: selectedLanguage.id})

  const pageData = response?.data?.length ? response.data[0] : null
  const errorText = response?.errors?.Page || 'Error loading page'

  const navigate = useNavigate()

  useEffect(() => {
    if (!error && response?.success && pageData) {
      document.title = pageData.meta_title || 'Loading...'
      const metaDescription = document.querySelector('meta[name="description"]')
      if (metaDescription) {
        metaDescription.setAttribute('content', pageData.meta_description || '')
      }
    } else {
      document.title = errorText
    }

    // Handle 404 error by navigating to the not found page
    if (error && 'status' in error) {
      const fetchError = error as FetchBaseQueryError
      if (fetchError.status === 404) {
        navigate('/404')
      }
    }
  }, [error, response, pageData, navigate])

  if (isLoading) return <p>Loading...</p>

  // If there are errors other than 404
  if (error || !response?.success || !pageData) return <p>{errorText}</p>

  return (
    <div>
      <h1>{pageData.h1}</h1>
      {pageData.subtitle && <h2>{pageData.subtitle}</h2>}
      <div>{pageData.body}</div>
    </div>
  )
}
