// platform-client-app\react-platform\src\redux\selectors\site\AuthformSelectors.ts

import { RootState } from '../../store';// Импортируем тип состояния корневого редьюсера

export const selectAuthform = (state: RootState) => state.authformReducer.data;
export const selectAuthformSuccess = (state: RootState) => state.authformReducer.success === true;
export const selectAuthformMessages = (state: RootState) => state.authformReducer.messages;
export const selectAuthformLoading = (state: RootState) => state.authformReducer.loading;
export const selectAuthformError = (state: RootState) => state.authformReducer.error;

export const selectAuthformErrorString = (state: RootState): string | null => {
  const error = state.authformReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectAuthformErrorArray = (state: RootState): string[] | null => {
  const error = state.authformReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map(err => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) => Array.isArray(value) ? value.map(val => `${key}: ${val}`) : `${key}: ${value}`);
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
