import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {GameInfoResponse} from "../../api/types"
import config from "../../config"

export const fetchGameInfo = createAsyncThunk<GameInfoResponse['gameInfo'], { token: string; slug: string }>(
  'game/getInfo',
  async ({ token, slug }, { dispatch }) => {
    const ws = new WebSocket(config.serverWebsocketUrl);

    // Typing the returned Promise with game data
    return new Promise<GameInfoResponse['gameInfo']>((resolve, reject) => {
      ws.onopen = () => {
        // Send a request to the server to get game information
        ws.send(JSON.stringify({
          type: 'getGameInfo',
          token,
          slug,
        }));
      };

      ws.onmessage = (event) => {
        const parsedData: GameInfoResponse = JSON.parse(event.data); // Typing the server response
        console.log('Received game info data:', parsedData);

        if (parsedData.gameInfo?.success) {  // Check if the request was successful
          console.log('Resolving with data:', parsedData.gameInfo);
          resolve(parsedData.gameInfo); // Возвращаем только gameInfo
        } else {
          reject(new Error(`Error fetching game info`));
        }
      };

      ws.onerror = (error) => {
        reject(new Error('WebSocket error: ' + error));
      };

      ws.onclose = () => {
        console.log('WebSocket connection closed');
      };
    });
  }
);

type GameState = {
  gameInfo: GameInfoResponse['gameInfo'] | null;
  loading: boolean;
  error: string | null;
};

const initialState: GameState = {
  gameInfo: null as GameInfoResponse['gameInfo'] | null,
  loading: false,
  error: null,
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    clearGameInfo: (state) => {
      state.gameInfo = null;  // Clear game data
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGameInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGameInfo.fulfilled, (state, action: PayloadAction<GameInfoResponse['gameInfo']>) => {
        state.loading = false;
        state.gameInfo = action.payload; // Save gameInfo object in state
      })
      .addCase(fetchGameInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch game info';
      });
  },
  selectors: {
    gameInfoSelector: state => state
  }
});

export const {clearGameInfo} = gameSlice.actions

export const gameReducer = gameSlice.reducer
export const {gameInfoSelector} = gameSlice.selectors