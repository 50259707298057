//providerReducer.js
import { FETCH_PROVIDERS_REQUEST, FETCH_PROVIDERS_SUCCESS, FETCH_PROVIDERS_FAILURE } from '../actions/providerActions';

const initialState = {
    providers: null,
    loading: false,
    error: null,
  };
  
  const providerReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PROVIDERS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_PROVIDERS_SUCCESS:
        return {
          ...state,
          loading: false,
          providers: action.payload,
        };
      case FETCH_PROVIDERS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default providerReducer;
  