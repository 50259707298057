import { FETCH_BALANCE_REQUEST, FETCH_BALANCE_SUCCESS, FETCH_BALANCE_FAILURE } from '../actions/balanceActions';

const initialState = {
    balance: null,
    loading: false,
    error: null,
  };
  
  const balanceReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_BALANCE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_BALANCE_SUCCESS:
        return {
          ...state,
          loading: false,
          balance: action.payload,
        };
      case FETCH_BALANCE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default balanceReducer;
  