// \util\codeConfirmationActions.js

import axios from 'axios';
import config from '../../../config';

export const ACTION_CODE_CONFIRMATION_REQUEST = 'ACTION_CODE_CONFIRMATION_REQUEST';
export const ACTION_CODE_CONFIRMATION_SUCCESS = 'ACTION_CODE_CONFIRMATION_SUCCESS';
export const ACTION_CODE_CONFIRMATION_FAILURE = 'ACTION_CODE_CONFIRMATION_FAILURE';

export const actionCodeConfirmationRequest = () => ({
    type: ACTION_CODE_CONFIRMATION_REQUEST,
});


export const actionCodeConfirmationSuccess = (data, messages) => ({
    type: ACTION_CODE_CONFIRMATION_SUCCESS,
    payload: { data, messages },
});

export const actionCodeConfirmationFailure = (error) => ({
    type: ACTION_CODE_CONFIRMATION_FAILURE,
    payload: { error },
});


//For Express
export const fetchCodeConfirmation = (userData = {}) => async (dispatch) => {

    // Инициализируем запрос
    dispatch(actionCodeConfirmationRequest());

    try {
        console.log('Attempting to fetchCodeConfirmation:' + `${config.serverUrl}/util/send-code-confirmation`, { userData });

        const response = await axios.post(`${config.serverUrl}/util/send-code-confirmation`,
            { userData },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

        const data = response.data;
        if (response.status === 200 && data.success) {
            console.log('fetchCodeConfirmation: ', data, data.data, data.messages);
            dispatch(actionCodeConfirmationSuccess(data.data, data.messages));
        } else {
            console.error('fetchCodeConfirmation error:', data.errors);
            dispatch(actionCodeConfirmationFailure(data.errors, data.message || 'Error code confirmation.'));
        }
    } catch (error) {
        const defaultErrorMessage = 'Error code confirmation.';
        const networkErrorMessage = 'Network error: Unable to connect to the server.';

        if (error.response) {
            console.error(`Error: ${error.response.status}`, error.response.data);

            const { status, data } = error.response;
            const { errors, message, error: singleError } = data;

            if (status === 404) {
                dispatch(actionCodeConfirmationFailure(null, 'API endpoint not found.'));
            } else if ([400, 403, 500].includes(status)) {
                dispatch(actionCodeConfirmationFailure(errors || singleError, message || defaultErrorMessage));
            } else {
                dispatch(actionCodeConfirmationFailure(singleError, message || 'Server error.'));
            }
        } else {
            console.error('fetchCodeConfirmation network error:', error.message);
            dispatch(actionCodeConfirmationFailure(null, networkErrorMessage));
        }
    }
};

