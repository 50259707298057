import React, { useState, useRef, useEffect, FormEvent } from 'react';
import Alert from '../../../layouts/ui/alert/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch } from "../../../../hooks"
import TextInput from './TextInput';
import config from '../../../../config.js';
import styles from '../PlayerDeposit.module.scss';
import { selectIsAuthenticated, selectToken } from '../../../../redux/selectors/site/loginSelectors';
import { fetchGetDepositBonusList } from '../../../../redux/actions/playerDeposit/bonusDepositListActions';
import {
    selectGetDepositBonusList,
    selectGetDepositBonusListLoading,
    selectGetDepositBonusListErrorString
} from '../../../../redux/selectors/playerDeposit/bonusDepositListSelectors';
import { PaymentMethod } from '../PlayerDeposit';

interface BonusLang {
    id: number;
    id_bonus: number;
    id_lang: number;
    name: string;
    description: string;
    image_path: string | null;
    image_link: string;
}

interface BonusGroupLang {
    id: number;
    id_bonus_group: number;
    id_lang: number;
    title: string;
    description: string;
}

interface BonusGroup {
    id: number;
    name: string | null;
    bonusGroupLangs: BonusGroupLang[];
}

interface BonusEvent {
    id: number;
    name: string;
}

interface Currency {
    id: number;
    type: string;
    title: string;
    code: string;
    symbol: string | null;
    flag_active: string;
    flag_active_players: string;
    pos: number;
}

interface BonusBudget {
    id: number;
    amount: number;
    flag_unlim: string;
    currency: Currency;
}

interface Bonus {
    id: string;
    subscription_availability: boolean;
    id_bonus_group: number;
    id_bonus_type: number;
    id_bonus_event: number;
    id_main_currency: number;
    external_id: string | null;
    status: string;
    flag_use_converter: string;
    flag_active: string;
    flag_all_currencies: string;
    flag_visible_player: string;
    flag_visible_guest: string;
    seo_url: string;
    priority: number | null;
    image_path: string | null;
    image_link: string;
    subscription_type: string;
    repeat_type: string;
    repeat_count: number;
    repeat_interval_type: string;
    repeat_interval_value: number;
    flag_repeat_total: string;
    accrual_type: string;
    flag_distribution_strict: string;
    distribution_bet_real: number;
    distribution_bet_bonus: number;
    distribution_win_real: number;
    distribution_win_bonus: number;
    wagering_type: string;
    freespin_wagering_type: string;
    freespin_wagering_value: number;
    restriction_days: string;
    flag_restriction_unsubscribe: string;
    expiration_type: string;
    expiration_relative_type: string;
    expiration_relative_duration: number;
    expiration_action: string;
    expiration_fixed_datetime: string | null;
    flag_cancellation: string;
    system_cancellation_action: string;
    player_cancellation_action: string;
    action_reaching_threshold: string;
    withdrawal_restriction: string;
    id_loyalty_min_level: number | null;
    id_loyalty_max_level: number | null;
    id_experience_min_level: number | null;
    id_experience_max_level: number | null;
    created_at: number;
    updated_at: number;
    bonusLangs: BonusLang[];
    bonusGroup: BonusGroup;
    bonusEvent: BonusEvent;
    bonusEventDeposits: any[];
    bonusBudgets: BonusBudget[];
    bonusFreespins: any[];
}

interface PaymentFields {
    // Определите точные поля, которые ожидаются в объекте fields
    [key: string]: any;
}

interface DepositDetailsProps {
    selectedCurrency: string;
    selectedPaymentMethod: PaymentMethod | null;
    handleSubmit: (e: FormEvent<HTMLFormElement>, fieldsToSave: { [key: string]: string | number | boolean }) => void;
}


const imgDir = config.fileUrls.payment;
const dafaultImg = "https://img.freepik.com/premium-vector/content-delivery-network-concept-proxy-anonymous-vpn-vector-design-cloud-processing-computing_135661-1393.jpg?semt=ais_hybrid";

const DepositDetails: React.FC<DepositDetailsProps> = ({ selectedCurrency, selectedPaymentMethod, handleSubmit }) => {
    const dispatch = useAppDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);
    const [formState, setFormState] = useState<{ [key: string]: any }>({});
    const [clientErrors, setClientErrors] = useState<{ required: { [key: string]: string } }>({ required: {} });
    // const [bonusStatuses, setBonusStatuses] = useState<{ [key: string]: boolean }>({});
    // const [changedBonuses, setChangedBonuses] = useState<string[]>([]);
    const [checkedBonus, setCheckedBonus] = useState<string>("");
    const formRef = useRef<HTMLFormElement>(null);

    const getDepositBonusList = useSelector(selectGetDepositBonusList);
    const getDepositBonusListLoading = useSelector(selectGetDepositBonusListLoading);
    const getDepositBonusListErrorString = useSelector(selectGetDepositBonusListErrorString);

    useEffect(() => {
        const eventIdDeposit = 2;
        if (isAuthenticated && token) {
            dispatch(fetchGetDepositBonusList(token, eventIdDeposit));  // Вызов экшена для получения данных
        }

        console.log('DepositDetails: fetchGetDepositBonusList');
    }, [dispatch, token, isAuthenticated]);

    useEffect(() => {
        console.log('DepositDetails: getDepositBonusList:', getDepositBonusList);
    }, [getDepositBonusList]);

    // useEffect(() => {
    //     const initialStatuses: { [key: string]: boolean } = {};
    //     getDepositBonusList.forEach((bonus: { id: string; flag_active: string }) => {
    //         initialStatuses[bonus.id] = bonus.flag_active === "YES";
    //     });
    //     setBonusStatuses(initialStatuses);
    // }, [getDepositBonusList]);

    // const toggleBonusStatus = (bonusId: string) => {
    //     setBonusStatuses(prevStatuses => {
    //         const updatedStatus = !prevStatuses[bonusId];
    //         setChangedBonuses(prevChangedBonuses => {
    //             const alreadyChanged = prevChangedBonuses.includes(bonusId);
    //             if (!alreadyChanged && updatedStatus !== bonusStatuses[bonusId]) {
    //                 return [...prevChangedBonuses, bonusId];
    //             }
    //             if (alreadyChanged && updatedStatus === bonusStatuses[bonusId]) {
    //                 return prevChangedBonuses.filter(id => id !== bonusId);
    //             }
    //             return prevChangedBonuses;
    //         });
    //         return { ...prevStatuses, [bonusId]: updatedStatus };
    //     });
    // };

    // Только один или ни одного бонуса можно выбрать
    const toggleBonusStatus = (bonusId: string) => {
        checkedBonus === bonusId ? setCheckedBonus("") : setCheckedBonus(bonusId)
    };

    const clientValidation = () => {
        const formElements = formRef.current!.elements;
        const newErrors = { required: {} as { [key: string]: string } };
        let formIsValid = true;

        Array.from(formElements).forEach(field => {
            const inputField = field as HTMLInputElement;
            const isFieldEmpty = inputField.type === 'checkbox' ? !inputField.checked : !inputField.value.trim();
            if (inputField.required && isFieldEmpty) {
                formIsValid = false;
                const errorMessage = inputField.getAttribute('data-error-required') || 'This field is required.';
                newErrors.required[inputField.name] = errorMessage;
            }
        });
        setClientErrors(newErrors);
        return formIsValid;
    };

    const updateClientErrors = (name: string, value: string, e: React.ChangeEvent<HTMLInputElement>) => {
        setClientErrors(prevErrors => {
            const updatedErrors = { ...prevErrors };
            if (value.trim() !== '') {
                delete updatedErrors.required[name];
            } else {
                const errorMessage = e.target.getAttribute('data-error-required') || 'This field is required.';
                updatedErrors.required[name] = errorMessage;
            }
            return updatedErrors;
        });
    };

    const renderFormField = (item: any) => {
        if (item.flag_display === "NO") return null;

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(item.name_tech, e);

        const commonProps = {
            id: item.name_tech,
            name: item.name_tech,
            value: formState[item.name_tech] || '',
            onChange: handleChange,
            required: item.flag_required === "YES",
            placeholder: item.name,
            error: clientErrors.required[item.name_tech]
        };

        return <TextInput {...commonProps} />;
    };

    const renderAmountFormField = () => {
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => handleInputChange('amount', e);

        const commonProps = {
            id: 'amount',
            name: 'amount',
            value: formState['amount'] || '',
            onChange: handleChange,
            required: true,
            placeholder: 'Enter amount',
            error: clientErrors.required['amount'],
            hint: `${selectedPaymentMethod?.min_deposit != null ? `Min ${selectedPaymentMethod.min_deposit}` : ''
                }${selectedPaymentMethod?.min_deposit != null && selectedPaymentMethod?.max_deposit != null ? ' - ' : ''
                }${selectedPaymentMethod?.max_deposit != null ? `Max ${selectedPaymentMethod.max_deposit}` : ''
                } ${selectedPaymentMethod?.currency_code || ''}`,
        };

        const buttonData = [
            ...(selectedPaymentMethod?.min_deposit != null
                ? [{ amount: selectedPaymentMethod.min_deposit, label: 'Min' }]
                : []),
            { amount: '50', label: '50+' },
            { amount: '100', label: '100+' },
            { amount: '200', label: '200+' },
            ...(selectedPaymentMethod?.max_deposit != null
                ? [{ amount: selectedPaymentMethod.max_deposit, label: 'Max' }]
                : []),
        ];

        const buttonsGroup = () => (
            <div className={`p-1 mb-4 ${styles.borderStyleRound}`}>
                <div className={`${styles.buttonGroup} btn-group d-flex`} role="group" aria-label="Basic example">
                    {buttonData.map((button, index) => (
                        <button
                            type="button"
                            key={index}
                            onClick={(e) => handleButtonAmountClick('amount', button.amount, e)}
                            className={`${styles.buttonBgTransparent} btn btn-outline-secondary rounded`}
                        >
                            {button.label}
                        </button>
                    ))}
                </div>
            </div>
        );

        return (
            <>
                <TextInput {...commonProps} />
                {buttonsGroup()}
            </>
        );
    };

    const handleInputChange = (name: string, e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormState(prevState => ({ ...prevState, [name]: value }));
        updateClientErrors(name, value.toString(), e);
    };

    const handleButtonAmountClick = (name: string, value: string | number, e: React.MouseEvent<HTMLButtonElement>) => {
        setFormState(prevState => ({ ...prevState, [name]: value }));
        updateClientErrors(name, value.toString(), e as unknown as React.ChangeEvent<HTMLInputElement>);
    };

    const handleButtonDepositClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        // e.preventDefault();
        clientValidation(); // нужно для того, чтобі сробатывал кастомный стиль валидации
    };

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (clientValidation()) {
            const fieldsToSave: Record<string, any> = {
                amount: formState['amount'],
            };

            if(checkedBonus){
                fieldsToSave.bonus_id = checkedBonus
            }

            handleSubmit(e as FormEvent<HTMLFormElement>, fieldsToSave);
            
        } else {
            console.log('Validation failed');
        }
    };

    return (
        <div>
            {selectedPaymentMethod && (
                <div id="block-paysystem" className="d-flex flex-column align-items-center mb-2">
                    <img
                        src={selectedPaymentMethod?.image_path ? `${imgDir}${selectedPaymentMethod?.image_path}` : dafaultImg}
                        alt={selectedPaymentMethod?.pay_system_name}
                        style={{ width: 'auto', height: '20px', objectFit: 'cover' }}
                    />
                    <p>{selectedPaymentMethod.name}</p>
                </div>
            )}

            <div className="mb-2">
                {getDepositBonusListLoading ? (
                    <div>Loading...</div>
                ) : getDepositBonusListErrorString ? (
                    <div>
                        <Alert type="error" message="Error fetching bonus list">
                            {getDepositBonusListErrorString}
                        </Alert>
                    </div>
                ) : (
                    <div
                        id="block-bonuslist"
                        className="d-flex flex-wrap justify-content-between"
                        style={{ gap: '1rem', margin: '0 auto', maxWidth: '100%' }}
                    >
                        {getDepositBonusList.map((bonus: Bonus) => (
                            <div
                                key={bonus.id}
                                className={`d-flex flex-column align-items-start p-3 ${ checkedBonus === bonus.id ? `${styles.alertBorderSuccess}` : `${styles.alertBorderSecondary}`} rounded`}
                                style={{
                                    flex: '1 1 calc(50% - 1rem)',
                                    boxSizing: 'border-box',
                                    minWidth: 'calc(50% - 1rem)',
                                    maxWidth: 'calc(50% - 1rem)',
                                }}
                            >
                                {/* Старый блок с мультивыбором бонусов. Оставил пока, на всякий случай */}
                                {/* <button
                                    onClick={() => toggleBonusStatus(bonus.id)}
                                    className={`${styles.buttonBgTransparent} btn ${bonusStatuses[bonus.id] ? 'btn-success' : 'btn-outline-secondary'} rounded-pill`}
                                    style={{ padding: '0.5rem 1rem', whiteSpace: 'nowrap' }}
                                >
                                    <div className="d-flex align-items-center">
                                        <span style={{ fontSize: '1rem' }}>
                                            {bonusStatuses[bonus.id] ? 'Active' : 'Inactive'}
                                        </span>
                                        <i className={`${styles.checkmarkCircle} ${bonusStatuses[bonus.id] ? styles.active : styles.inactive}`} style={{ marginLeft: '0.5rem', width: '1em', height: '1em' }}></i>
                                    </div>
                                </button> */}

                                <button
                                    onClick={() => toggleBonusStatus(bonus.id)}
                                    className={`${styles.buttonBgTransparent} btn ${ checkedBonus === bonus.id ? 'btn-success' : 'btn-outline-secondary'} rounded-pill` }
                                    style={{ padding: '0.5rem 1rem', whiteSpace: 'nowrap' }}
                                >
                                    <div className="d-flex align-items-center">
                                        <span style={{ fontSize: '1rem' }}>
                                            {checkedBonus === bonus.id? 'Active' : 'Inactive'}
                                        </span>
                                        <i className={`${styles.checkmarkCircle} ${ checkedBonus === bonus.id ? styles.active : styles.inactive }`} style={{ marginLeft: '0.5rem', width: '1em', height: '1em' }}></i>
                                    </div>
                                </button>

                                <p className="h5 text-center text-truncate" style={{ width: '100%' }}>
                                    {bonus?.bonusLangs[0].name}
                                </p>
                                <p className="text-center text-truncate" style={{ width: '100%' }}>
                                    {bonus?.bonusLangs[0].description}
                                </p>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <form onSubmit={handleFormSubmit} ref={formRef}>
                {selectedPaymentMethod && selectedPaymentMethod.fields && selectedPaymentMethod.fields.map((item: any) => (
                    <React.Fragment key={item.id}>
                        {renderFormField(item)}
                    </React.Fragment>
                ))}
                {renderAmountFormField()}

                <div className="mt-2">
                    <button
                        type="submit"
                        onClick={(e) => handleButtonDepositClick(e)}
                        className="btn btn-success rounded-pill d-flex justify-content-center w-100"
                    >
                        DEPOSIT
                    </button>
                </div>
            </form>
        </div>
    );
};

export default DepositDetails;