// vipClubInfoReducer.js
import { FETCH_VIPCLUBINFO_REQUEST, FETCH_VIPCLUBINFO_SUCCESS, FETCH_VIPCLUBINFO_FAILURE } from '../../actions/bonus/vipClubInfoActions';

const initialState = {
    vipClubInfo: [],
    loading: false,
    error: null,
    messages: null,
  };
  
  const vipClubInfoReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_VIPCLUBINFO_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_VIPCLUBINFO_SUCCESS:
        return {
          ...state,
          loading: false,
          vipClubInfo: action.payload,
        };
      case FETCH_VIPCLUBINFO_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default vipClubInfoReducer;
  