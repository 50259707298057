import React from 'react'
import styles from "./CarouselTitleIcon.module.scss"
import clsx from "clsx"

type Props = {
   logoPath: string | null
   title: string
   className?: string
}

export const CarouselTitleIcon = ({logoPath, title, className}: Props) => {
  return (
    <div className={clsx(clsx(styles.titleContainer, className))}>
      {
        logoPath &&
          <div className={styles.iconContainer}>
              <img src={logoPath}
                   alt="carousel_icon" className={styles.winners_icon}
              />
          </div>

      }
      <h5>{title}</h5>
    </div>
  )
}

