import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams  } from 'react-router-dom';
import { selectIsAuthenticated, selectLoginErrors, selectIsLoadingLogin, selectHasTokenInLocalStorage } from '../../../redux/selectors/site/loginSelectors';
import Login from './Login';
import Signup from './Signup';
import {RootState} from '../../../redux/store'

const AuthPage: React.FC = () => {
    const [activeTab, setActiveTab] = useState<'login' | 'signup'>('login');
    const isAuthenticated = useSelector<RootState, boolean>(selectIsAuthenticated); // По умолчанию активен логин
    const navigate = useNavigate();
    const { param } = useParams();

    // Редирект на главную, если пользователь аутентифицирован
    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }else{
            if (param) {
                switch (param) {
                    case 'login':
                        setActiveTab('login');
                        break;
                    case 'signup':
                        setActiveTab('signup');
                        break;
                    default:
                        navigate('/404');
                        break;
                }
            }
        }
    }, [isAuthenticated, navigate]);

    const handleButtonActiveTabClick = (tab: 'login' | 'signup') => {
        setActiveTab(tab);
    };

    return (
        
        <>
            {activeTab === 'login' && <Login handleButtonActiveTabClick={handleButtonActiveTabClick} />}
            {activeTab === 'signup' && <Signup handleButtonActiveTabClick={handleButtonActiveTabClick} />}
        </>
    );
};

export default AuthPage;
