import React, { ReactNode } from 'react'
import styles from "./AuthLayout.module.scss"

type AuthLayoutProps = {
    children: ReactNode
}

const MainLayout: React.FC<AuthLayoutProps> = ({ children }) => {
    return (
        <div className="auth-layout d-flex align-items-center min-vh-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-10 col-md-8 col-lg-8">
                        <div className={`card ${styles.centeredCard}`}>
                            <div className="card-body">
                                {/* тут будет внутренний контент */}
                                {children}
                                {/* тут будет внутренний контент */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >




    );
};

export default MainLayout;
