// platform-client-app\react-platform\src\components\pages\auth\formComponents\SelectInput.tsx
import React from 'react';

type Option = {
  id: string;
  name: string;
};

type SelectInputProps = {
  id: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  required?: boolean;
  options: Option[];
  placeholder: string;
  error?: string | string[] | null;
};

const SelectInput: React.FC<SelectInputProps> = ({
  id,
  name,
  value = '',
  onChange,
  required = false,
  options,
  placeholder,
  error = ''
}) => (
  <div className="form-group mb-3">
    <label htmlFor={id} className="form-label">
      <strong>{placeholder} {required && <span style={{ color: 'red' }}>*</span>}</strong>
    </label>
    <select
      id={id}
      name={name}
      className={`form-control ${error ? 'is-invalid' : ''}`}
      value={value}
      onChange={onChange}
      required={required}
      data-error-required={`${placeholder} is required`}
    >
      <option value="" disabled>{placeholder}</option>
      {options.map((option) => (
        <option key={option.id} value={option.id}>{option.name}</option>
      ))}
    </select>
    <div className="invalid-feedback">
      {Array.isArray(error) ? (
        // Если error - это массив строк, выводим каждую строку как элемент списка
        error.map((errMsg, index) => (
          <p key={index}>{errMsg}</p>
        ))
      ) : (
        // Иначе выводим error как строку, если он не null
        error || ''
      )}
    </div>
    {/* <div className="invalid-feedback">
      {error || ''}
    </div> */}
  </div>
);

export default SelectInput;
