import React from 'react'
import {Grid2} from "@mui/material"
import styles from "./CarouselBlock.module.scss"
import {Carousel} from "./Carousel/Carousel"
import clsx from "clsx"
import stylesCommon from "src/common/styles/commonStyles.module.scss"

export const CarouselBlock = () => {

  return (
    <div className={clsx(styles.carouselBlock, stylesCommon.group)}>
      <Grid2 spacing={'24px'} container direction={'column'}>
        <div className={styles.carouselContainer}><Carousel id={1}/></div>
        <div className={styles.carouselContainer}><Carousel id={2}/></div>
      </Grid2>
    </div>

  )
}