// platform-client-app\react-platform\src\redux\reducers\player\player-deposit\playerBalancesReducer.js
import {
  ACTION_GET_PLAYER_BALANCES_REQUEST,
  ACTION_GET_PLAYER_BALANCES_SUCCESS,
  ACTION_GET_PLAYER_BALANCES_FAILURE,
} from '../../actions/playerDeposit/playerBalancesActions';

const initialState = {
  loading: false,
  error: null,
  data: [],
  messages: null,
};

const playerBalancesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_GET_PLAYER_BALANCES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ACTION_GET_PLAYER_BALANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        messages: action.payload.messages,
        error: null,
      };

    case ACTION_GET_PLAYER_BALANCES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default playerBalancesReducer;
