// updatePersonalInfoReducer.js
import {
  FETCH_UPDATEPERSONALINFO_REQUEST,
  FETCH_UPDATEPERSONALINFO_SUCCESS,
  FETCH_UPDATEPERSONALINFO_FAILURE
} from '../../actions/player/updatePersonalInfoActions';

const initialState = {
  result: [],
  resultMessages: null,
  loading: false,
  error: null,
  errorMessage: null,
};

const updatePersonalInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UPDATEPERSONALINFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        errorMessage: null
      };
    case FETCH_UPDATEPERSONALINFO_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        resultMessages: action.resultMessages,
      };
    case FETCH_UPDATEPERSONALINFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        errorMessage: action.errorMessage,
      };

    default:
      return state;
  }
};

export default updatePersonalInfoReducer;
