// gameListSelectors.js

export const selectGameList             = (state) => state.gameListReducer.data;
export const selectGameListMessages     = (state) => state.gameListReducer.messages;
export const selectGameListLoading      = (state) => state.gameListReducer.loading;

export const selectGameListError = (state) => {
    const errors = state.gameListReducer.errors;
  
    if (Array.isArray(errors)) {
      return errors.join('\n');
    }
  
    if (typeof errors === 'object' && errors !== null) {
      return Object.entries(errors)
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n');
    }
  
    return errors;
  };