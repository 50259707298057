import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {selectIsAuthenticated, selectToken} from '../../redux/selectors/site/loginSelectors'
import {RootState} from '../../redux/store'
import {connectBalanceWebSocket} from "src/redux/actions/balanceActions"
import {selectBalance, selectError, selectLoading} from "src/redux/selectors/balanceSelectors"
import {PublicBlocks} from "../layouts/PublicBlocks"

const Home = () => {
  const dispatch = useDispatch()

  const balance = useSelector((state: RootState) => selectBalance(state))
  const loading = useSelector((state: RootState) => selectLoading(state))
  const error = useSelector((state: RootState) => selectError(state))
  const isAuthenticated = useSelector((state: RootState) => selectIsAuthenticated(state))
  const token = useSelector((state: RootState) => selectToken(state))

  useEffect(() => {
    if (isAuthenticated) {
      console.log('home page token', token)
      const closeBalanceWebSocket = dispatch(connectBalanceWebSocket(token))

      return () => {
        closeBalanceWebSocket()
      }
    }
  }, [dispatch, token, isAuthenticated])

  return (
      <>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error: {error}</p>
        ) : balance !== null ? (
          <div>
            <PublicBlocks />
          </div>
        ) : (
          <p>No data!</p>
        )}
      </>
  )
}

export default Home
