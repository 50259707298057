// platform-client-app\react-platform\src\redux\selectors\site\CodeConfirmationSelectors.ts

import { RootState } from '../../store';// Импортируем тип состояния корневого редьюсера

export const selectCodeConfirmation = (state: RootState) => state.codeConfirmationReducer.data;
export const selectCodeConfirmationCreatedSuccess = (state: RootState) => state.codeConfirmationReducer.success === true;
export const selectCodeConfirmationMessages = (state: RootState) => state.codeConfirmationReducer.messages;
export const selectCodeConfirmationLoading = (state: RootState) => state.codeConfirmationReducer.loading;
export const selectCodeConfirmationError = (state: RootState) => state.codeConfirmationReducer.error;

export const selectCodeConfirmationErrorString = (state: RootState): string | null => {
  const error = state.codeConfirmationReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectCodeConfirmationErrorArray = (state: RootState): string[] | null => {
  const error = state.codeConfirmationReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map(err => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) => Array.isArray(value) ? value.map(val => `${key}: ${val}`) : `${key}: ${value}`);
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
