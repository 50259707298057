import {useRef, useCallback} from 'react'

export const useCarouselScroll = (active = false, direction: 'left' | 'right' = 'right', interval = 1) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const scrollAnimationFrame = useRef<number | null>(null)

  const scroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const {scrollLeft, clientWidth, scrollWidth} = scrollRef.current

      if (direction === 'right') {
        // Add an inaccuracy for correct end detection, at autoscroll
        const isAtEnd = Math.abs(scrollLeft + clientWidth - scrollWidth) < 20
        const scrollTo = isAtEnd ? 0 : scrollLeft + clientWidth
        scrollRef.current.scrollTo({left: scrollTo, behavior: 'smooth'})
      } else {
        // if (scrollLeft === 0) return  // Do not scroll left if already at the far-left position
        const scrollTo = scrollLeft - clientWidth
        scrollRef.current.scrollTo({left: scrollTo, behavior: 'smooth'})
      }
    }
  }

  const smoothScrollStep = useCallback(() => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, offsetWidth } = scrollRef.current;
      const maxScrollLeft = scrollWidth - offsetWidth;

      // Движение вправо
      if (direction === 'right') {
        scrollRef.current.scrollLeft += interval;
        // Если достигли конца, просто продолжайте прокрутку
        if (scrollLeft >= maxScrollLeft) {
          scrollRef.current.scrollLeft = 0; // Возвращаемся к началу
        }
      }
      // Движение налево
      else if (direction === 'left') {
        scrollRef.current.scrollLeft -= interval;
        // Если достигли начала, просто продолжайте прокрутку
        if (scrollLeft <= 0) {
          scrollRef.current.scrollLeft = maxScrollLeft; // Переход к концу
        }
      }

      // Перезапуск анимации
      scrollAnimationFrame.current = requestAnimationFrame(smoothScrollStep);
    }
  }, [direction, interval]);

  const startSmoothScroll = useCallback(() => {
    stopSmoothScroll();
    if (active && scrollRef.current) {
      scrollRef.current.scrollLeft = scrollRef.current.scrollWidth / 2;
    }
    scrollAnimationFrame.current = requestAnimationFrame(smoothScrollStep);
  }, [active, smoothScrollStep]);

  const stopSmoothScroll = useCallback(() => {
    if (scrollAnimationFrame.current) {
      cancelAnimationFrame(scrollAnimationFrame.current)
      scrollAnimationFrame.current = null
    }
  }, [])

  return {scrollRef, scroll, startSmoothScroll, stopSmoothScroll}
}
