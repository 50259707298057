import React from 'react';
import Alert, { AlertProps } from '../../../layouts/ui/alert/Alert';

interface FormWrapperProps {
    title?: string | null;
    description?: string | null;
    children: React.ReactNode;
    alertProps?: AlertProps | null; // Используем интерфейс AlertProps
    alertPosition?: 'top' | 'bottom';
}

const FormWrapper: React.FC<FormWrapperProps> = ({
    title = null,
    description = null,
    children,
    alertProps = null,
    alertPosition = "bottom"
}) => (
    <div className="container">
        <div className="row">
            <div className="col-lg-10 offset-1">
                {title && <h1>{title}</h1>}
                {description && <p>{description}</p>}

                {/* Отображение Alert сверху, если alertPosition === "top" */}
                {alertProps && alertPosition === "top" && (
                    <div className="mt-2 mb-2">
                        <Alert {...alertProps} />
                    </div>
                )}

                {children}

                {/* Отображение Alert снизу, если alertPosition === "bottom" */}
                {alertProps && alertPosition === "bottom" && (
                    <div className="mt-2 mb-2">
                        <Alert {...alertProps} />
                    </div>
                )}
            </div>
        </div>
    </div>
);

export default FormWrapper;

