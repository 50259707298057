import React, {useEffect, useMemo, useState} from 'react'
import {useAppDispatch, useAppSelector} from 'src/hooks'
import {Box, Paper} from '@mui/material'
import DepositWithdrawalTable from '../DepositWithdrawalTable'
import stylesCommon from './DepositWithdrawalHandler.module.scss'
import {TRANSACTIONS_PAGINATION_VALUES, SIBLING_COUNT, DEFAULT_PAGINATION_VALUE} from 'src/common/constants'
import {Pagination} from '../../../../../common/Pagination'
import {
  clearTransactionsWebSocket,
  fetchTransactions,
  TransactionsState
} from '../../../../../redux/slices/transactionsSlice'
import {Transaction} from '../../../../../api/types'
import {RootState} from '../../../../../redux/store'
import {useSelector} from "react-redux"
import {selectToken} from "../../../../../redux/selectors/site/loginSelectors"

export const DepositWithdrawalHandler: React.FC<TransactionsComponentProps> = ({direction, selector}) => {
  const dispatch = useAppDispatch()
  const {data, loading, error} = useAppSelector(selector)

  const token = useSelector(selectToken)

  // State for filters as a single object
  const [filters, setFilters] = useState({
    uidFilter: '',
    dateFilter: '',
    amountFilter: '',
    currencyFilter: '',
    statusFilter: ''
  })

  // State for sorting
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const [orderBy, setOrderBy] = useState<keyof Transaction>('date')

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGINATION_VALUE)

  useEffect(() => {
    if (token) {
      dispatch(
        fetchTransactions({
          token,
          direction,
          page: currentPage,
          pageSize: itemsPerPage
        })
      )
    }

    return () => {
      dispatch(clearTransactionsWebSocket(direction))
    }
  }, [token, dispatch, currentPage, itemsPerPage])

  // Local data filtering
  const filteredData = useMemo(() => {
    if (!data?.transactions?.data?.length) return []

    return data.transactions.data.filter((transaction: Transaction) => {
      const matchesUid = !filters.uidFilter || transaction.uid.includes(filters.uidFilter)
      const matchesDate = !filters.dateFilter || new Date(transaction.date * 1000).toLocaleDateString().includes(filters.dateFilter)
      const matchesAmount = !filters.amountFilter || transaction.amount.toString().includes(filters.amountFilter)
      const matchesCurrency = !filters.currencyFilter || transaction.currency_code.includes(filters.currencyFilter)
      const matchesStatus = !filters.statusFilter || transaction.status.includes(filters.statusFilter)

      return matchesUid && matchesDate && matchesAmount && matchesCurrency && matchesStatus
    })
  }, [data, filters])

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error loading transactions.</p>
  if (!data || !data.transactions.data.length) return <div>No transactions available</div>

  return (
    <Box className={stylesCommon.transactionsContainer}>
      <h3>{direction === 'deposit' ? 'Deposits' : 'Withdrawals'}</h3>
      <Paper className={stylesCommon.tableContainer}>
        <DepositWithdrawalTable
          data={filteredData} // Display only filtered data
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          filtersState={{filters, setFilters}} // Passing filters as a single state
        />
      </Paper>
      <Pagination
        variant={'transactions'}
        onPageChange={(value) => setCurrentPage(Number(value))}
        totalCount={data.transactions.pagination.total_count}
        currentPage={currentPage}
        pageSize={itemsPerPage}
        siblingCount={SIBLING_COUNT}
        selectSettings={{
          value: itemsPerPage.toString(),
          onChangeOption: (value: string) => {
            setItemsPerPage(Number(value)) // Change the number of items per page
            setCurrentPage(1) // Reset current page to 1
          },
          arr: TRANSACTIONS_PAGINATION_VALUES.map((val) => val.toString())
        }}
      />
    </Box>
  )
}

type TransactionsComponentProps = {
  direction: 'deposit' | 'withdrawal';
  selector: (state: RootState) => TransactionsState['deposit'] | TransactionsState['withdrawal'];
};
