// vipClubInfoActions.js.js
import axios from 'axios';
import config from '../../../config';

export const FETCH_VIPCLUBINFO_REQUEST = 'FETCH_VIPCLUBINFO_REQUEST';
export const FETCH_VIPCLUBINFO_SUCCESS = 'FETCH_VIPCLUBINFO_SUCCESS';
export const FETCH_VIPCLUBINFO_FAILURE = 'FETCH_VIPCLUBINFO_FAILURE';

// Экшены для получения данных
export const fetchVipClubInfoRequest = () => ({
  type: FETCH_VIPCLUBINFO_REQUEST,
});

export const fetchVipClubInfoSuccess = (bonusList) => ({
  type: FETCH_VIPCLUBINFO_SUCCESS,
  payload: bonusList,
});

export const fetchVipClubInfoFailure = (error) => ({
  type: FETCH_VIPCLUBINFO_FAILURE,
  payload: error,
});

export const fetchVipClubInfo = (token, language = null) => async (dispatch) => {
  dispatch(fetchVipClubInfoRequest());
  try {
    const response = await axios.get(`${config.apiBaseUrl}/bonus/list`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json; charset=UTF-8',
      }
    });
    
    dispatch(fetchVipClubInfoSuccess(response.bonusList.data));
  } catch (error) {
    dispatch(fetchVipClubInfoFailure(error.message));
  }
};


export const connectWebSocketVipClubInfo = (token, language = null) => {
  return (dispatch) => {
    const ws = new WebSocket(`${config.serverWebsocketUrl}`);

    ws.onopen = () => {
      console.log('Connected to WebSocket server getVipClubInfo');
      ws.send(JSON.stringify({ type: 'getVipClubInfo', token }));
    };

    ws.onmessage = (event) => {
      console.log('WebSocket message received getVipClubInfo:', event.data);
      try {
        const data = JSON.parse(event.data);
        console.log('Parsed event.data:', data.vipClubInfo.data);

        if (data.type === 'getVipClubInfo') {
          const vipClubInfoData = data.vipClubInfo.data;
          
          // Преобразуем данные в массив
          const vipClubInfoArray = Array.isArray(vipClubInfoData) ? vipClubInfoData : [vipClubInfoData];

          console.log('VipClubInfo received (array):', vipClubInfoArray);
          dispatch(fetchVipClubInfoSuccess(vipClubInfoArray));
        } else if (data.type === 'error') {
          dispatch(fetchVipClubInfoFailure(data.message));
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error.message);
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      ws.close();
    };
  };
};
